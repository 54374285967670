import { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";
import { getActivityChartData } from "../../../../store/slices/Profile/ProfileActivity";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

const useProfileVisitedChart = ({
  datasets,
  value,
  setValue,
  // setCurrent,
  // current,
  selectedChart,
  setSelectedChart,
  title,
  state,
  activeProfile,
  index,
}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const chartRef = useRef(null);
  const monthPickerRef = useRef(null);
  const currentDate = new Date();

  const [current, setCurrent] = useState([
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
  ]);

  useEffect(() => {
    if (Object.keys(state?.activityChartData[activeProfile] ?? {})?.length === 0) {
      dispatch(
        getActivityChartData({
          member_id: activeProfile,
          profile_view_month: value[index]?.month,
          profile_view_year: value[index]?.year,
        })
      );
      dispatch(
        getActivityChartData({
          member_id: activeProfile,
          interest_month: value[index]?.month,
          interest_year: value[index]?.year,
        })
      );
      dispatch(
        getActivityChartData({
          member_id: activeProfile,
          contact_year: value[index]?.year,
          contact_month: value[index]?.month,
        })
      );
    }
    
    //eslint-disable-next-line
  }, [activeProfile, location.pathname]);

  useEffect(() => {
    const chartOptions = {
      type: "bar",
      data: {
        labels: ["Week1", "Week2", "Week3", "Week4", "Week5"],
        datasets: datasets,
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: true,
            position: "right",
            labels: {
              usePointStyle: true,
            },
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
            barThickness: 100,
            barPercentage: 0,
            categoryPercentage: 0,
          },
          y: {
            grid: {
              display: false,
            },
            ticks: {
              beginAtZero: true,
              min: 0,
              max: 30,
              stepSize: 10,
            },
          },
        },
      },
    };

    const chart = new Chart(chartRef.current, chartOptions);

    return () => {
      chart.destroy();
    };
    // eslint-disable-next-line
  }, [datasets]);

  const showPicker = () => {
    if (monthPickerRef && monthPickerRef.current) {
      monthPickerRef.current.show();
    }
  };

  const hidePicker = () => {
    if (monthPickerRef && monthPickerRef.current) {
      monthPickerRef.current.dismiss();
    }
  };

  

  useEffect(() => {
    if ( selectedChart === "Interests") {
      setValue((prevValue) => [
        { ...prevValue[0], year: current[0], month: current[1] },
        prevValue[1],
        prevValue[2],
      ]);
    } else if (selectedChart === "Contact") {
      setValue((prevValue) => [
        prevValue[0],
        { ...prevValue[1], year: current[0], month: current[1] },
        prevValue[2],
      ]);
    } else if (selectedChart === "Profiles Visited") {
      setValue((prevValue) => [
        prevValue[0],
        prevValue[1],
        { ...prevValue[2], year: current[0], month: current[1] },
      ]);
    }
    // eslint-disable-next-line
  }, [selectedChart]);

  const handlePickerChange = (args, title) => {
    setCurrent(() => args);
    hidePicker();
    if (title === "Profiles Visited") {
      setSelectedChart("Profiles Visited");
      setValue((prevValue) => [
        { ...prevValue[0], year: args?.[0], month: args?.[1] },
        prevValue[1],
        prevValue[2],
      ]);
      dispatch(
        getActivityChartData({
          member_id: activeProfile,
          profile_view_month: args?.[1],
          profile_view_year: args?.[0],
        })
      );
    }
    if (title === "Interests") {
      setSelectedChart("Interests");
      setValue((prevValue) => [
        prevValue[0],
        { ...prevValue[1], year: args?.[0], month: args?.[1] },
        prevValue[2],
      ]);
      dispatch(
        getActivityChartData({
          member_id: activeProfile,
          interest_month: args?.[1],
          interest_year: args?.[0],
        })
      );
    }
    if (title === "Contact") {
      setSelectedChart(() => "Contact");
      setValue((prevValue) => [
        prevValue[0],
        prevValue[1],
        { ...prevValue[2], year: args?.[0], month: args?.[1] },
      ]);
      dispatch(
        getActivityChartData({
          member_id: activeProfile,
          contact_month: args?.[1],
          contact_year: args?.[0],
        })
      );
    }
  };

  const lang = {
    months: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    from: "From",
    to: "To",
  };

  return {
    chartRef,
    monthPickerRef,
    lang,
    value,
    current,
    showPicker,
    handlePickerChange,
  };
};

export default useProfileVisitedChart;
