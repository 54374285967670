import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  sortBy: "",
  sortOrder: "desc",
  data: [],
  showCreateModal: false,
  selectedBranch: "",
  editBranch: false,
  clearSelection: false,
  search: "",
};

const branchesSlice = createSlice({
  name: "branches",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
    selectBranch: (state, action) => {
      state.selectedBranch = action.payload;
    },
    editBranch: (state, action) => {
      state.editBranch = action.payload;
    },
  },
});

export const { updateConfig, selectBranch, editBranch } = branchesSlice.actions;

export default branchesSlice.reducer;
