import { useFormik } from "formik";
import * as Yup from "yup";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  updateConfig,
  updateItemFromTableByID,
} from "../../../../store/slices/Settings/faqsSlice";
import { useCreateNewFAQDataMutation } from "../../../../store/queries/cms";
import { useSelector } from "react-redux";

const useAddFAQForm = (setShowFAQform, refetch) => {
  const [errors, setErrors] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const imageFileInputRef = useRef(null);

  const dispatch = useDispatch();

  const faqs = useSelector((state) => state.faqs);

  let addPaymentVal = Yup.object({
    question: Yup.string()
      .min(5, "Question Must be atleast 5 Characters")
      .required("*Required"),
    answer: Yup.string().required("*Required"),
  });

  const [addnewFAQ] = useCreateNewFAQDataMutation();

  const formik = useFormik({
    initialValues: {
      question:
        faqs.formEditMode === true ? faqs.selectedItemDetails.question : "",
      answer: faqs.formEditMode === true ? faqs.selectedItemDetails.answer : "",
    },

    validationSchema: addPaymentVal,
    enableReinitialize: true,
    onSubmit: (values, { resetForm, setFieldError }) => {
      handleFormSubmit(values, resetForm, setFieldError);
    },
  });

  const handleFormSubmit = async (values, resetForm, setFieldError) => {
    try {
      let obj = {
        answer: values.answer,
        question: values.question,
      };

      let formData = new FormData();
      Object.keys(obj).forEach((key) => {
        return formData.append(key, obj[key]);
      });

      try {
        if (faqs.formEditMode === false) {
          addnewFAQ(obj)
            .then((data) => {
              if (data.data.success === true) {
                setShowFAQform(false);
                resetForm();
                refetch();
                toast.success("FAQ has been added successfully.");
              }
            })
            .catch((error) => {});
        } else {
          formData.append("_method", "PUT");
          formData.append("faq_id", faqs.selectedItemDetails._id);
          dispatch(updateItemFromTableByID(formData))
            .unwrap()
            .then((data) => {
              if (data.data.success === true) {
                setShowFAQform(false);
                resetForm();
                dispatch(
                  updateConfig((state) => {
                    state.clearSelection = true;
                  })
                );
                refetch();
                toast.success("FAQ has been updated successfully. ");
              }
            })
            .catch((error) => {
              if (!error.success) {
                if (error?.message?.question[0] !== "") {
                  setFieldError("question", error?.message?.question[0]);
                }
                if (error?.message?.answer[0] !== "") {
                  setFieldError("answer", error?.message?.answer[0]);
                }
              }
            });
        }
      } catch (error) {}
    } catch (error) {
      setErrors("Something went wrong!");
    }
  };

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
  };

  return {
    errors,
    message,
    setLoading,
    setMessage,
    formik,
    loading,
    handleCloseModal,
    imageFileInputRef,
  };
};

export default useAddFAQForm;
