import React from "react";
import {
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  OffCanvasLayout,
  PaymentHistory,
  Table,
  DataContainer,
  SimpleReport,
} from "@wac-ui-dashboard/wac_component_library";
import Select from "react-select";
import useTransactions from "./useTransactions";
import OrderColumn from "../../Global/OrderColumn";
import { FaSort } from "react-icons/fa";
import AddPaymentForm from "../AddPaymentForm";
import Invoice from "./Invoice";
import SwitchableNav from "./SwitchableNav";
import DashboardHero from "../../Dashboard/DashboardHero";
import EmptyData from "../../Global/EmptyData";
import Style from "./transactions.module.scss";
import ErrorMessage from "../../Global/ErrorMessage";
import PaymentStatusModal from "./PaymentStatusModal";
import DatePickerRsuite from "../../Global/CustomDateRangePicker";

const Transactions = ({ dashboard = false }) => {
  const {
    transactions,
    showCreateModal,
    hasCreatePermission,
    isFetching,
    isLoading,
    filters,
    activeFilter,
    showEditModal,
    tableFields,
    currentPage,
    paginationOptions,
    showInvoiceModal,
    activeTab,
    profilePaymentHistory,
    selectedUser,
    actionOptions,
    selectedUserId,
    TransactionItems,
    hasExportPermission,
    headerDetails,
    handleTableFilter,
    branchOptionsData,
    handleDateRangeChange,
    handleClose,
    handleFilter,
    setSkip,
    handleDashboardRedirect,
    handleActionChange,
    setSelectedUser,
    handleActiveTab,
    refetch,
    handleSort,
    handleSearch,
    handleCreateClick,
    closeModal,
    getRow,
    handleEditClick,
    handlePagination,
    closeEditModal,
    handleDateChange,
    handlePageSize,
    updateTransactionFields,
    closeInvoiceModal,
    handleClearClick,
    handlePaymentFilter,
    TransactionsState,
    paymentOption,
    date,
    handleEditAction,
    hasEditPermission,
    hasUpdatePermission,
  } = useTransactions(dashboard);

  const allBranchesOption = {
    _id: "",
    name: "All Branches",
  };

  const branchesOption = [
    allBranchesOption,
    ...(branchOptionsData?.data?.branch ?? []),
  ];

  return (
    <>
      {!dashboard ? (
        <>
          <HeadingGroup
            title={"Transactions"}
            buttonTitle={hasCreatePermission === 1 ? "Add payment" : ""}
            className={`pro-mb-4`}
            handleClick={handleCreateClick}
          />
          <div className={`pro-w-100 pro-my-5`}>
            <DashboardHero
              data={transactions?.data?.collection_details}
              obj={TransactionItems}
              transactions={true}
              loading={isFetching}
              shimmerCount={4}
            />
          </div>
        </>
      ) : (
        <></>
      )}

      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        {!dashboard ? (
          <SearchFilters
            //showDateRange

            data={filters}
            activeFilter={activeFilter}
            onDateChange={handleDateChange}
            handleButtonGroupChange={handleFilter}
            initialDateRange={date}
            onSearchInput={handleSearch}
            showActions={false}
            handleActionClick={handleEditClick}
            loading={isLoading}
            SearchIcon={
              <span className="material-symbols-outlined"> search </span>
            }
            //showClearFilters
            dropDownFilter={
              <>
                <div className="col-auto">
                  <DatePickerRsuite
                    defaultDateRange={date}
                    handleDateRangeChange={handleDateRangeChange}
                  />
                </div>
                <div className="col-2 payment-filter">
                  <Select
                    id="branch"
                    placeholder={"Select Branch"}
                    className={`pro-input lg`}
                    classNamePrefix="pro-input"
                    options={branchesOption}
                    getOptionValue={(option) => option?._id}
                    getOptionLabel={(option) => option?.name}
                    onChange={(value) => {
                      handleTableFilter(value);
                    }}
                  />
                </div>
                <div className="col-2 payment-filter">
                  <Select
                    id="payment_type"
                    placeholder={"Select Payment Type"}
                    className={`pro-input lg`}
                    classNamePrefix="pro-input"
                    options={paymentOption ?? []}
                    getOptionValue={(option) => option?.id}
                    getOptionLabel={(option) => option?.name}
                    onChange={(value) => {
                      handlePaymentFilter(value);
                    }}
                  />
                </div>
              </>
            }
            handleClear={handleClearClick}
            searchInputProps={{ value: TransactionsState?.search }}
            actionOptions={actionOptions?.filter((item) =>
              hasExportPermission !== 1 ? item.value === 0 : true
            )}
            onActionOptionChange={handleActionChange}
          />
        ) : (
          <></>
        )}

        {transactions?.data?.data?.data?.length === 0 ? (
          <EmptyData />
        ) : (
          <div
            onClick={dashboard ? () => handleDashboardRedirect() : () => {}}
            className={!dashboard ? "pro-pt-3" : Style.dashboardTable}
          >
            {/* <Table
              data={transactions?.data?.data?.data || []}
              uniqueID={"_id"}
              //fields={adons?.data?.fields}
              fields={tableFields}
              SortIcon={<FaSort />}
              handleSort={handleSort}
              getRow={getRow}
              loading={isFetching}
              perpage={TransactionsState?.currentPageSize}
              extraClasssName={"dashboard_no_padding"}
              // isSelectBox
              // propStyle={{
              //   selectBox_table: Style.selectBox_table,
              // }}
              showCheckBox={true}
              editIcon={<span className="material-symbols-outlined">edit</span>}
              handleEdit={handleEditAction}
              editable={true}
              deletable={false}
              multiSelect={false}
            /> */}
            <Table
              data={transactions?.data?.data?.data || []}
              uniqueID={"_id"}
              editIcon={<span className="material-symbols-outlined">edit</span>}
              extraClasssName={"dashboard_no_padding"}
              showCheckBox={hasEditPermission === 1 ? true : false}
              deletable={false}
              handleEdit={handleEditAction}
              clear={TransactionsState.clearSelection}
              multiSelect={false}
              assignable={false}
              fields={tableFields}
              SortIcon={<FaSort />}
              handleSort={handleSort}
              getRow={getRow}
              loading={isFetching}
              perpage={TransactionsState?.currentPageSize}
            />
          </div>
        )}

        {!dashboard && activeFilter === "PURCHASED" && !isFetching ? (
          <>
            <div className="pro-m-4 pro-mx-0">
              <DataContainer>
                <SimpleReport
                  extraClassNames={Style.simple_report}
                  propStyle={{ textInner: Style.textInner }}
                  data={headerDetails}
                />
              </DataContainer>
            </div>
          </>
        ) : (
          <></>
        )}

        {transactions?.data?.data?.data?.length > 0 && !dashboard && (
          <Pagination
            currentPage={currentPage}
            totalPageCount={transactions?.data?.data?.last_page}
            defaultValue={paginationOptions?.filter(
              (item) => item.value === TransactionsState?.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
        <OffCanvasLayout
          show={showCreateModal}
          handleClose={closeModal}
          title={"Payment detail"}
          closeIcon={<span className="material-symbols-outlined">close</span>}
        >
          <div className="">
            <SwitchableNav
              activeTab={activeTab}
              selectedUserId={selectedUserId}
              handleActiveTab={handleActiveTab}
              is_edit={TransactionsState?.is_edit}
            />
            {activeTab === "payment" ? (
              <AddPaymentForm
                refetch={refetch}
                selectedUser={selectedUser}
                setSkip={setSkip}
                setSelectedUser={setSelectedUser}
                updatePermission={hasUpdatePermission}
              />
            ) : (
              profilePaymentHistory?.data?.map((item) => {
                return (
                  <div className="pro-my-4">
                    <PaymentHistory
                      icon={
                        <span className="material-symbols-outlined">
                          package
                        </span>
                      }
                      title={item?.name}
                      date={item?.date}
                      price={<p>₹ {item?.amount} </p>}
                      paymentMethod={item?.payment_type}
                    />
                  </div>
                );
              })
            )}

            {(profilePaymentHistory?.data?.length === undefined ||
              profilePaymentHistory?.data?.length === 0) &&
            activeTab === "history" ? (
              <div className="pro-mt-5">
                <ErrorMessage />
              </div>
            ) : (
              <></>
            )}
          </div>
        </OffCanvasLayout>
        <ModalLayout show={showEditModal} handleClose={closeEditModal}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetch={refetch}
              tableFields={transactions?.data?.fields}
              moduleId={transactions?.data?.module_id}
              updateData={updateTransactionFields}
            />
          </div>
        </ModalLayout>

        <ModalLayout
          title={"Add note"}
          show={TransactionsState.statusModal}
          handleClose={() => handleClose()}
        >
          <div className="pro-m-5">
            <PaymentStatusModal
              refetch={refetch}
              handleClose={handleClose}
              userId={TransactionsState?.IDs?.userId}
              selectedId={TransactionsState?.IDs?.transactionID}
              currentStatus={TransactionsState?.IDs?.status}
            />
          </div>
        </ModalLayout>

        <ModalLayout
          show={showInvoiceModal}
          handleClose={closeInvoiceModal}
          centered={false}
          // title={"Invoice"}
          // closeIcon={<span className="material-symbols-outlined">close</span>}
        >
          <Invoice />
        </ModalLayout>
      </div>
    </>
  );
};

export default Transactions;
