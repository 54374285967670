import {
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  BreadCrumbs,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../Global/OrderColumn";
import { useSelector } from "react-redux";
import useStatesSingle from "./useStateSingle";
import AddDistrictFormContainer from "./AddForm";
import EmptyData from "../../Global/EmptyData";

const StatesSingle = () => {
  const {
    statesSinglePageList,
    stateSinglePage,
    currentPage,
    paginationOptions,
    isFetching,
    isLoading,
    actionOptions,
    hasCreatePermission,
    hasExportPermission,
    handleActionChange,
    refetch,
    handlePagination,
    handlePageSize,
    handleDateChange,
    handleSort,
    handleSearch,
    getRow,
    handleEditClick,
    closeEditModal,
    showEditModal,
    handleDelete,
    closeTableEditModal,
    showTableDeleteModal,
    ActionDelete,
    showform,
    setShowform,
    handleEditBranch,
    handleLinkClick,
    updateStatesSingleFields,
    handleClearClick,
  } = useStatesSingle();

  const { editStates } = useSelector((state) => state.stateSinglePage);

  return (
    <>
      {statesSinglePageList?.data?.data?.state_name ? (
        <div className="pro-mb-3">
          <BreadCrumbs
            data={["States", `${statesSinglePageList?.data?.data?.state_name}`]}
            clickable
            handleClick={handleLinkClick}
          />
        </div>
      ) : (
        <div className="pro-d-flex pro-gap-1">
          <div className="shimmer pro-py-1 pro-px-6 pro-mb-1"></div>
          <div className="shimmer pro-py-1 pro-px-6 pro-mb-1"></div>
        </div>
      )}
      <HeadingGroup
        title={statesSinglePageList?.data?.data?.data?.state_name || "District"}
        className={`pro-mb-4`}
        buttonTitle={hasCreatePermission === 1 ? "Add" : ""}
        handleClick={() => {
          setShowform(true);
        }}
      />
      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        <SearchFilters
          data={statesSinglePageList?.data?.data?.data?.filters}
          onDateChange={handleDateChange}
          onSearchInput={handleSearch}
          showActions={false}
          handleActionClick={handleEditClick}
          loading={isLoading}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          //showClearFilters
          handleClear={handleClearClick}
          searchInputProps={{ value: stateSinglePage?.search }}
          actionOptions={actionOptions?.filter((item) =>
            hasExportPermission !== 1 ? item.value === 0 : true
          )}
          onActionOptionChange={handleActionChange}
        />
        {statesSinglePageList?.data?.data?.data?.data?.length === 0 ? (
          <EmptyData />
        ) : (
          <Table
            multiSelect={false}
            data={statesSinglePageList?.data?.data?.data.data || []}
            uniqueID={"_id"}
            fields={statesSinglePageList?.data?.data?.fields}
            showCheckBox={false}
            clear={stateSinglePage.clearSelection}
            SortIcon={<FaSort />}
            editIcon={<span className="material-symbols-outlined">edit</span>}
            deleteIcon={
              <span className="material-symbols-outlined">delete</span>
            }
            handleSort={handleSort}
            getRow={getRow}
            handleEdit={handleEditBranch}
            deletable={true}
            editable={false}
            loading={isFetching}
            perpage={stateSinglePage.currentPageSize}
            assignable={false}
            handleDelete={handleDelete}
          />
        )}

        {statesSinglePageList?.data?.data?.data?.data?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            totalPageCount={statesSinglePageList?.data?.data?.data?.last_page}
            defaultValue={paginationOptions?.filter(
              (item) => item.value === stateSinglePage?.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}

        <ModalLayout show={showEditModal} handleClose={closeEditModal}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              //   refetchData={refetch}
              tableFields={statesSinglePageList?.data?.data?.fields}
              moduleId={statesSinglePageList?.data?.data?.module_id}
              updateData={updateStatesSingleFields}
            />
          </div>
        </ModalLayout>

        <ModalLayout
          centered={false}
          show={showform}
          handleClose={() => {
            setShowform(false);
          }}
          title={editStates !== true ? "Add  District" : "Edit District"}
          closeIcon={<span className="material-symbols-outlined">close</span>}
        >
          <AddDistrictFormContainer setShowform={setShowform} refetch={refetch} />
        </ModalLayout>

        <ModalLayout
          centered={false}
          show={showTableDeleteModal}
          handleClose={closeTableEditModal}
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={`Are you sure you want to delete this item?`}
              isRight={true}
              cancelText={`No`}
              submitText={`Yes`}
              cancelAction={closeTableEditModal}
              submitAction={ActionDelete}
            >
              ConfirmationBox
            </ConfirmationBox>

            {true && <span className="error-message">{true}</span>}
          </div>
        </ModalLayout>
      </div>
    </>
  );
};

export default StatesSingle;
