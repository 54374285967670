import React, { useState } from "react";
import { Input, Button } from "@wac-ui-dashboard/wac_component_library";
import Select from "react-select";

const RowOne = ({
  formik,
  dobRef,
  otp,
  handlePhoneNumberChange,
  isEditProfile,
  phoneVerified,
  getFieldError,
  optionsData,
  showPassword,
  handleShowPassword,
  cuntryCodeOptions,
  handleVerifyPhone,
  handleValidatePhone,
}) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const handleMenuOpen = () => {
    setMenuIsOpen(() => true);
  };

  const handleMenuClose = () => {
    setMenuIsOpen(() => false);
  };

  const capitalizeName = (event, setFieldValue) => {
    const inputName = event.target.name;
    const inputValue = event.target.value;

    const nameParts = inputValue.split(" ");
    const capitalizedParts = nameParts.map((part) => {
      return part.charAt(0).toUpperCase() + part.slice(1);
    });

    const capitalizedFullName = capitalizedParts.join(" ");

    setFieldValue(inputName, capitalizedFullName);
  };

  const capitalizeLastName = (event, setFieldValue) => {
    const inputName = event.target.name;
    const inputValue = event.target.value;

    const capitalizedLastName =
      inputValue.charAt(0).toUpperCase() + inputValue.slice(1);

    setFieldValue(inputName, capitalizedLastName);
  };
  return (
    <div>
      <Input
        label={"First name*"}
        type="text"
        id="first_name
          "
        name="first_name
          "
        className={`pro-input lg ${
          formik.touched.first_name && formik.errors.first_name && " error"
        }`}
        {...formik.getFieldProps("first_name")}
        onChange={(event) => capitalizeName(event, formik.setFieldValue)}
        error={formik.touched.first_name && formik.errors.first_name}
        errorMessage={formik.errors.first_name}
      />
      <Input
        label={"Last name*"}
        type="text"
        id="last_name"
        name="last_name"
        className={`pro-input lg ${
          formik.touched.last_name && formik.errors.last_name && " error"
        }`}
        {...formik.getFieldProps("last_name")}
        onChange={(event) => capitalizeLastName(event, formik.setFieldValue)}
        error={formik.touched.last_name && formik.errors.last_name}
        errorMessage={formik.errors.last_name}
      />

      {!isEditProfile ? (
        <div className="input-wrap pro-mb-4">
          <div className="input-country-wrap">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Mobile number*
            </label>
            <div className="input-main-wrap verify-btn-wrap">
              <div className="code-wrap pro-mb-4 ">
                <Select
                  id="country_code"
                  options={cuntryCodeOptions}
                  // getOptionLabel={(option) => `${option.label}`}
                  getOptionValue={(option) => `${option.value}`}
                  onMenuOpen={handleMenuOpen}
                  onMenuClose={handleMenuClose}
                  menuIsOpen={menuIsOpen}
                  formatOptionLabel={({ label, title }) =>
                    menuIsOpen ? title : label
                  }
                  className={` multi-select country-select ${
                    formik.touched.country_code &&
                    formik.errors.country_code &&
                    " error"
                  }`}
                  value={cuntryCodeOptions?.filter(
                    (p) =>
                      p.value?.toString() ===
                      formik.values.country_code?.toString()
                  )}
                  classNamePrefix="pro-input"
                  onBlur={() => formik.handleBlur("country_code")}
                  onChange={(value) =>
                    formik.setFieldValue("country_code", value?.value)
                  }
                ></Select>
              </div>
              <input
                type="text"
                id="phone_number"
                name="phone_number"
                className={`pro-input lg ${
                  formik.touched.phone_number && formik.errors.phone_number && " error"
                }`}
                onBlur={() => formik.handleBlur("phone_number")}
                {...formik.getFieldProps("phone_number")}
                onChange={(e) => handlePhoneNumberChange(e.target.value)}
                
              />

              <Button
                className={"pro-btn pro-btn-primary"}
                onClick={
                  !phoneVerified?.verified
                    ? () => handleVerifyPhone()
                    : () => {}
                }
              >
                {phoneVerified?.status && !phoneVerified?.verified
                  ? `Resend OTP`
                  : phoneVerified?.verified
                  ? `Verified`
                  : `Verify`}
              </Button>
            </div>
            {(formik.errors.phone_number&&formik.touched.phone_number)&& (
              <span className="error-text">{formik.errors.phone_number}</span>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
      {phoneVerified?.status && !phoneVerified?.verified && !isEditProfile ? (
        <div className="pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            OTP
          </label>
          <div className="verify-btn-wrap">
            <input
              type="text"
              id="otp"
              name="otp"
              className={`pro-input lg ${
                formik.touched.otp && formik.errors.otp && " error"
              }`}
              {...formik.getFieldProps("otp")}
            />
            <Button
              className={"pro-btn pro-btn-primary"}
              onClick={() => handleValidatePhone()}
            >
              validate
            </Button>
          </div>
          {formik.touched.otp && formik.errors.otp && (
            <span className="error-text">{formik.errors.otp}</span>
          )}
        </div>
      ) : (
        <></>
      )}
      {/* {otp !== "" && !phoneVerified?.verified ? (
        <div className="pro-my-2 pro-pnt">OTP : {otp}</div>
      ) : (
        <></>
      )} */}

      {/* {!isEditProfile ? ( */}
      <Input
        label={"Email address*"}
        type="text"
        id="email"
        name="email"
        placeholder="name@example.com"
        className={`pro-input lg ${
          formik.touched.email && formik.errors.email && " error"
        }`}
        {...formik.getFieldProps("email")}
        onClick={() => formik.setFieldTouched("email", true)}
        error={formik.touched.email && formik.errors.email}
        errorMessage={formik.touched.email && formik.errors.email}
      />
      {/* ) : (
        <></>
      )} */}

      {!isEditProfile ? (
        <div className="p-re">
          <Input
            label={"Password*"}
            type="password"
            id="password"
            autoComplete="new-password"
            name="password"
            className={`pro-input lg ${
              formik.touched.password && formik.errors.password && " error"
            } hide-icon-input`}
            {...formik.getFieldProps("password")}
            error={formik.touched.password && formik.errors.password}
            errorMessage={formik.errors.password}
          />
          {showPassword ? (
            <button
              className={`hide-icon`}
              onClick={(e) => handleShowPassword(e)}
            >
              <span className="material-symbols-outlined">
                {" "}
                visibility_off{" "}
              </span>
            </button>
          ) : (
            <button
              className={`hide-icon`}
              onClick={(e) => handleShowPassword(e)}
            >
              <span className="material-symbols-outlined"> visibility </span>
            </button>
          )}
        </div>
      ) : (
        <></>
      )}
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Gender*
        </label>
        <Select
          id="gender"
          placeholder={"Select"}
          className={`pro-input lg  ${
            formik.touched.gender && formik.errors.gender && " error"
          }`}
          classNamePrefix="pro-input"
          options={optionsData?.gender}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.name}
          value={optionsData?.gender?.filter(
            (m) => formik.values.gender?.id === m?.id
          )}
          onBlur={formik.handleBlur("gender")}
          onChange={(value) => {
            formik.setFieldValue("gender", value || null);
            // formik.setFieldValue("dob", "");
          }}
        />

        {formik.touched.gender && formik.errors.gender && (
          <span className="error-text">{formik.errors.gender}</span>
        )}
      </div>
    </div>
  );
};

export default RowOne;
