import React from "react";
import Style from "./complaintsModal.module.scss";
import { Button, HeadingGroup } from "@wac-ui-dashboard/wac_component_library";
import useComplaintsModal from "./useComplaintsModal";

const ComplaintsModal = ({
  selectedId,
  handleClose,
  refetch,
  currentStatus,
}) => {
  const { formik } = useComplaintsModal({
    selectedId,
    handleClose,
    refetch,
    currentStatus,
  });

  return (
    <div className="row">
      <HeadingGroup
        title={`${currentStatus?.label ?? "Close"} Complaint`}
        extraClassName={`pro-mb-4 ${Style.heading}`}
      />

      <div className="col-12 pro-mb-4">
        <div className="input-wrap">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Note
          </label>
          <textarea
            id="note"
            rows={6}
            name="note"
            className={`pro-input lg ${
              formik.errors.note && formik.touched.note && " error"
            }`}
            onChange={(e) => formik.setFieldValue("note", e.target.value)}
          ></textarea>
          {formik.touched.note && formik.errors.note && (
            <span className="error-text">{formik.errors.note}</span>
          )}
        </div>
      </div>

      <div className={`pro-d-flex  col-6`}>
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => handleClose()}
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-mx-3"}
          onClick={formik.handleSubmit}
          type="submit"
        >
          Confirm
        </Button>
      </div>
    </div>
  );
};

export default ComplaintsModal;
