import Style from "./dashboardGraphShimmer.module.scss";

const DashboardGraphShimmer = ({ count }) => {
  return (
    <div className={`${Style.root} pro-p-5 pro-rounded-3`}>
      {/* <div className="pro-d-flex pro-justify-center pro-mb-6">
        <div className="pro-py-2 pro-pb-2 pro-align-self-center pro-px-6 shimmer"></div>
      </div> */}
      <div
        className={`${Style.graph} pro-d-flex pro-d-flex pro-px-3 pro-gap-4 pro-ms-5 pro-mt-6`}
      >
        {[...Array(count)].map((_, i) => (
          <div className={`${Style.graph_item_wrap} pro-d-flex`} key={i}>
            <div
              className={`${Style.graph_item} shimmer pro-px-6 pro-ps-4 pro-me-3`}
            ></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DashboardGraphShimmer;
