import React from "react";
import Style from "./addStaffForm.module.scss";
import Select from "react-select";

const AddDioceseForm = ({
  formik,
  communityList,
  optionsWithIcons,
  staffList,
  selectedOption,
  setSelectedOption,
  options,
}) => {
  return (
    <div className={Style.root}>
      <div className="col-12 pro-mb-4">
        <div className="row">
          <div className="col-12 pro-mb-4">
            <div className="input-wrap">
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                Name*
              </label>
              <input
                type="text"
                className={`pro-input lg ${
                  formik.errors.dioceseName &&
                  formik.touched.dioceseName &&
                  " error"
                }`}
                id="dioceseName"
                name="dioceseName"
                {...formik.getFieldProps("dioceseName")}
              />
              {formik.touched.dioceseName && formik.errors.dioceseName && (
                <span className="error-text">{formik.errors.dioceseName}</span>
              )}
            </div>
          </div>

          <div className="col-12 pro-mb-4">
            <div className="input-wrap">
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                Community*
              </label>
              <Select
                id="dioceseCommunity"
                name="dioceseCommunity"
                options={communityList?.data?.community}
                getOptionLabel={(option) => `${option.name}`}
                getOptionValue={(option) => `${option._id}`}
                className={` pro-input lg ${
                  formik.touched.dioceseCommunity &&
                  formik.errors.dioceseCommunity &&
                  " error"
                }`}
                value={communityList?.data?.community?.filter(
                  (p) =>
                    p?.unique_community_id?.toString() ===
                    formik.values.dioceseCommunity?.unique_community_id?.toString()
                )}
                classNamePrefix="pro-input"
                onBlur={() => formik.handleBlur("dioceseCommunity")}
                onChange={(value) =>
                  formik.setFieldValue("dioceseCommunity", value)
                }
              />
              {formik.touched.dioceseCommunity &&
                formik.errors.dioceseCommunity && (
                  <span className="error-text">
                    {formik.errors.dioceseCommunity}
                  </span>
                )}
            </div>
          </div>

          <div className="col-12 pro-mb-4">
            <div className="input-wrap">
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                Code*
              </label>
              <input
                type="text"
                className={`pro-input lg ${
                  formik.errors.dioceseCode &&
                  formik.touched.dioceseCode &&
                  " error"
                }`}
                id="dioceseCode"
                name="dioceseCode"
                {...formik.getFieldProps("dioceseCode")}
              />
              {formik.touched.dioceseCode && formik.errors.dioceseCode && (
                <span className="error-text">{formik.errors.dioceseCode}</span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddDioceseForm;
