import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import {
  useGetMemberCreationFormDataQuery,
  useGetMembersVerificationDataQuery,
  useUpdateVerificationListDataMutation,
} from "../../../store/queries/members";
import {
  getVerificationData,
  updateConfig,
} from "../../../store/slices/Users/verificationSlice";
import { getFormatedDate } from "../../../utils/functions/table";
import { PhotoRow, ProofRow } from "./Rows";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ProfileNavigation } from "../../Global/ProfileNavigation";
import { subDays } from "date-fns";

const useVerifications = (dashboard) => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const dispatch = useDispatch();
  const verificationState = useSelector((state) => state.membersVerification);

  const { showEditModal } = useSelector((state) => state.global);
  const activeProfile =
    params.get("active") ?? sessionStorage.getItem("active");

  const [showImageVerificationModal, setShowImageVerificationModal] =
    useState(false);

  const [showDocumentVerificationModal, setShowDocumentVerificationModal] =
    useState(false);
  const [imageData, setImageData] = useState("");
  const [viewId, setViewId] = useState(false);

  const [activeProfileID, setActiveProfileID] = useState("");

  const [date, setDate] = useState({
    startDate: verificationState.startDate,
    endDate: verificationState.endDate,
    key: "selection",
  });

  const {
    data: verification = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetMembersVerificationDataQuery({
    page_size: verificationState.currentPageSize,
    page: verificationState.currentPage,
    filter: verificationState.currentFilter,
    sort_by: verificationState.sortBy,
    sort_order: verificationState.sortOrder,
    start: dashboard ? "" : getFormatedDate(verificationState.startDate),
    end: dashboard ? "" : getFormatedDate(verificationState.endDate),
    search: verificationState.search,
    branch_id: verificationState?.branchId,
  });
  const { data: branchOptionsData = {} } = useGetMemberCreationFormDataQuery(
    {}
  );
  const allBranchesOption = {
    _id: "",
    name: "All Branches",
  };

  const branchesOption = [
    allBranchesOption,
    ...(branchOptionsData?.data?.branch ?? []),
  ];

  const [updateVerificationFields] = useUpdateVerificationListDataMutation();

  const hasUploadViewPermission = useMemo(() => {
    let permission = verification?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_upload_view")
    );

    return permission?.[0]?.can_upload_view ?? 0;
  }, [verification]);

  const hasViewPermission = useMemo(() => {
    let permission = verification?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_view")
    );

    return permission?.[0]?.can_view ?? 0;
  }, [verification]);

  const hasUpdatePermission = useMemo(() => {
    let permission = verification?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_update")
    );

    return permission?.[0]?.can_update ?? 0;
  }, [verification]);

  const hasExportPermission = useMemo(() => {
    let permission = verification?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_export")
    );

    return permission?.[0]?.can_export ?? 0;
  }, [verification]);

  const filters = useMemo(() => {
    if (verification?.data?.filters) {
      const buttonGroups = verification?.data?.filters?.filter((d) => !d?.type);
      return [{ type: "buttonGroup", buttons: buttonGroups }];
    } else {
      return [];
    }
    // eslint-disable-next-line
  }, [isLoading, verification]);

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;

        state.currentPage = 1;
      })
    );
  };

  const handleFilter = (filter) => {
    if (filter.value === "all") {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.currentFilter = filter.value;

          state.sortBy = "";
          state.sortOrder = "";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.currentFilter = filter.value;
        })
      );
    }
  };

  const handleDateChange = (range) => {
    setDate(range);
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = range.startDate;
        state.endDate = range.endDate;
      })
    );
  };
  const handleSort = (label) => {
    if (verificationState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder =
            verificationState.sortOrder === "asc" ? "asc" : "desc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const onImagesClick = (data) => {
    // if (!verificationState?.profileData?.[data._id]) {
    // dispatch(getVerificationData({ user_id: data._id, upload_type: 1 }));
    // }
    dispatch(getVerificationData({ user_id: data._id, upload_type: 1 }));
    setActiveProfileID(data._id);
    setShowImageVerificationModal(true);
  };

  const onDocumentClick = (data) => {
    dispatch(getVerificationData({ user_id: data._id, upload_type: 2 }));

    setActiveProfileID(data._id);
    setShowDocumentVerificationModal(true);
  };

  const closeModal = () => {
    setShowImageVerificationModal(false);
  };

  const closeDocumentModal = () => {
    setShowDocumentVerificationModal(false);
  };

  const handleEditClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
  };

  useEffect(() => {
    if (!dashboard) {
      setDate({
        startDate: subDays(new Date(), 30),
        endDate: new Date(),
        key: "selection",
      });

      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          // state.currentFilter = "all";
          state.currentPageSize = 10;
          state.startDate = subDays(new Date(), 30);
          state.endDate = new Date();
          state.sortBy = "";
          state.sortOrder = "";
          state.search = "";
          state.profileDataStatus = "idle";
          state.profileData = {};
          state.profileRejectResponse = [];
          state.updateProfileStatus = "idle";
        })
      );
    }
    // eslint-disable-next-line
  }, [dashboard]);

  const handleClearClick = () => {
    setDate({
      startDate: subDays(new Date(), 30),
      endDate: new Date(),
      key: "selection",
    });

    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentFilter = "all";
        state.currentPageSize = 10;
        state.startDate = subDays(new Date(), 30);
        state.endDate = new Date();
        state.sortBy = "";
        state.sortOrder = "";
        state.search = "";
        state.profileDataStatus = "idle";
        state.profileData = {};
        state.profileRejectResponse = [];
        state.updateProfileStatus = "idle";
      })
    );
  };
  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      photo: (_, data) =>
        //FIXME verification icon should be from the front end. After image is verified, the logo should change
        hasUpdatePermission === 1 && !dashboard ? (
          <PhotoRow data={data} onClick={() => onImagesClick(data)} />
        ) : (
          <PhotoRow data={data} />
        ),
      id_proof: (_, data) =>
        hasUpdatePermission === 1 && !dashboard ? (
          <ProofRow data={data} onClick={() => onDocumentClick(data)} />
        ) : (
          <ProofRow data={data} />
        ),
      name: (_, data) => (
        <div className="pro-py-1">
          <ProfileNavigation
            hasViewPermission={
              hasViewPermission === 1 && !dashboard ? true : false
            }
            userId={data?._id}
            fieldValue={data?.name}
            activeProfile={activeProfile}
            params={params}
          />
        </div>
      ),
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };
  let actionOptions = [
    { label: "Edit Columns", value: 0 },
    { label: "Export view", value: 1 },
  ];

  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    } else {
    }
  };

  const closeIdModal = () => {
    setShowDocumentVerificationModal(() => true);
    setViewId(() => false);
  };

  const handleDashboardRedirect = (filter) => {
    navigate("/users/verification");
  };
  const handleDateRangeChange = (range) => {
    const startDate = range[0];
    const endDate = range[1];

    setDate({
      startDate,
      endDate,
    });

    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = startDate;
        state.endDate = endDate;
      })
    );
  };
  const handleTableFilter = (value) => {
    dispatch(
      updateConfig((state) => {
        state.branchId = value?._id;
      })
    );
  };

  return {
    verification,
    verificationState,
    filters,
    activeProfileID,
    currentPage: verificationState.currentPage,
    activeFilter: verificationState.currentFilter,
    showImageVerificationModal,
    showDocumentVerificationModal,
    paginationOptions,
    isFetching,
    isLoading,
    viewId,
    imageData,
    actionOptions,
    hasExportPermission,
    handleDateRangeChange,
    handleDashboardRedirect,
    handleActionChange,
    setImageData,
    setViewId,
    closeIdModal,
    setShowImageVerificationModal,
    setShowDocumentVerificationModal,
    closeDocumentModal,
    handlePagination,
    handlePageSize,
    handleFilter,
    handleDateChange,
    handleSort,
    handleSearch,
    getRow,
    closeModal,
    handleEditClick,
    closeEditModal,
    showEditModal,
    refetch,
    updateVerificationFields,
    hasUploadViewPermission,
    hasViewPermission,
    hasUpdatePermission,
    branchesOption,
    handleClearClick,
    handleTableFilter,
    date,
  };
};

export default useVerifications;
