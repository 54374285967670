import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../../api";

const initialState = {
  favouritesStatus: "idle",
  favouritesCountStatus: "idle",
  favouritesData: {},
  favouritesCount: {},
  favouritesPermissions: [],
  selectedProfiles: [],
  currentPage: 1,
};

const cache = {};
export const getFavouritesDetails = createAsyncThunk(
"favourites/getFavouritesDetails",
  async (params, { rejectWithValue }) => {
    const cacheKey = JSON.stringify(params);

    if (cache[cacheKey]) {
      return cache[cacheKey];
    }
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        params.activeFilter === 1
          ? `/admin/members/profile/favourites/other?user_id=${params.user_id}&page_size=21&page=${params?.page}&type=${params.type}`:
             `/admin/members/profile/favourites?user_id=${params.user_id}&page_size=21&page=${params?.page}&type=${params.type}`
      );
      cache[cacheKey] = response;

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getFavouritesCount = createAsyncThunk(
  "favourites/getFavouritesCount",
  async (params, { rejectWithValue }) => {
    const cacheKey = JSON.stringify(params);

    if (cache[cacheKey]) {
      return cache[cacheKey];
    }
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        params.activeFilter === 1
          ? `/admin/members/profile/favourites/other?user_id=${params.user_id}&page_size=21&page=${params?.page}&type=${params.type}`:
          `/admin/members/profile/favourites?user_id=${params.user_id}&page_size=21&page=${params?.page}&type=${params.type}`
      );
      cache[cacheKey] = response;

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const favouritesSlice = createSlice({
  name: "favourites",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: {
    [getFavouritesDetails.pending]: (state, action) => {
      state.favouritesStatus = "pending";
    },
    [getFavouritesDetails.fulfilled]: (state, action) => {
      state.favouritesPermissions = action?.payload?.data?.data?.permission;
      let newArry = state.favouritesData[action.meta?.arg?.user_id]
        ? current(state.favouritesData[action.meta?.arg?.user_id]?.data ?? [])
        : [];

      let tempArry = [
        ...newArry,
        ...(action?.payload?.data?.data?.data
          ? action?.payload?.data?.data?.data
          : []),
      ];

      state.selectedProfiles = tempArry
        ?.filter((item) => Boolean(item.short_listed))
        ?.map((item) => item?._id);

      if (state.favouritesData?.[action.meta?.arg?.user_id]) {
        state.favouritesData[action.meta?.arg?.user_id] = {
          ...action.payload?.data?.data,
          favouritesStatus: "fulfilled",
          data: [
            ...state.favouritesData[action.meta?.arg?.user_id].data,
            ...action.payload?.data?.data?.data,
          ],
        };
      } else {
        state.favouritesData[action.meta?.arg?.user_id] = {
          ...action.payload?.data?.data,
          favouritesStatus: "fulfilled",
          page: 1,
        };
      }
      state.favouritesStatus = "fulfilled";
    },
    [getFavouritesDetails.rejected]: (state) => {
      state.favouritesStatus = "failed";
    },

    [getFavouritesCount.pending]: (state, action) => {
      state.favouritesCountStatus = "pending";
    },
    [getFavouritesCount.fulfilled]: (state, action) => {
      state.favouritesCount[action.meta?.arg?.user_id] =
        action.payload?.data?.data;
      // state.favouritesCount[action.meta?.arg?.user_id].favouritesCountStatus =
      //   "fulfilled";

      state.favouritesCountStatus = "fulfilled";
    },
    [getFavouritesCount.rejected]: (state) => {
      state.favouritesCountStatus = "failed";
    },
  },
});

export const { updateConfig } = favouritesSlice.actions;

export default favouritesSlice.reducer;
