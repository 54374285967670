import { createSlice } from "@reduxjs/toolkit";
import { subDays } from "date-fns";

const initialState = {
  currentPage: 1,
  currentFilter: "all",
  startDate: subDays(new Date(), 30),
  endDate: new Date(),
  currentPageSize: 10,
  sortBy: "",
  sortOrder: "desc",
  search: "",
  
};

/**
 * @example {
 
 *  "filter":"all"
 *  "sort_by":"name"
 *  "sort_order":"asc"
 *  "page_size":"10"
 *  "page":"1"
 *  }
 */

const incompleteSlice = createSlice({
  name: "incomplete",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = incompleteSlice.actions;

export default incompleteSlice.reducer;
