import { useFormik } from "formik";
import * as Yup from "yup";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { updateConfig } from "../../../../store/slices/Settings/faqsSlice";

import { useSelector } from "react-redux";

import {
  getStatusDetails,
  updateItemFromTableByID,
  getFollowUpDetailsByID,
} from "../../../../store/slices/payment/followUpSlice";
import { useEffect } from "react";

const useAddBranchForm = ({ setShowform, refetch }) => {
  const [errors, setErrors] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [options, setOptions] = useState("");
  const [singleFollowUpDetails, setSingleFollowUpDetails] = useState([]);

  const imageFileInputRef = useRef(null);

  const followup = useSelector((state) => state.followup);

  const dispatch = useDispatch();

  var validationSchema = Yup.object({
    status: Yup.string().required("Required"),
    comment: Yup.string().required("Required"),
  });

  useEffect(() => {
    dispatch(getStatusDetails({ id: 123 }))
      .unwrap()
      .then((result) => {
        if (result?.data?.success === true) {
          const modifiedStatuses = result.data.data.follow_up_status.map(
            (status) => ({
              value: status.id,
              label: status.name,
            })
          );
          setOptions(modifiedStatuses);
        }
      })
      .catch((err) => {});

    dispatch(
      getFollowUpDetailsByID({ follow_up_id: followup.selectedFollowUpID })
    )
      .unwrap()
      .then((result) => {
        if (result?.data?.success === true) {
          setSingleFollowUpDetails(result.data.data);
        }
      })
      .catch((err) => {});
    // eslint-disable-next-line
  }, [followup.selectedFollowUpID]);

  const [selectedOption, setSelectedOption] = useState(null);

  const formik = useFormik({
    initialValues: {
      status:
        followup?.selectedItemsDetails.bride_name !== ""
          ? followup?.selectedItemsDetails?.bride_name
          : "",
      comment:
        followup?.selectedItemsDetails.groom_name !== ""
          ? followup?.selectedItemsDetails?.groom_name
          : "",
    },

    validationSchema: validationSchema,
    // enableReinitialize:true,
    onSubmit: (values, { resetForm, setFieldError }) => {
      handleFormSubmit(values, resetForm, setFieldError);
    },
  });

  const handleFormSubmit = async (values, resetForm, setFieldError) => {
    try {
      if (true) {
        try {
          let formData = new FormData();

          formData.append("_method", "PUT");
          formData.append("status", values.status);
          formData.append("note", values.comment);

          formData.append("follow_up_id", followup?.selectedFollowUpID);

          dispatch(updateItemFromTableByID(formData))
            .unwrap()
            .then((data) => {
              setShowform(false);
              refetch();
              toast.success("Successfully submitted", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            })
            .catch((err) => {
              if (err.message.note !== "") {
                setFieldError("comment", err?.message?.note);
              }
            });
        } catch (error) {}
      }
    } catch (error) {
      setErrors("Something went wrong!");
    }
  };

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
  };

  const handleFileChange = (event) => {
    formik.setFieldValue("image", event.currentTarget.files[0]);
  };

  return {
    errors,
    message,
    setLoading,
    setMessage,
    setOptions,
    formik,
    loading,
    handleCloseModal,
    imageFileInputRef,
    selectedOption,
    setSelectedOption,
    options,
    handleFileChange,
    singleFollowUpDetails,
  };
};

export default useAddBranchForm;
