import {
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../Global/OrderColumn";
// import AddFormContainer from "./AddForm";
import useDataUpdation from "./useDataUpdation";
import DataUpdationModal from "./DataUpdationModal";
import EmptyData from "../../Global/EmptyData";
import Style from "./dataUpdation.module.scss";
import DatePickerRsuite from "../../Global/CustomDateRangePicker";

const DataUpdation = ({ dashboard = false }) => {
  const {
    DataUpdationList,
    dataUpdation,
    currentPage,
    paginationOptions,
    isFetching,
    isLoading,
    actionOptions,
    hasExportPermission,
    date,
    handleActionChange,
    handleDateRangeChange,
    refetch,
    showDataUpdationModal,
    selectedId,
    closeActionModal,
    handlePagination,
    handlePageSize,
    handleDateChange,
    handleDashboardRedirect,
    handleSort,
    handleSearch,
    getRow,
    handleEditClick,
    closeEditModal,
    showEditModal,
    handleDelete,

    handleEditAction,
    updateDataUpdationFields,
    handleClearClick,
  } = useDataUpdation(dashboard);

  return (
    <>
      {!dashboard ? (
        <HeadingGroup title={"Data Updation"} className={`pro-mb-4`} />
      ) : (
        <></>
      )}

      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        {!dashboard ? (
          <SearchFilters
            data={DataUpdationList?.data?.filters}
            //showDateRange
            dropDownFilter={
              <div className="col-auto">
                <DatePickerRsuite
                  defaultDateRange={date}
                  handleDateRangeChange={handleDateRangeChange}
                />
              </div>
            }
            onDateChange={handleDateChange}
            initialDateRange={date}
            onSearchInput={handleSearch}
            showActions={false}
            handleActionClick={handleEditClick}
            loading={isLoading}
            SearchIcon={
              <span className="material-symbols-outlined"> search </span>
            }
            //showClearFilters
            handleClear={handleClearClick}
            searchInputProps={{ value: dataUpdation?.search }}
            actionOptions={actionOptions?.filter((item) =>
              hasExportPermission !== 1 ? item.value === 0 : true
            )}
            onActionOptionChange={handleActionChange}
          />
        ) : (
          <></>
        )}
        {DataUpdationList?.data?.data?.length === 0 ? (
          <EmptyData />
        ) : (
          <div
            onClick={dashboard ? () => handleDashboardRedirect() : () => {}}
            className={!dashboard ? "pro-pt-3" : Style.dashboardTable}
          >
            <Table
              multiSelect={false}
              isSelectBox={dashboard ? false : true}
              data={DataUpdationList?.data?.data || []}
              uniqueID={"_id"}
              fields={DataUpdationList?.data?.fields}
              SortIcon={<FaSort />}
              editIcon={<span className="material-symbols-outlined">edit</span>}
              deleteIcon={
                <span className="material-symbols-outlined">delete</span>
              }
              handleSort={handleSort}
              getRow={getRow}
              loading={isFetching}
              perpage={dataUpdation?.currentPageSize}
              assignable={false}
              handleEdit={handleEditAction}
              handleDelete={handleDelete}
              deletable={false}
              editable={false}
              showCheckBox={false}
              extraClasssName={"dashboard_no_padding"}
            />
          </div>
        )}

        {DataUpdationList?.data?.data?.length > 0 && !dashboard && (
          <Pagination
            currentPage={currentPage}
            totalPageCount={Math.ceil(
              DataUpdationList?.data?.total / dataUpdation.currentPageSize
            )}
            defaultValue={paginationOptions?.filter(
              (item) => item.value === dataUpdation?.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}

        {!dashboard ? (
          <>
            <ModalLayout show={showEditModal} handleClose={closeEditModal}>
              <div className="pro-m-5">
                <OrderColumn
                  title={"Choose which columns you see"}
                  refetchData={refetch}
                  tableFields={DataUpdationList?.data?.fields}
                  moduleId={DataUpdationList?.data?.module_id}
                  updateData={updateDataUpdationFields}
                />
              </div>
            </ModalLayout>

            <ModalLayout
              show={showDataUpdationModal}
              handleClose={closeActionModal}
            >
              <div className="pro-m-5">
                <DataUpdationModal
                  selectedId={selectedId}
                  handleClose={closeActionModal}
                  refetch={refetch}
                />
              </div>
            </ModalLayout>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default DataUpdation;
