import React from "react";
import {
  // DatePickerWithInput,
  Input,
} from "@wac-ui-dashboard/wac_component_library";
import Select from "react-select";
import { format, parse } from "date-fns";
// import { getFormatedDate } from "../../../../../../utils/functions/table";
// import { DatePickerWithInput } from "../../../../../Global/DatePickerWithInput";
// import InputDatePicker from "../../../../../Global/InputDatePicker";

const RowTwo = ({
  formik,
  dobRef,
  stateRef,
  districtRef,
  getFieldError,
  optionsData,
  currentFormData,
}) => {
  let maxDate = new Date();
  maxDate.setFullYear(
    maxDate.getFullYear() - (formik.values.gender?.id === 1 ? 21 : 18)
  );

  let minDate = new Date();
  minDate.setFullYear(minDate.getFullYear() - 70);

  const reverseDateString = (dateString) => {
    const parts = dateString.split("-");
    const reversedDateString = parts[2] + "-" + parts[1] + "-" + parts[0];
    return reversedDateString;
  };
  let dateStr = reverseDateString(formik.values.dob);

  const handleDobChange = (e) => {
    const dateValue = e.target.value;
    const parsedDate = parse(dateValue, "yyyy-MM-dd", new Date());

    if (isNaN(parsedDate)) {
      // Handle invalid date input
      // For example, display an error message or set a flag
      return;
    }

    formik.setFieldValue("dob", format(parsedDate, "dd-MM-yyyy"));

    // Rest of the code...
  };

  return (
    <>
      <div className="input-wrap ">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Date of Birth*
        </label>
        <div className={`pro-w-100 pro-d-flex pro-flex-column`}>
          <Input
            value={formik.values.dob === "" ? "yyyy-MM-dd" : dateStr}
            type={"date"}
            min={minDate.toLocaleDateString("fr-ca")}
            max={maxDate.toLocaleDateString("fr-ca")}
            className={`pro-input lg ${
              formik.touched.dob && formik.errors.dob && "error"
            }`}
            onChange={handleDobChange}
            onBlur={formik.handleBlur("dob")}
            error={formik.touched.dob && formik.errors.dob}
            errorMessage={formik.errors.dob}
          />
        </div>
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Height
        </label>
        <Select
          id="height"
          isClearable={true}
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("height") && " error"}`}
          classNamePrefix="pro-input"
          options={optionsData?.height}
          getOptionValue={(option) => option?._id}
          getOptionLabel={(option) => option?.name}
          value={optionsData?.height?.filter(
            (m) => formik.values.height?._id === m?._id
          )}
          onChange={(value) => formik.setFieldValue("height", value || null)}
        ></Select>
        {getFieldError("height") && (
          <span className="error-text">{getFieldError("height")}</span>
        )}
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Weight
        </label>
        <Select
          id="weight"
          isClearable={true}
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("weight") && " error"}`}
          classNamePrefix="pro-input"
          options={optionsData?.weight}
          getOptionValue={(option) => option?._id}
          getOptionLabel={(option) => option?.kgs}
          value={optionsData?.weight?.filter(
            (m) => formik.values.weight?._id === m?._id
          )}
          onChange={(value) => formik.setFieldValue("weight", value || null)}
        ></Select>
        {getFieldError("weight") && (
          <span className="error-text">{getFieldError("weight")}</span>
        )}
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Marital Status
        </label>
        <Select
          id="marital_status"
          placeholder={"Select"}
          className={`pro-input lg  ${
            formik.errors.marital_status &&
            formik.touched.marital_status &&
            " error"
          }`}
          classNamePrefix="pro-input"
          options={optionsData?.marital_status}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.name}
          value={optionsData?.marital_status?.filter(
            (m) => formik.values.marital_status?.id === m?.id
          )}
          onBlur={formik.handleBlur("marital_status")}
          onChange={(value) =>
            formik.setFieldValue("marital_status", value || null)
          }
        ></Select>
        {formik.touched.marital_status && formik.errors.marital_status && (
          <span className="error-text">{formik.errors.marital_status}</span>
        )}
      </div>

      {formik.values.marital_status !== "" &&
      formik.values?.marital_status &&
      formik.values.marital_status?.name !== "Unmarried" ? (
        <Input
          label={"Number of Children"}
          type="text"
          id="no_of_children"
          name="no_of_children"
          className={`pro-input lg ${
            getFieldError("no_of_children") && " error"
          }`}
          {...formik.getFieldProps("no_of_children")}
          error={getFieldError("no_of_children")}
          errorMessage={getFieldError("no_of_children")}
        />
      ) : (
        <></>
      )}

      <div className={"input-wrap pro-mb-5"}>
        <label
          htmlFor="current_location"
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          Country
        </label>
        <Select
          id="country"
          isClearable={true}
          placeholder={"Select"}
          className={"pro-input lg"}
          classNamePrefix="pro-input"
          options={optionsData?.country}
          getOptionValue={(option) => option?._id}
          getOptionLabel={(option) => option?.name}
          value={optionsData?.country?.filter(
            (m) => formik.values.country?._id === m?._id
          )}
          onChange={(value) => {
            formik.setFieldValue("country", value || null);
            stateRef.current.setValue([], "clear");
            districtRef.current.setValue([], "clear");
          }}
        />
      </div>

      <div className={"input-wrap pro-mb-5"}>
        <label
          htmlFor="current_location"
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          State
        </label>
        <Select
          id="state"
          isClearable={true}
          ref={stateRef}
          isDisabled={formik.values?.country?._id ? false : true}
          placeholder={"Select"}
          className={"pro-input lg"}
          classNamePrefix="pro-input"
          options={optionsData?.state?.filter(
            (p) => p?.country_id === formik.values.country?.unique_country_id
          )}
          getOptionValue={(option) => option?._id}
          getOptionLabel={(option) => option?.name}
          value={optionsData?.state?.filter(
            (m) => formik.values.state?._id === m?._id
          )}
          onChange={(value) => {
            formik.setFieldValue("state", value || null);
            districtRef.current.setValue([], "clear");
          }}
        />
      </div>

      <div className={"input-wrap pro-mb-5"}>
        <label
          htmlFor="current_location"
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          District
        </label>
        <Select
          id="district"
          ref={districtRef}
          isDisabled={formik.values?.state?._id ? false : true}
          isClearable={true}
          placeholder={"Select"}
          className={"pro-input lg"}
          classNamePrefix="pro-input"
          menuPlacement="auto"
          options={optionsData?.district?.filter(
            (p) => p?.state_id === formik.values.state?.unique_state_id
          )}
          getOptionValue={(option) => option?._id}
          getOptionLabel={(option) => option?.name}
          value={optionsData?.district?.filter(
            (m) => formik.values.district?._id === m?._id
          )}
          onChange={(value) => formik.setFieldValue("district", value || null)}
        />
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Physical Status
        </label>
        <Select
          id="physical_status"
          isClearable={true}
          placeholder={"Select"}
          className={`pro-input lg  ${
            getFieldError("physical_status") && " error"
          }`}
          classNamePrefix="pro-input"
          options={optionsData?.physical_status}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.name}
          value={optionsData?.physical_status?.filter(
            (m) => formik.values.physical_status?.id === m?.id
          )}
          onChange={(value) =>
            formik.setFieldValue("physical_status", value || null)
          }
        ></Select>
        {getFieldError("physical_status") && (
          <span className="error-text">{getFieldError("physical_status")}</span>
        )}
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Body Type
        </label>
        <Select
          id="body_type"
          isClearable={true}
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("body_type") && " error"}`}
          classNamePrefix="pro-input"
          options={optionsData?.body_type}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.name}
          value={optionsData?.body_type?.filter(
            (m) => formik.values.body_type?.id === m?.id
          )}
          onChange={(value) => formik.setFieldValue("body_type", value || null)}
        ></Select>
        {getFieldError("body_type") && (
          <span className="error-text">{getFieldError("body_type")}</span>
        )}
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Complexion
        </label>
        <Select
          id="body_complexion"
          isClearable={true}
          placeholder={"Select"}
          className={`pro-input lg  ${
            getFieldError("body_complexion") && " error"
          }`}
          classNamePrefix="pro-input"
          options={optionsData?.body_complexion}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.name}
          value={optionsData?.body_complexion?.filter(
            (m) => formik.values.body_complexion?.id === m?.id
          )}
          onChange={(value) =>
            formik.setFieldValue("body_complexion", value || null)
          }
        ></Select>
        {getFieldError("body_complexion") && (
          <span className="error-text">{getFieldError("body_complexion")}</span>
        )}
      </div>
    </>
  );
};

export default RowTwo;
