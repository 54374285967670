import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGetStaffRolesDataQuery } from "../../../store/queries/settings";
import { updateConfig } from "../../../store/slices/Settings/roleSlice";
import {
  UserRow,
  PaymentIcon,
  CMSIcon,
  SettingsIcon,
  Dashboard,
  NotificationIcon,
} from "./Rows";

const useRoles = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const rolesState = useSelector((state) => state.roles);

  const { data: roles = {}, isFetching } = useGetStaffRolesDataQuery({});

  const [showModal, setShowModal] = useState(false);

  const hasCreatePermission = useMemo(() => {
    let permission = roles?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_create")
    );
    return permission?.[0]?.can_create ?? 0;
  }, [roles]);
  const hasEditPermission = useMemo(() => {
    let permission = roles?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_edit")
    );
    return permission?.[0]?.can_edit ?? 0;
  }, [roles]);
  const hasUpdatePermission = useMemo(() => {
    let permission = roles?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_update")
    );
    return permission?.[0]?.can_update ?? 0;
  }, [roles]);

  const handleSort = (label) => {
    if (rolesState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = rolesState.sortOrder === "asc" ? "asc" : "desc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleCreateClick = () => {
    setShowModal(true);
  };

  const handleEdit = (item) => {
    navigate({
      pathname: "/configure/roles/permission",
      search: `role=${item[0]}&update=true`,
    });
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      dashboard: (_, data) => <Dashboard data={data} />,
      user: (_, data) => <UserRow data={data} />,
      payments: (_, data) => <PaymentIcon data={data} />,
      cms: (_, data) => <CMSIcon data={data} />,
      configure: (_, data) => <SettingsIcon data={data} />,
      support: (_, data) => <NotificationIcon data={data} />,
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  return {
    roles,
    rolesState,
    isFetching,
    showModal,
    hasCreatePermission,
    hasEditPermission,
    hasUpdatePermission,
    handleCreateClick,
    handleSort,
    handleEdit,
    getRow,
    closeModal,
  };
};

export default useRoles;
