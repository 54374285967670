import { useFormik } from "formik";
import * as Yup from "yup";
import { useRef, useState } from "react";
// import { useDispatch } from "react-redux";
// import { updateConfig } from "../../../../store/slices/Settings/faqsSlice";
import {
  useCreateNewBranchDataMutation,
  useEditBranchDataMutation,
} from "../../../../store/queries/settings";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/Settings/branchesSlice";

const useAddBranchForm = ({setShowform,refetch}) => {

  const dispatch = useDispatch();
  const [errors, setErrors] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const imageFileInputRef = useRef(null);

  const branches = useSelector((state) => state.branches);


  let addPaymentVal = Yup.object({
    name: Yup.string().required("*Required"),
    // branch_admin: Yup.string().required("*Required"),
  });

  const [addnewBranch] = useCreateNewBranchDataMutation();
  const [editBranch] = useEditBranchDataMutation();

  const formik = useFormik({
    initialValues: {
      name: branches.editBranch === true ? branches.selectedBranch.name : "",
      // branch_admin: "",
    },

    validationSchema: addPaymentVal,

    onSubmit: (values, { resetForm, setFieldError }) => {
      handleFormSubmit(values, resetForm, setFieldError);
    },
  });

  const handleFormSubmit = async (values, resetForm, setFieldError) => {
    try {
      try {
        if (branches.editBranch === false) {
          let obj = {
            name: values.name,
          };

          addnewBranch(obj).then((data) => {
            if (data.data.success === true) {
              setShowform(false);
              refetch?.()
              resetForm();
              toast.success("Branch Added Successfully");
            } else if (data.data.success === false) {
              toast.error("Failed to Add Branch ");
              if (data.data.message.name !== "") {
                setFieldError("name", data.data.message.name);
              } else {
                setFieldError("name", "Something went worng!");
              }
            }
          });
        } else {
          let obj = {
            name: values.name,
            branch_id: branches.selectedBranch.branch_id,
          };

          editBranch(obj).then((data) => {
            if (data.data.success === true) {
              setShowform(false);
              refetch?.()
              dispatch(
                updateConfig((state) => {
                  state.clearSelection = true;
                })
              );
              resetForm();
              toast.success("Branch Updated Successfully");
            } else if (data.data.success === false) {
              toast.error("Failed to Update Branch ");
              if (data.data.message.name !== "") {
                setFieldError("name", data.data.message.name);
              } else {
                setFieldError("name", "Something went worng!");
              }
            }
          });
        }
      } catch (error) {}
    } catch (error) {
      setErrors("Something went wrong!");
    }
  };

  const handleCloseModal = () => {
    setShowform(false);
  };

  return {
    errors,
    message,
    setMessage,
    loading,
    setLoading,
    formik,
    handleCloseModal,
    imageFileInputRef,
  };
};

export default useAddBranchForm;
