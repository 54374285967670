import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  sortBy: "",
  sortOrder: "desc",
  data: [],
  showCreateModal: false,
  selectedDiocese: "",
  editDiocese: false,
  clearSelection: false,
  search: "",
  preSelectedCommunity: null,
};

export const saveDiocesesDetails = createAsyncThunk(
  "dioceses/saveDiocesesDetails",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("/admin/diocese/create", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const saveParish = createAsyncThunk(
  "dioceses/saveParish",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("admin/parish/create", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateDiocesesDetails = createAsyncThunk(
  "dioceses/updateDiocesesDetails",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.put("/admin/diocese/update", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteParishFromDiocese = createAsyncThunk(
  "branches/deleteParishFromDiocese",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("admin/diocese/remove/parish", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getDioceseDetailsByID = createAsyncThunk(
  "branches/getDioceseDetailsByID",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `/admin/diocese/edit?${new URLSearchParams(params).toString()}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const dioceseSlice = createSlice({
  name: "diocesesSingle",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
    selectDiocese: (state, action) => {
      state.selectedDiocese = action.payload;
    },
    editDiocese: (state, action) => {
      state.editDiocese = action.payload;
    },

    setPreSelectedCommunity: (state, action) => {
      state.preSelectedCommunity = action.payload;
    },
  },
  extraReducers: {
    [getDioceseDetailsByID.pending]: (state, action) => {},
    [getDioceseDetailsByID.fulfilled]: (state, action) => {
      state.selectedDiocese = action.payload.data.data;
    },
    [getDioceseDetailsByID.rejected]: (state, action) => {},
  },
});

export const {
  updateConfig,
  selectDiocese,
  editDiocese,
  setPreSelectedCommunity,
} = dioceseSlice.actions;

export default dioceseSlice.reducer;
