import React from "react";
import { IconText, Image } from "@wac-ui-dashboard/wac_component_library";
import Select from "react-select";
import Style from "../basic.module.scss";

const RowThree = ({
  formik,
  getFieldError,
  optionsData,
  parishRef,
  dioceseRef,
  imagePreview,
  isEditProfile,
  activeImageIndex,
  handleThumbClick,
  currentFormData,
  handleProfileImage,
  profilefileInputRef,
  canChangePermission,
}) => {
  let dioceseOptions = optionsData?.community?.filter(
    (item) => item?._id === formik.values?.community_id?._id
  )?.[0]?.dioceses;
  let parishOptions = dioceseOptions?.filter(
    (item) => item?._id === formik.values?.diocese_id?._id
  )?.[0]?.parishes;

  return (
    <>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Community*
        </label>
        <Select
          id="community_id"
          placeholder={"Select"}
          className={`pro-input lg  ${
            formik.touched.community_id &&
            formik.errors.community_id &&
            " error"
          }`}
          classNamePrefix="pro-input"
          isDisabled={isEditProfile && !canChangePermission}
          options={optionsData?.community}
          getOptionValue={(option) => option?._id}
          getOptionLabel={(option) => option?.name}
          onBlur={formik.handleBlur("community_id")}
          value={formik?.values?.community_id}
          onChange={(value) => {
            formik.setFieldValue("community_id", value || null);
            parishRef.current.setValue([], "clear");
            dioceseRef.current.setValue({}, "clear");
          }}
        ></Select>
        {formik.touched.community_id && formik.errors.community_id && (
          <span className="error-text">{formik.errors.community_id}</span>
        )}
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Diocese*
        </label>
        <Select
          id="diocese_id"
          ref={dioceseRef}
          isDisabled={
            (formik.values?.community_id?._id && !isEditProfile ? false : true) && !canChangePermission
          }
          placeholder={"Select"}
          className={`pro-input lg  ${
            formik.touched.diocese_id &&
            formik.errors.diocese_id &&
            formik.values?.community_id?._id &&
            " error"
          }`}
          classNamePrefix="pro-input"
          // options={formik.values.community_id?.dioceses}
          options={dioceseOptions}
          getOptionValue={(option) => option?._id}
          getOptionLabel={(option) => option?.name}
          onBlur={formik.handleBlur("diocese_id")}
          value={formik?.values?.diocese_id}
          onChange={(value) =>
            formik.setFieldValue("diocese_id", value || null)
          }
        ></Select>
        {formik.touched.diocese_id &&
          formik.errors.diocese_id &&
          formik.values?.community_id?._id && (
            <span className="error-text">{formik.errors.diocese_id}</span>
          )}
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Parish
        </label>
        <Select
          ref={parishRef}
          isDisabled={formik.values?.diocese_id?._id ? false : true}
          id="parish_id"
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("parish_id") && " error"}`}
          classNamePrefix="pro-input"
          // options={formik.values.diocese_id?.parishes}
          options={parishOptions}
          getOptionValue={(option) => option?._id}
          getOptionLabel={(option) => option?.full_name}
          value={formik?.values?.parish_id}
          onChange={(value) => formik.setFieldValue("parish_id", value || null)}
        ></Select>
        {getFieldError("parish_id") && (
          <span className="error-text">{getFieldError("parish_id")}</span>
        )}
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Mother Tongue
        </label>
        <Select
          id="mother_tongue"
          isClearable={true}
          placeholder={"Select"}
          className={`pro-input lg  ${
            getFieldError("mother_tongue") && " error"
          }`}
          classNamePrefix="pro-input"
          options={optionsData?.language}
          getOptionValue={(option) => option?._id}
          getOptionLabel={(option) => option?.name}
          value={optionsData?.language?.filter(
            (m) => formik.values.mother_tongue?._id === m?._id
          )}
          onChange={(value) =>
            formik.setFieldValue("mother_tongue", value || null)
          }
        ></Select>
        {getFieldError("mother_tongue") && (
          <span className="error-text">{getFieldError("mother_tongue")}</span>
        )}
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          About myself
        </label>
        <textarea
          id="about_me"
          rows={4}
          name="about_me"
          className={`pro-input lg ${
            formik.touched.about_me && formik.errors.about_me && " error"
          }`}
          {...formik.getFieldProps("about_me")}
        ></textarea>
        { formik.touched.about_me && formik.errors.about_me && (
          <span className="error-text">{formik.errors.about_me}</span>
        )}
      </div>
      {!isEditProfile ? (
        <>
          <div className="input-wrap pro-mb-4">
            <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
              Photo
            </label>
            <div className="input-drag">
              <input
                type="file"
                multiple
                accept=".jpeg, .jpg , .png"
                placeholder="placeholder"
                ref={profilefileInputRef}
                className={`pro-input lg ${
                  getFieldError("profile_images") && " error"
                }`}
                id="profile_images"
                name="profile_images"
                onBlur={formik.handleBlur("profile_images")}
                onChange={(e) => handleProfileImage(e)}
              />

              <span className="input-drag-box">
                {formik.values.profile_images?.length === 0 ? (
                  <IconText title={`Drop files to attach or browse`} />
                ) : formik.values.profile_images?.[0]?.url ? (
                  <IconText
                    title={`${formik.values.profile_images?.length} images`}
                  />
                ) : formik.values.profile_images?.[0]?.name ? (
                  <IconText
                    title={`${formik.values.profile_images?.length} images`}
                  />
                ) : (
                  <></>
                )}
              </span>

              {getFieldError("profile_images") && (
                <span className="error-text">
                  {getFieldError("profile_images")}
                </span>
              )}
            </div>
          </div>
          <span className="pro-font-sm ">Max upload file size 30 MB</span>
          {formik?.values?.profile_images ? (
            <div
              className={`${Style.root_image_lists} col-12 pro-mb-3 pro-pb-3`}
            >
              <div className={`row`}>
                <label
                  htmlFor=""
                  className="pro-font-sm pro-mb-3 pro-fw-medium "
                >
                  Select Primary image
                </label>

                {Object.values(imagePreview)?.map((url, index) => {
                  return (
                    <div
                      className={`col-2`}
                      key={index}
                      onClick={() => handleThumbClick(index)}
                    >
                      <div
                        className={`${Style.root_image_inner} ${
                          activeImageIndex === index ? Style.checked : ""
                        } ${imagePreview.isPrimary === 1 ? Style.checked : ""}`}
                      >
                        <Image
                          width={100}
                          height={100}
                          src={url}
                          alt={`image - 01`}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default RowThree;
