import Style from "./activityMonitorShimmer.module.scss";

const ActivityMonitorShimmer = ({ idle = true }) => {
  return (
    <div className="pro-mt-6">
      {idle && (
        <div className="pro-d-flex pro-justify-between pro-mb-5">
          <div className={`shimmer ${Style.title}`}></div>
          <div className="pro-px-6 pro-py-5 shimmer"></div>
        </div>
      )}

      <div className={`pro-d-flex pro-gap-5 ${Style.root}`}>
        <div
          className={`pro-px-5 shimmer pro-rounded-circle ${Style.left_img}`}
        ></div>
        <div className="pro-w-100">
          <div className={`${Style.text} pro-py-1 pro-mt-2 shimmer`}></div>
          <div className="pro-gap-2 pro-d-flex">
            <div className="pro-px-3 pro-py-3 shimmer pro-rounded-circle"></div>
            <div className="pro-px-3 pro-py-3 shimmer pro-rounded-circle"></div>
            <div className="pro-px-3 pro-py-3 shimmer pro-rounded-circle"></div>
            <div className="pro-px-3 pro-py-3 shimmer pro-rounded-circle"></div>
            <div className="pro-px-3 pro-py-3 shimmer pro-rounded-circle"></div>
          </div>
        </div>
      </div>

      <div className={`pro-d-flex pro-gap-5 ${Style.root}`}>
        <div
          className={`pro-px-5 shimmer pro-rounded-circle ${Style.left_img}`}
        ></div>
        <div className="pro-w-100">
          <div className={`${Style.text} pro-py-1 pro-mt-2 shimmer`}></div>
          <div className="pro-gap-2 pro-d-flex">
            <div className="pro-px-3 pro-py-3 shimmer pro-rounded-circle"></div>
            <div className="pro-px-3 pro-py-3 shimmer pro-rounded-circle"></div>
            <div className="pro-px-3 pro-py-3 shimmer pro-rounded-circle"></div>
            <div className="pro-px-3 pro-py-3 shimmer pro-rounded-circle"></div>
            <div className="pro-px-3 pro-py-3 shimmer pro-rounded-circle"></div>
          </div>
        </div>
      </div>

      <div className={`pro-d-flex pro-gap-5 ${Style.root}`}>
        <div
          className={`pro-px-5 shimmer pro-rounded-circle ${Style.left_img}`}
        ></div>
        <div className="pro-w-100">
          <div className={`${Style.text} pro-py-1 pro-mt-2 shimmer`}></div>
          <div className="pro-gap-2 pro-d-flex">
            <div className="pro-px-3 pro-py-3 shimmer pro-rounded-circle"></div>
            <div className="pro-px-3 pro-py-3 shimmer pro-rounded-circle"></div>
            <div className="pro-px-3 pro-py-3 shimmer pro-rounded-circle"></div>
            <div className="pro-px-3 pro-py-3 shimmer pro-rounded-circle"></div>
            <div className="pro-px-3 pro-py-3 shimmer pro-rounded-circle"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivityMonitorShimmer;
