import { useState } from "react";
import Select from "react-select";
import Style from "./statusButton.module.scss";
import { updateConfig } from "../../../../store/slices/payment/transactionsSlice";
import { useDispatch } from "react-redux";

const StatusButton = ({ data, refetch, useDataOptions }) => {
  const dispatch = useDispatch();

  const [status, setStatus] = useState({
    value: "Purchased",
    label: "Purchased",
  });

  const handleChange = (selectedOption) => {
    // setStatus(selectedOption);
    dispatch(
      updateConfig((state) => {
        state.statusModal = true;
        state.IDs = {
          transactionID: data?._id,
          status: selectedOption,
          userId: data?.user_id,
        };
      })
    );
  };

  return (
    <div className="col-12 no-border" style={{ minWidth: "222px" }}>
      <Select
        // options={options?.filter((item) => item.value !== status.value)}
        options={useDataOptions}
        value={status}
        menuPlacement="auto"
        menuPosition="fixed"
        onChange={handleChange}
        className={Style.reactSelectContainer}
        classNamePrefix="pro-input"
      />
    </div>
  );
};
export default StatusButton;
