import { getAxiosInstance } from "../../../api";

export const getExportedData = async (params) => {
  const getParams = (key) => {
    if (params?.[key]) {
      return `${key}=${params[key]}&`;
    } else {
      return "";
    }
  };

  const api = await getAxiosInstance();
  try {
    const response = await api.get(
      `/admin/staffs/transfers/export?${params?.fields
        ?.filter((field) => field !== "action")
        ?.map((field, index) => `fields[${index}]=${field}`)
        .join("&")}&
        ${getParams("start")}${getParams("end")}${getParams(
        "sort_by"
      )}sort_order=${params?.sort_order || "desc"}&${getParams("search")}`,
      {
        responseType: "blob",
      }
    );

    return response;
  } catch (error) {
    return error.response.data;
  }
};
