import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../../api";

const initialState = {
  shortListedStatus: "idle",
  shortListedData: {},
  shortlistedPermissions: [],
  selectedProfiles: [],
  currentPage: 1,
};

export const getShortListedDetails = createAsyncThunk(
  "shortlisted/getShortListedDetails",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `/admin/members/profile/shortlisted?user_id=${params.user_id}&items_per_page=21&page=${params?.page}`
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const shortListedSlice = createSlice({
  name: "shortListed",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: {
    [getShortListedDetails.pending]: (state, action) => {
      state.shortListedStatus = "pending";
      // state.shortListedData[action.meta?.arg?.user_id] = {
      //   ...state.shortListedData[action.meta?.arg?.user_id],
      //   shortListedStatus: "pending",
      // };
    },
    [getShortListedDetails.fulfilled]: (state, action) => {
      state.shortlistedPermissions = action?.payload?.data?.data?.permissions;

      if (
        state.shortListedData?.[action.meta?.arg?.user_id] &&
        !action.meta?.arg?.clearState
      ) {
        if (action.payload?.data?.data?.data?.data) {
          state.shortListedData[action.meta?.arg?.user_id] = {
            ...action.payload?.data?.data?.data,
            shortListedStatus: "fulfilled",
            data: [
              ...state.shortListedData[action.meta?.arg?.user_id].data,
              ...action.payload?.data?.data?.data?.data,
            ],
          };
        } else {
          state.shortListedData[action.meta?.arg?.user_id] = {
            ...action.payload?.data?.data?.data,
            shortListedStatus: "fulfilled",
            data: [...state.shortListedData[action.meta?.arg?.user_id].data],
          };
        }
      } else {
        state.shortListedData[action.meta?.arg?.user_id] = {
          ...action.payload?.data?.data?.data,
          shortListedStatus: "fulfilled",
          page: 1,
        };
      }
      state.shortListedStatus = "fulfilled";
    },
    [getShortListedDetails.rejected]: (state,action) => {
      state.shortListedStatus = "failed";
      // state.shortListedData[action.meta?.arg?.user_id] = {
      //   ...state.shortListedData[action.meta?.arg?.user_id],
      //   shortListedStatus: "failed",
      // };
    },
  },
});

export const { updateConfig } = shortListedSlice.actions;

export default shortListedSlice.reducer;
