import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Select from "react-select";
import useEmployement from "./useEmployement";

const Employement = ({
  optionsData,
  isOptionDataSuccess,
  isEditProfile,
  profileRefetch,
  className,
  isStickyFooter,
}) => {
  const {
    formik,
    isLoading,
    stateRef,
    districtRef,
    occupationRef,
    handleCloseModal,
    getFieldError,
  } = useEmployement({ isOptionDataSuccess, isEditProfile, profileRefetch });

  return (
    <div className={className}>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Employement Sector
        </label>

        <Select
          id="employment_sector"
          isClearable={true}
          placeholder={"Select"}
          className={`pro-input lg  ${
            getFieldError("employment_sector") && " error"
          }`}
          classNamePrefix="pro-input"
          options={optionsData?.employment_sector}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.name}
          value={optionsData?.employment_sector?.filter(
            (m) => formik.values.employment_sector?.id === m?.id
          )}
          onChange={(value) =>
            formik.setFieldValue("employment_sector", value || null)
          }
        ></Select>
        {getFieldError("employment_sector") && (
          <span className="error-text">
            {getFieldError("employment_sector")}
          </span>
        )}
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Occupation Category
        </label>

        <Select
          id="occupation_category"
          isClearable={true}
          placeholder={"Select"}
          className={`pro-input lg  ${
            getFieldError("occupation_category") && " error"
          }`}
          classNamePrefix="pro-input"
          options={optionsData?.occupation_category}
          getOptionValue={(option) => option}
          getOptionLabel={(option) => option?.name}
          value={optionsData?.occupation_category?.filter(
            (m) => formik.values.occupation_category?._id === m?._id
          )}
          onBlur={formik.handleBlur("occupation_category")}
          onChange={(value) => {
            formik.setFieldValue("occupation_category", value || null);
            occupationRef.current.setValue([], "clear");
          }}
        ></Select>
        {getFieldError("occupation_category") && (
          <span className="error-text">
            {getFieldError("occupation_category")}
          </span>
        )}
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Occupation
        </label>

        <Select
          ref={occupationRef}
          id="occupation"
          isDisabled={formik.values?.occupation_category?._id ? false : true}
          isClearable={true}
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("occupation") && " error"}`}
          classNamePrefix="pro-input"
          options={optionsData?.occupation?.filter(
            (p) =>
              p?.occupation_category_id ===
              formik.values.occupation_category?.category_id
          )}
          getOptionValue={(option) => option?._id}
          getOptionLabel={(option) => option?.name}
          value={formik.values.occupation}
          onBlur={formik.handleBlur("occupation")}
          onChange={(value) => {
            formik.setFieldValue("occupation", value);
          }}
        ></Select>
        {getFieldError("occupation") && (
          <span className="error-text">{getFieldError("occupation")}</span>
        )}
      </div>

      <Input
        label={"Occupation Details"}
        id="occupation_detail"
        type="text"
        name="occupation_detail"
        className={`pro-input lg  ${
          getFieldError("occupation_detail") && " error"
        }`}
        {...formik.getFieldProps("occupation_detail")}
        error={getFieldError("occupation_detail")}
        errorMessage={getFieldError("occupation_detail")}
      />

      <Input
        label={"Firm name"}
        id="organization"
        type="text"
        name="organization"
        className={`pro-input lg ${getFieldError("organization") && " error"}`}
        {...formik.getFieldProps("organization")}
        error={getFieldError("organization")}
        errorMessage={getFieldError("organization")}
      />

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Annual Income
        </label>

        <Select
          id="annual_income"
          isClearable={true}
          placeholder={"Select"}
          className={`pro-input lg  ${
            getFieldError("annual_income") && " error"
          }`}
          classNamePrefix="pro-input"
          options={optionsData?.annual_income}
          getOptionValue={(option) => option?._id}
          getOptionLabel={(option) => option?.value}
          value={optionsData?.annual_income?.filter(
            (m) => formik.values.annual_income?._id === m?._id
          )}
          onBlur={formik.handleBlur("annual_income")}
          onChange={(value) =>
            formik.setFieldValue("annual_income", value || null)
          }
        ></Select>
        {getFieldError("annual_income") && (
          <span className="error-text">{getFieldError("annual_income")}</span>
        )}
      </div>

      <div className={"input-wrap pro-mb-5"}>
        <label
          htmlFor="current_location"
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          Work Country
        </label>
        <Select
          id="work_country"
          isClearable={true}
          placeholder={"Select"}
          className={"pro-input lg"}
          classNamePrefix="pro-input"
          options={optionsData?.country}
          getOptionValue={(option) => option?._id}
          getOptionLabel={(option) => option?.name}
          value={optionsData?.country?.filter(
            (m) => formik.values.work_country?._id === m?._id
          )}
          onChange={(value) => {
            formik.setFieldValue("work_country", value || null);
            stateRef.current.setValue([], "clear");
            districtRef.current.setValue([], "clear");
          }}
        />
      </div>

      <div className={"input-wrap pro-mb-5"}>
        <label
          htmlFor="current_location"
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          Work State
        </label>
        <Select
          id="work_state"
          isClearable={true}
          ref={stateRef}
          isDisabled={formik.values?.work_country?._id ? false : true}
          placeholder={"Select"}
          className={"pro-input lg"}
          classNamePrefix="pro-input"
          options={optionsData?.state?.filter(
            (p) =>
              p?.country_id === formik.values.work_country?.unique_country_id
          )}
          getOptionValue={(option) => option?._id}
          getOptionLabel={(option) => option?.name}
          value={optionsData?.state?.filter(
            (m) => formik.values.work_state?._id === m?._id
          )}
          onChange={(value) => {
            formik.setFieldValue("work_state", value || null);
            districtRef.current.setValue([], "clear");
          }}
        />
      </div>

      <div className={"input-wrap pro-mb-5"}>
        <label
          htmlFor="current_location"
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          Work District
        </label>
        <Select
          id="work_district"
          ref={districtRef}
          isDisabled={formik.values?.work_state?._id ? false : true}
          isClearable={true}
          placeholder={"Select"}
          className={"pro-input lg"}
          classNamePrefix="pro-input"
          menuPlacement="auto"
          options={optionsData?.district?.filter(
            (p) => p?.state_id === formik.values.work_state?.unique_state_id
          )}
          getOptionValue={(option) => option?._id}
          getOptionLabel={(option) => option?.name}
          value={optionsData?.district?.filter(
            (m) => formik.values.work_district?._id === m?._id
          )}
          onChange={(value) =>
            formik.setFieldValue("work_district", value || null)
          }
        />
      </div>

      <div
        className={`col-12 pro-d-flex pro-justify-end ${
          isStickyFooter && "offcanvas-footer-sticky-btns"
        }`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => handleCloseModal()}
        >
          Cancel
        </Button>

        <Button
          className={`pro-btn-primary lg pro-ms-3 ${isLoading ? "loading" : ""}`}
          type="submit"
          disabled={isLoading}
          onClick={formik.handleSubmit}
        >
          {isEditProfile ? "Update" : "Next"}
        </Button>
      </div>
    </div>
  );
};

export default Employement;
