import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  getFollowUpCommentData,
  getFollowUpOverviewData,
  updateConfig,
} from "../../../store/slices/Profile/ProfileFollowup";
import { useDispatch, useSelector } from "react-redux";
import useObserver from "../../../utils/hooks/useObserver";

const useProfileFollowUp = () => {
  const [activeFilter, setActiveFilter] = useState(2);
  const [params] = useSearchParams();
  const [page, setPage] = useState(1);
  const [pageComments, setPageComments] = useState(1);
  const [showScroll, setShowScroll] = useState(false);
  const dispatch = useDispatch();

  const activeProfile =
    params.get("active") ?? sessionStorage.getItem("active");
  const state = useSelector((state) => state.profileFollowup);
  const profileFollowupCommentState = state?.commentData?.[activeProfile];
  const profileFollowupOverviewState = state?.overviewData?.[activeProfile];

  const filters = [
    {
      type: "buttonGroup",
      buttons: [
        { name: "Overview", value: 2 },
        { name: "Comments", value: 1 },
      ],
    },
  ];

  useEffect(() => {
    if (
      Object.keys(state?.commentData[activeProfile] ?? {})?.length === 0 &&
      activeFilter === 1
    ) {
      dispatch(
        getFollowUpCommentData({
          user_id: activeProfile,
          // page: pageComments,
          page: state?.currentPage,
          page_size: state?.currentPageSize,
        })
      );
    }
    if (
      Object.keys(state?.overviewData[activeProfile] ?? {})?.length === 0 &&
      activeFilter === 2
    ) {
      dispatch(
        getFollowUpOverviewData({
          user_id: activeProfile,
          // page: page,
          page: state?.currentPage,
          page_size: state?.currentPageSize,
        })
      );
    }

    //eslint-disable-next-line
  }, [activeProfile, activeFilter]);

  useEffect(() => {
    function handleWindowResize() {
      if (window.scrollY > 150) {
        setShowScroll(true);
      } else if (window.scrollY === 0) {
        setShowScroll(false);
      }
    }
    window.addEventListener("scroll", handleWindowResize);

    return () => {
      window.removeEventListener("scroll", handleWindowResize);
    };
  }, []);

  const hasOverviewPermission = useMemo(() => {
    let permission = state?.followUpPermissions?.filter((p) =>
      Object.keys(p).includes("can_overview")
    );

    return permission?.[0]?.can_overview ?? 0;
  }, [state]);
  const hasCommentsPermission = useMemo(() => {
    let permission = state?.followUpPermissions?.filter((p) =>
      Object.keys(p).includes("can_comments")
    );

    return permission?.[0]?.can_comments ?? 0;
  }, [state]);

  if (hasOverviewPermission !== 1) {
    filters[0].buttons = filters[0].buttons.filter(
      (button) => button.value !== 2
    );
  }
  if (hasCommentsPermission !== 1) {
    filters[0].buttons = filters[0].buttons.filter(
      (button) => button.value !== 1
    );
  }

  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  };
 
  const handleLoadMore = () => {
   
    if (activeFilter === 1) {
 
      setPageComments((prev) => prev + 1);
      dispatch(
        getFollowUpCommentData({
          user_id: activeProfile,
          page: state.commentData[activeProfile]?.current_page + 1,
          page_size: state?.currentPageSize,
        })
      );
    } else {
    
      setPage((prev) => prev + 1);
      dispatch(
        getFollowUpOverviewData({
          user_id: activeProfile,
          page: state.overviewData[activeProfile]?.current_page + 1,
          page_size: state?.currentPageSize,
          // page: page + 1,
        })
      );
    }
  };

  const { lastElement } = useObserver({
    loading:
      activeFilter === 1
        ? profileFollowupCommentState?.commentStatus !== "fulfilled"
        : profileFollowupOverviewState?.overviewStatus !== "fulfilled",
    hasMore:
      activeFilter === 1
        ? state?.currentPageSize * profileFollowupCommentState?.current_page <
          profileFollowupCommentState?.total
        : state?.currentPageSize * profileFollowupOverviewState?.current_page <
          profileFollowupOverviewState?.total,
    callback: handleLoadMore,
  });

  const handleTabChange = (item) => {
    if (item.value !== activeFilter) {
      // if (item.value === 1) {
      //   setPage(1);

      // } else {
      //   setPageComments(1);

      // }
      setActiveFilter(item.value);
    }
  };

  return {
    filters,
    state,
    activeFilter,
    showScroll,
    profileFollowupCommentState,
    profileFollowupOverviewState,
    lastElement,
    handleTabChange,
    handleScrollToTop,
  };
};

export default useProfileFollowUp;
