import { useFormik } from "formik";
import * as Yup from "yup";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { updateConfig } from "../../../../store/slices/Settings/faqsSlice";

import {
  updateStaffDetails,
  passwordReset,
  viewStaffProfile,
} from "../../../../store/slices/Settings/staffSlice";
import { emailRegExp, phoneRegExp } from "../../../../utils/functions/table";

const useProfileEdit = ({ setProfileViewVisible, userData }) => {
  const [loading, setLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState("");
  const profilefileInputRef = useRef(null);
  // const [userData, setUserData] = useState(null);
  const [message, setMessage] = useState("");
  const [options, setOptions] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(true);

  const [imagePreview, setImagePreview] = useState(
    userData !== "" ? userData?.image_url : ""
  );

  const [showExistingPassword, setShowExistingPassword] = useState(true);

  const imageFileInputRef = useRef(null);

  const dispatch = useDispatch();

  var validationSchemaProfileEdit = Yup.object({
    email: Yup.string()
      .matches(emailRegExp, "Please enter a valid email")
      .required("*Required"),
    phoneNumber: Yup.string()
      .matches(phoneRegExp, "Please enter a valid phone number")
      .min(10, "Phone number must be 10 digits")
      .max(10, "Phone number must be 10 digits")
      .required("*Required"),
  });

  var validationSchemaPasswordChange = Yup.object({
    existingPassword: Yup.string().required("Existing password is required"),
    newPassword: Yup.string().required("New password is required"),
  });

  const [selectedOption, setSelectedOption] = useState(null);

  const formikEditUserData = useFormik({
    initialValues: {
      email: userData?.email,
      phoneNumber: userData?.phone_number,
    },

    validationSchema: validationSchemaProfileEdit,
    enableReinitialize: true,
    onSubmit: (values, { resetForm, setFieldError }) => {
      handleFormSubmit(values, resetForm, setFieldError);
    },
  });

  const formikEditPassword = useFormik({
    initialValues: {
      email: userData?.email,
      phoneNumber: userData?.phone_number,
      existingPassword: "",
      newPassword: "",
    },

    validationSchema: validationSchemaPasswordChange,
    enableReinitialize: true,
    onSubmit: (values, { resetForm, setFieldError }) => {
      handleSubmitPasswordChange(values, resetForm, setFieldError);
    },
  });

  // useEffect(() => {
  //   if (localStorage.getItem("USER_DETAILS") !== undefined)
  //     setIsDataLoading("loading");
  //   dispatch(
  //     editItemFromTableByID({ user_id: localStorage.getItem("USER_DETAILS") })
  //   )
  //     .unwrap()
  //     .then((response) => {
  //       setIsDataLoading("success");
  //       setUserData(response);
  //     });
  //   // eslint-disable-next-line
  // }, []);

  const handleFormSubmit = async (values, resetForm, setFieldError) => {
    try {
      let obj = {
        first_name: userData?.first_name,
        last_name: userData?.last_name,
        phone_number: values.phoneNumber,
        email: values.email,
        gender: userData?.gender,
        role_id: userData?.role_id,
        user_id: userData?._id,
        country_code: userData?.country_code,
      };

      if (values.profile?.name) {
        obj.photo = values.profile;
      }

      let formData = new FormData();

      Object.keys(obj).forEach((key) => {
        return formData.append(key, obj[key]);
      });

      formData.append("_method", "PUT");

      dispatch(updateStaffDetails(formData))
        .unwrap()
        .then((data) => {
          setProfileViewVisible(false);

          dispatch(viewStaffProfile({ user_id: userData?._id }));
          resetForm();
          toast.success("Your details has been updated successfully.");
        })
        .catch((err) => {
          if (err?.message?.groom_name?.[0] !== "") {
            setFieldError("groomName", err?.message?.groom_name?.[0]);
          }
        });
    } catch (error) {
      
    }
  };

  const handleSubmitPasswordChange = async (
    values,
    resetForm,
    setFieldError
  ) => {
    try {
      try {
        let obj = {
          password: values.existingPassword,
          confirm_password: values.newPassword,
        };

        let formData = new FormData();

        Object.keys(obj).forEach((key) => {
          return formData.append(key, obj[key]);
        });

        dispatch(passwordReset(formData))
          .unwrap()
          .then((data) => {
            if (data?.data?.success) {
              setProfileViewVisible(false);
              resetForm();
              toast.success("Your password has been updated successfully.");
            }
          })
          .catch((err) => {
            if (err?.message?.password?.[0] !== "") {
              setFieldError("existingPassword", err?.message?.password?.[0]);
            }
          });
      } catch (error) {}
    } catch (error) {
      toast.success("Something went wrong!");
    }
  };

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
  };

  const handleShowExistingPassword = (e) => {
    e.preventDefault();
    setShowExistingPassword(!showExistingPassword);
    var x = document.getElementById("existingPassword");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };
  const handleShowNewPassword = (e) => {
    e.preventDefault();
    setShowNewPassword(!showNewPassword);
    var x = document.getElementById("newPassword");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };

  const handleProfileImage = (e) => {
    const files = e.target.files;

    if (files?.[0]?.size / (1024 * 1024) <= 5) {
      formikEditUserData.setFieldValue("profile", e?.target?.files?.[0]);

      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        setImagePreview(reader.result);
      };

      if (file) {
        reader.readAsDataURL(file);
        formikEditUserData.handleSubmit()
      }
    } else if (files?.[0]?.size / (1024 * 1024) > 5) {
      toast.error("The image must be less than 5MB in size.");
    }
  };

  return {
    message,
    setLoading,
    setMessage,
    setOptions,
    imagePreview,
    profilefileInputRef,
    formikEditUserData,
    formikEditPassword,
    loading,
    handleCloseModal,
    showExistingPassword,
    showNewPassword,
    handleProfileImage,
    handleShowExistingPassword,
    handleShowNewPassword,
    imageFileInputRef,
    selectedOption,
    setSelectedOption,
    options,
    isDataLoading,
    setIsDataLoading,
    userData,
  };
};

export default useProfileEdit;
