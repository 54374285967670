import { useDispatch, useSelector } from "react-redux";
import { updateFormData } from "../../../../store/slices/Users/createMemberSlice";

import { useGetMemberCreationFormDataQuery } from "../../../../store/queries/members";

const useMemberCreationForm = () => {
  const dispatch = useDispatch();
  const { activeTab, CompletedPercentage, isEditProfile, completedTabs,isUpdateProfile,Updatedata} =
    useSelector((state) => state.createMembers);

  const { data: optionsData = {}, isSuccess: isOptionDataSuccess } =
    useGetMemberCreationFormDataQuery({});
  
  let tabs = [
    { label: "Basic", completed: CompletedPercentage.Basic },
    { label: "Family", completed: CompletedPercentage.Family },
    { label: "Edu", completed: CompletedPercentage.Edu },
    { label: "Job", completed: CompletedPercentage.Job },
    { label: "Contact", completed: CompletedPercentage.Contact },
  ];

  const handleTabClick = (tab) => {
    if (completedTabs[tab.label] === true) {
      dispatch(
        updateFormData((state) => {
          state.activeTab = tab.label;
        })
      );
    } else if (isEditProfile) {
      dispatch(
        updateFormData((state) => {
          state.activeTab = tab.label;
        })
      );
    }
  };
  return {
    tabs,
    activeTab,
    isOptionDataSuccess,
    optionsData: optionsData?.data,
    isEditProfile,
    handleTabClick,
    isUpdateProfile,
    Updatedata
  };
};

export default useMemberCreationForm;
