import {
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
  OffCanvasLayout,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../Global/OrderColumn";
import AddFormContainer from "./AddForm";
import { useSelector } from "react-redux";
import useFollowUp from "./useFollowUp";
import EmptyData from "../../Global/EmptyData";
import ProfilePreview from "./ProfilePreview";
import Style from "./followUp.module.scss";
import DatePickerRsuite from "../../Global/CustomDateRangePicker";

const FollowUp = ({ dashboard = false }) => {
  const {
    FollowUpList,
    followup,
    currentPage,
    paginationOptions,
    isFetching,
    isLoading,
    activeFilter,
    actionOptions,
    profileData,
    filters,
    date,
    viewProfilePreview,
    hasExportPermission,
    hasUpdatePermission,
    handleDateRangeChange,
    handleFilter,
    handleDashboardRedirect,
    closePreviewClick,
    handleActionChange,
    refetch,
    handlePagination,
    handlePageSize,
    handleDateChange,
    handleSort,
    handleSearch,
    getRow,
    handleEditClick,
    closeEditModal,
    showEditModal,
    handleDelete,
    showform,
    setShowform,
    showDeleteConfirm,
    setShowDeleteConfirm,
    handleDeleteAction,
    handleEditAction,
    updateFollowupsFields,
    handleClearClick,
  } = useFollowUp(dashboard);

  const { editDiocese } = useSelector((state) => state.dioceseListingPage);

  return (
    <>
      {!dashboard ? (
        <HeadingGroup
          title={"Follow up"}
          className={`pro-mb-4`}
          buttonTitle={""}
          handleClick={() => {
            setShowform(true);
          }}
        />
      ) : (
        <></>
      )}
      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        {!dashboard ? (
          <SearchFilters
            data={filters}
            activeFilter={activeFilter}
            //showDateRange
            dropDownFilter={
              <div className="col-auto">
                <DatePickerRsuite
                  defaultDateRange={date}
                  handleDateRangeChange={handleDateRangeChange}
                />
              </div>
            }
            onDateChange={handleDateChange}
            handleButtonGroupChange={handleFilter}
            onSearchInput={handleSearch}
            showActions={false}
            handleActionClick={handleEditClick}
            loading={isLoading}
            initialDateRange={date}
            SearchIcon={
              <span className="material-symbols-outlined"> search </span>
            }
            //showClearFilters
            handleClear={handleClearClick}
            searchInputProps={{ value: followup?.search }}
            actionOptions={actionOptions?.filter((item) =>
              hasExportPermission !== 1 ? item.value === 0 : true
            )}
            onActionOptionChange={handleActionChange}
          />
        ) : (
          <></>
        )}
        {FollowUpList?.data?.data?.data?.length === 0 ? (
          <EmptyData />
        ) : (
          <div
            onClick={dashboard ? () => handleDashboardRedirect() : () => {}}
            className={!dashboard ? "pro-pt-3" : Style.dashboardTable}
          >
            <Table
              multiSelect={false}
              data={FollowUpList?.data?.data?.data || []}
              uniqueID={"_id"}
              fields={FollowUpList?.data?.fields}
              SortIcon={<FaSort />}
              editIcon={<span className="material-symbols-outlined">edit</span>}
              deleteIcon={
                <span className="material-symbols-outlined">delete</span>
              }
              handleSort={handleSort}
              getRow={getRow}
              loading={isFetching}
              perpage={followup?.currentPageSize}
              assignable={false}
              handleEdit={handleEditAction}
              handleDelete={handleDelete}
              deletable={false}
              showCheckBox={false}
              extraClasssName={"dashboard_no_padding"}
            />
          </div>
        )}

        {FollowUpList?.data?.data?.data?.length > 0 && !dashboard && (
          <Pagination
            currentPage={currentPage}
            totalPageCount={FollowUpList?.data?.data?.last_page}
            defaultValue={paginationOptions?.filter(
              (item) => item.value === followup?.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}

        {!dashboard ? (
          <>
            <ModalLayout show={showEditModal} handleClose={closeEditModal}>
              <div className="pro-m-5">
                <OrderColumn
                  title={"Choose which columns you see"}
                  refetchData={refetch}
                  tableFields={FollowUpList?.data?.fields}
                  moduleId={FollowUpList?.data?.module_id}
                  updateData={updateFollowupsFields}
                />
              </div>
            </ModalLayout>

            <ModalLayout
              centered={false}
              show={showDeleteConfirm}
              handleClose={setShowDeleteConfirm}
            >
              <div className="pro-m-5">
                <ConfirmationBox
                  title={`Are you sure you want to delete this item?`}
                  isRight={true}
                  cancelText={`No`}
                  submitText={`Yes`}
                  cancelAction={setShowDeleteConfirm}
                  submitAction={handleDeleteAction}
                >
                  ConfirmationBox
                </ConfirmationBox>

                {true && <span className="error-message">{true}</span>}
              </div>
            </ModalLayout>

            <OffCanvasLayout
              centered={false}
              show={showform}
              handleClose={() => {
                setShowform(false);
              }}
              title={editDiocese === true ? "Edit Follow up" : "Add Follow up"}
              closeIcon={
                <span className="material-symbols-outlined">close</span>
              }
            >
              <AddFormContainer
                isStickyFooter
                setShowform={setShowform}
                refetch={refetch}
                hasUpdatePermission={hasUpdatePermission}
              />
            </OffCanvasLayout>

            <OffCanvasLayout
              centered={true}
              show={viewProfilePreview}
              handleClose={closePreviewClick}
              title={"Profile Preview"}
              closeIcon={
                <span className="material-symbols-outlined">close</span>
              }
            >
              <ProfilePreview
                profileData={profileData}
                status={profileData?.data?.data?.status || 1}
              />
            </OffCanvasLayout>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default FollowUp;
