import React from "react";
import "react-month-picker/scss/month-picker.scss";
import usePartnerDashboardChart from "./usePartnerDashboardChart";
import { DataContainer } from "@wac-ui-dashboard/wac_component_library";
import Style from "./partnerDashboardChart.module.scss";

const PartnerDashboardChart = ({ title, datasets,labels, chartType,type, state }) => {
  const { chartRef } = usePartnerDashboardChart({
    datasets,
    labels,
    chartType,
    type,
    state,
  });

  return (
    <>
      <DataContainer>
        <div className={`pro-d-flex pro-justify-between ${Style.root}`}>
          <h6 className="pro-ttl ">{title}</h6>
        </div>
        <canvas
          ref={chartRef}
          id="partnerDashboardChart"
          style={{ width: "100%", maxWidth: "100%", maxHeight: "50vh" }}
        />
      </DataContainer>
    </>
  );
};

export default PartnerDashboardChart;
