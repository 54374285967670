import { toast } from "react-toastify";
import { UpdateData } from "./api";
import { useFormik } from "formik";
import * as Yup from "yup";


const useDataUpdationModal = ({ selectedId, handleClose, refetch }) => {
  let dataUpdationValidationSchema = Yup.object({
    reason: Yup.string().required("*Required"),
  });

  const formik = useFormik({
    initialValues: {
      reason: "",
    },

    validationSchema: dataUpdationValidationSchema,

    onSubmit: (values, { resetForm }) => {
      try {
        let formData = new FormData();
        formData.append("_method", "PUT");
        formData.append("request_id", selectedId);
        formData.append("reason", values.reason);
        formData.append("status", 1);
        UpdateData(formData).then((response) => {
          if (response?.data?.status_code === 200) {
            refetch?.();
            resetForm();
            handleClose?.();
            toast.success(response?.data?.message);
          } else if (response?.status_code === 400) {
            formik.setErrors(response?.message);
            toast.error("Failed to Update");
          } else {
            toast.error("Failed to Update");
          }
        });
      } catch (error) {
        toast.error("Something went wrong");
      }
    },
  });

  return { formik };
};

export default useDataUpdationModal;
