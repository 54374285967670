import Style from "./addDistrictForm.module.scss";


const AddDistrictForm = ({ formik, optionsWithIcons, stateList }) => {
  const capitalizeName = (event, setFieldValue) => {
    const inputName = event.target.name;
    const inputValue = event.target.value;

    const nameParts = inputValue.split(" ");
    const capitalizedParts = nameParts.map((part) => {
      return part.charAt(0).toUpperCase() + part.slice(1);
    });

    const capitalizedFullName = capitalizedParts.join(" ");

    setFieldValue(inputName, capitalizedFullName);
  };
  return (
    <div className={Style.root}>
      <div className="col-12 pro-mb-4">
        <div className="row">
          <div className="col-12 pro-mb-4">
            <div className="input-wrap">
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                Name*
              </label>
              <input
                type="text"
                className={`pro-input lg ${
                  formik.errors.districtName &&
                  formik.touched.districtName &&
                  " error"
                }`}
                id="districtName"
                name="districtName"
                {...formik.getFieldProps("districtName")}
                onChange={(event) => capitalizeName(event, formik.setFieldValue)}
    
              />
              {formik.touched.districtName && formik.errors.districtName && (
                <span className="error-text">{formik.errors.districtName}</span>
              )}
            </div>
          </div>


          {/* <div className="col-12 pro-mb-4">
            <div className="input-wrap">
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                State*
              </label>
              <Select
                id="state"
                name="state"
                options={stateList?.data?.state}
                getOptionLabel={(option) => `${option.name}`}
                getOptionValue={(option) => `${option._id}`}
                className={` pro-input lg ${
                  formik.touched.state &&
                  formik.errors.state &&
                  " error"
                }`}
                value={stateList?.data?.state?.filter(
                  (p) =>
                    p?.unique_state_id?.toString() ===
                    formik.values.state?.unique_state_id?.toString()
                )}
                classNamePrefix="pro-input"
                onBlur={() => formik.handleBlur("state")}
                onChange={(value) =>
                  formik.setFieldValue("state", value)
                }
              />
              {formik.touched.state &&
                formik.errors.state && (
                  <span className="error-text">
                    {formik.errors.state}
                  </span>
                )}
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default AddDistrictForm;
