import { Button } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useAddBranchForm from "./useAddForm";
import AddFormFields from "../AddFormFields";

const AddFormFieldsContainer = ({ setShowform, refetch }) => {
  const {
    formik,
    errors,
    loading,
    message,
    optionsWithIcons,
    staffList,
    selectedOption,
    setSelectedOption,
    options,
    handleFileChange,
  } = useAddBranchForm({ setShowform, refetch });

  return (
    <div className={`pro-p-4`}>
      <form action="" onSubmit={formik.handleSubmit}>
        <AddFormFields
          optionsWithIcons={optionsWithIcons}
          staffList={staffList}
          formik={formik}
          setShowform={setShowform}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          options={options}
          handleFileChange={handleFileChange}
        />
        <div className="col-12 pro-mt-4 pro-d-flex pro-justify-end">
          <Button
            className={"pro-btn-link lg pro-px-5"}
            onClick={() => setShowform(false)}
          >
            Cancel
          </Button>

          <Button className={"pro-btn-primary lg pro-mx-3"} type="submit">
            {loading ? (
              <i
                className="spinner-border spinner-border-sm me-3"
                role="status"
                aria-hidden="true"
              ></i>
            ) : (
              ""
            )}
            Save
          </Button>
        </div>

        {errors && <span className="error-text">{errors}</span>}

        {Object.keys(message)?.map((key, item) => {
          return (
            <div className="error-text">{key + " : " + message[key]} </div>
          );
        })}
      </form>
    </div>
  );
};

export default AddFormFieldsContainer;
