import Style from './buttonGroupShimmer.module.scss';

const ButtonGroupShimmer = () => {
  return (
    
        <div className={`pro-d-flex pro-mb-4`}>
            <span className={`${Style.item} ${Style.thumb} pro-h-100 pro-px-3 pro-d-flex`}>
              <span className="shine  pro-w-100"></span>
            </span>
            <span className={`${Style.item} ${Style.thumb} pro-h-100 pro-px-3 pro-d-flex`}>
              <span className="shine pro-w-100 "></span>
            </span>
          </div>
    
  )
}

export default ButtonGroupShimmer