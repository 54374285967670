import Style from './reactSliderShimmer.module.scss';

const ReactSliderShimmer = () => {
  return (
    <div className={`${Style.root}`}>
        <div className="shimmer pro-px-6 pro-py-1 pro-mt-3 pro-mb-5"></div>
        <div className={`${Style.range_wrap} pro-mt-2`}>
            <span className={`${Style.range_one} ${Style.range} blink`}></span>
            <span className={`${Style.range_two} ${Style.range} blink`}></span>
        </div>
    </div>
  )
}

export default ReactSliderShimmer