import { getAxiosInstance } from "../../api";

export const login = async (params) => {

  const api = await getAxiosInstance();
  try {
    const response = await api.post("admin/login", params);

    return response;
  } catch (error) {

    return error.response.data
  }
};

export const forgotPassword = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post("/user/forgot-password", params);

    return response;
  } catch (error) {
    return error.response.data
  }
};

export const resetPassword = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post("/admin/password/reset", params);

    return response;
  } catch (error) {
    return error.response.data
  }
};

export const validateOTP = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post("/user/otp/validate", params);

    return response;
  } catch (error) {
    return error.response.data
  }
};
