import Select from "react-select";
// import { changeStatus } from "./api";
// import { toast } from "react-toastify";

const FilterDays = ({ status, handleChange }) => {
  const options = [
    { value: 10, label: "Last 10 days" },
    { value: 30, label: "Last 30 days" },
  ];

  return (
    <div className="col-2">
      <Select
        options={options}
        value={status}
        onChange={handleChange}
        className={`pro-input lg `}
        classNamePrefix="pro-input"
      />
    </div>
  );
};
export default FilterDays;
