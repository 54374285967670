import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };

    try {
      const response = await api[method](
        `${endpoint}?${getParams("start")}${getParams("end")}${getParams(
          "filter"
        )}${getParams("sort_by")}sort_order=${
          params?.sort_order || "desc"
        }&page_size=${params?.page_size || "10"}&${getParams("search")}page=${
          params?.page || 1
        }&${getParams("faq_id")}`,
        body
      );

      return { data: response.data };
    } catch (axiosError) {
      let err = axiosError;

      return {
        data: err?.response?.data || err.message,
      };
    }
  };

export const usersRtk = createApi({
  reducerPath: "usersApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["deleted-accounts", "messages", "requests", "job-assign"],
  endpoints: (builder) => ({
    // Deleted Accounts

    getDeletedAccountsListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/members/delete/list`,
      }),
      providesTags: ["deleted-accounts"],
    }),

    updateDeletedAccountsListFieldsData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/user/fields/update`,
      }),

      invalidatesTags: ["deleted-accounts"],
    }),

    getMessagesListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/message/list`,
      }),
      providesTags: ["messages"],
    }),

    updateMessagesListFieldsData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/user/fields/update`,
      }),

      invalidatesTags: ["messages"],
    }),

    getRequestsListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/message/list`,
      }),
      providesTags: ["requests"],
    }),

    updateRequestsListFieldsData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/user/fields/update`,
      }),

      invalidatesTags: ["requests"],
    }),

    deleteUser: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/members/profile/settings/delete/approve`,
      }),

      invalidatesTags: ["deleted-accounts"],
    }),
  }),
});

export const {
  useGetDeletedAccountsListDataQuery,
  useUpdateDeletedAccountsListFieldsDataMutation,
  useGetMessagesListDataQuery,
  useUpdateMessagesListFieldsDataMutation,
  useGetRequestsListDataQuery,
  useUpdateRequestsListFieldsDataMutation,
  useDeleteUserMutation
} = usersRtk;
