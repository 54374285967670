import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";
import { subDays } from "date-fns";


const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  currentFilter: null,
  startDate: subDays(new Date(), 30),
  endDate: new Date(),
  sortBy: "",
  sortOrder: "desc",
  search: "",
  showCreateModal: false,
  selectedItemsDetails: "",
  profileDetails: "",
  clearSelection: false,
};

/**
 * @example {
 *
 *  "sort_by":"name"
 *  "sort_order":"asc"
 *  "page_size":"10"
 *  "page":"1"
 *  }
 */

export const editItemFromTableByID = createAsyncThunk(
  "staff/editItemFromTableByID",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `/admin/staffs/view?${new URLSearchParams(params).toString()}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const viewStaffProfile = createAsyncThunk(
  "staff/viewStaffProfile",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `/admin/staffs/profile?${new URLSearchParams(params).toString()}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateStaffDetails = createAsyncThunk(
  "staff/updateStaffDetails",
  async (formdata, { rejectWithValue }) => {
  
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/admin/staffs/update`, formdata);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const passwordReset = createAsyncThunk(
  "staff/passwordReset",
  async (formdata, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/admin/password/reset`, formdata);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const staffSlice = createSlice({
  name: "staff",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
    clearData: (state, action) => {
      state.selectedItemsDetails = "";
    },
  },
  extraReducers: {
    [editItemFromTableByID.pending]: (state, action) => {},
    [editItemFromTableByID.fulfilled]: (state, action) => {
      state.selectedItemsDetails = action.payload.data.data;
    },
    [editItemFromTableByID.rejected]: (state, action) => {},

    [viewStaffProfile.pending]: (state, action) => {},
    [viewStaffProfile.fulfilled]: (state, action) => {
      state.profileDetails = action.payload.data.data;
    },
    [viewStaffProfile.rejected]: (state, action) => {},
  },
});

export const { updateConfig, clearData } = staffSlice.actions;

export default staffSlice.reducer;
