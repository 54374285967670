import { useDispatch, useSelector } from "react-redux";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { updateConfig } from "../../../store/slices/Notifications/dataUpdateSlice";
import { getFormatedDate } from "../../../utils/functions/table";
import {
  useGetDataUpdationListDataQuery,
  useUpdateDataUpdationListFieldsDataMutation,
} from "../../../store/queries/notifications";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import Select from "react-select";
import { subDays } from "date-fns";
import Style from "./selectStatusButton.module.scss";
import { ProfileNavigation } from "../../Global/ProfileNavigation";

const useDataUpdation = (dashboard) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dataUpdation = useSelector((state) => state.dataUpdation);
  const { showEditModal } = useSelector((state) => state.global);
  const [showDataUpdationModal, setshowDataUpdationModal] = useState(false);

  const [params] = useSearchParams();

  const activeProfile =
    params.get("active") ?? sessionStorage.getItem("active");

  const [selectedId, setSelectedId] = useState("");

  const [date, setDate] = useState({
    startDate: dataUpdation.startDate,
    endDate: dataUpdation.endDate,
    key: "selection",
  });

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const [updateDataUpdationFields] =
    useUpdateDataUpdationListFieldsDataMutation();

  const {
    data: DataUpdationList = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetDataUpdationListDataQuery({
    page_size: dataUpdation.currentPageSize,
    page: dataUpdation.currentPage,
    sort_by: dataUpdation.sortBy,
    sort_order: dataUpdation.sortOrder,
    start: dashboard ? "" :getFormatedDate(dataUpdation.startDate),
    end: dashboard ? "" :getFormatedDate(dataUpdation.endDate),
    search: dataUpdation.search,
  });

  useEffect(() => {
    if (!dashboard) {
      setDate({
        startDate: dataUpdation.startDate,
        endDate: dataUpdation.endDate,
        key: "selection",
      });
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.currentPageSize = 10;
          state.startDate = subDays(new Date(), 30)
          state.endDate = new Date()
          state.sortBy = "";
          state.sortOrder = "";
          state.data = [];
          state.showCreateModal = false;
          state.showEditModal = false;
          state.selectedItemsDetails = "";
          state.search = "";
        })
      );
    }
    // eslint-disable-next-line
  }, [dashboard]);

  const hasUpdatePermission = useMemo(() => {
    let permission = DataUpdationList?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_update")
    );

    return permission?.[0]?.can_update ?? 0;
  }, [DataUpdationList]);

  const hasExportPermission = useMemo(() => {
    let permission = DataUpdationList?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_export")
    );

    return permission?.[0]?.can_export ?? 0;
  }, [DataUpdationList]);

  const hasViewPermission = useMemo(() => {
    let permission = DataUpdationList?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_view")
    );

    return permission?.[0]?.can_view ?? 0;
  }, [DataUpdationList]);


  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleDateChange = (range) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = range.startDate;
        state.endDate = range.endDate
      })
    );
  };

  const handleSort = (label) => {
    if (dataUpdation.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = dataUpdation.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };
  const handleEditClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
  };
  const options = [{ value: 1, label: "Update" }];

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      status: (feild, data) =>
        data?.status === "Accepted" ? (
          <p className="pro-mb-0">{data[feild] ?? ""}</p>
        ) : (
          <div className="col-12 no-border" style={{ minWidth: "222px" }}>
            {hasUpdatePermission === 1 && !dashboard ? (
              <Select
                options={options}
                menuPlacement={`auto`}
                menuPosition={"auto"}
                placeholder={"Action"}
                onChange={() => handleAction(data?._id)}
                className={Style.reactSelectContainer}
                classNamePrefix="pro-input"
              />
            ) : (
              <p className="pro-mb-0">{data[feild] ?? ""}</p>
            )}
          </div>
        ),
        name: (_, data) => (
          <ProfileNavigation
            userId={data?.member_id}
            fieldValue={data?.name}
            activeProfile={activeProfile}
            params={params}
            hasViewPermission={hasViewPermission === 1 ? true : false}
          />
        ),
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  let actionOptions = [
    { label: "Edit Columns", value: 0 },
    { label: "Export view", value: 1 },
  ];

  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    } else {
    }
  };

  const handleClearClick = () => {
    setDate({
      startDate: subDays(new Date(), 30),
      endDate: new Date(),
      key: "selection",
    });
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentPageSize = 10;
        state.startDate = subDays(new Date(), 30)
        state.endDate = new Date()
        state.sortBy = "";
        state.sortOrder = "";
        state.data = [];
        state.showCreateModal = false;
        state.showEditModal = false;
        state.selectedItemsDetails = "";
        state.search = "";
      })
    );
  };

  const handleAction = (id) => {
    setshowDataUpdationModal(() => true);
    setSelectedId(id);
  };

  const handleDashboardRedirect = () => {
    navigate("/support/data-updation");
  };

  const closeActionModal = () => {
    setshowDataUpdationModal(() => false);
  };

  const handleDateRangeChange = (range) => {
    const startDate = range[0];
    const endDate = range[1];

    setDate({
      startDate,
      endDate,
    });

    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = startDate;
        state.endDate = endDate;
      })
    );
  };

  return {
    handleClearClick,
    DataUpdationList,
    dataUpdation,
    currentPage: dataUpdation.currentPage,
    paginationOptions,
    isFetching,
    isLoading,
    showDataUpdationModal,
    selectedId,
    actionOptions,
    hasExportPermission,
    date,
    handleDateRangeChange,
    handleActionChange,
    closeActionModal,
    handlePagination,
    handleDateChange,
    handleDashboardRedirect,
    handlePageSize,
    handleSort,
    handleSearch,
    getRow,
    handleEditClick,
    closeEditModal,
    showEditModal,
    refetch,
    updateDataUpdationFields,
  };
};

export default useDataUpdation;
