import { useDispatch, useSelector } from "react-redux";

import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { updateConfig } from "../../../store/slices/CMS/usersList";
import { getFormatedDate } from "../../../utils/functions/table";
import { useMemo, useState } from "react";
import {
  useGetUsersListDataQuery,
  useUpdateUsersListFieldsDataMutation,
} from "../../../store/queries/cms";
import { ProfileNavigation } from "../../Global/ProfileNavigation";
import { useSearchParams } from "react-router-dom";
import { subDays } from "date-fns";
import { IconText } from "@wac-ui-dashboard/wac_component_library";
import moment from "moment";
import { getExportedData } from "./api";
import { toast } from "react-toastify";

const useUsersList = () => {
  const dispatch = useDispatch();

  const usersList = useSelector((state) => state.usersList);
  const [params] = useSearchParams();
  const activeProfile =
    params.get("active") ?? sessionStorage.getItem("active");
  const { showEditModal } = useSelector((state) => state.global);

  const [showImageModal, setShowImageModal] = useState(false);

  const [imageData, setImageData] = useState("");
  const [loadingExport, setLoadingExport] = useState(false);

  const [date, setDate] = useState({
    startDate: usersList.startDate,
    endDate: usersList.endDate,
    key: "selection",
  });

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const [updateUsersListFields] = useUpdateUsersListFieldsDataMutation();

  const {
    data: usersListData = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetUsersListDataQuery({
    page_size: usersList.currentPageSize,
    page: usersList.currentPage,
    sort_by: usersList.sortBy,
    sort_order: usersList.sortOrder,
    start: getFormatedDate(usersList.startDate),
    end: getFormatedDate(usersList.endDate),
    search: usersList.search,
  });

  const hasViewPermission = useMemo(() => {
    let permission = usersListData?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_view")
    );

    return permission?.[0]?.can_view ?? 0;
  }, [usersListData]);

  const hasExportPermission = useMemo(() => {
    let permission = usersListData?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_export")
    );

    return permission?.[0]?.can_export ?? 0;
  }, [usersListData]);

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleDateChange = (range) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = range.startDate
        state.endDate = range.endDate
      })
    );
  };

  const handleSort = (label) => {
    if (usersList.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = usersList.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };
  const handleEditClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      photo: (feild, data) =>
        data?.[feild] ? (
          <div className="no-wrap">
            <IconText
              icon={
                <span className="material-symbols-outlined x4">imagesmode</span>
              }
              title={`${"View Photo"}`}
              onClick={() => handleViewImage(data?.[feild])}
            />
          </div>
        ) : (
          <></>
        ),
      name: (_, data) => (
        <ProfileNavigation
          userId={data?._id}
          fieldValue={data?.name}
          activeProfile={activeProfile}
          params={params}
          hasViewPermission={hasViewPermission === 1 ? true : false}
        />
      ),
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleViewImage = (data) => {
    setImageData(data);

    setShowImageModal(() => true);
  };

  const closeModal = () => {
    setShowImageModal(() => false);
    setImageData("");
  };
  let actionOptions = [
    { label: "Edit Columns", value: 0 },
    { label: "Export view", value: 1 },
  ];

  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    } else {
      setLoadingExport(true)
      getExportedData({
        fields: Object.keys(usersListData?.data?.fields),
        sort_by: usersList.sortBy,
        sort_order: usersList.sortOrder,
        start: getFormatedDate(usersList.startDate),
        end: getFormatedDate(usersList.endDate),
        search: usersList.search,
      }).then((response) => {
        if (response?.status=== 200) {
          let dataType = response?.data?.type;
          let binaryData = [];
          binaryData.push(response?.data);
          let downloadLink = document.createElement("a");
          downloadLink.href = window.URL.createObjectURL(
            new Blob(binaryData, { type: dataType })
          );
          downloadLink.setAttribute(
            "download",
            `${moment(Date.now()).format("MM_DD_YYYY_HH_mm_ss")}.zip`
          );
          document.body.appendChild(downloadLink);
          downloadLink.click();
          setLoadingExport(false)
        } else {
          toast.error("Something went wrong");
          setLoadingExport(false)
        }
      })
    }
  };

  const handleClearClick = () => {
    setDate({
      startDate: subDays(new Date(), 30),
      endDate: new Date(),
      key: "selection",
    });
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentPageSize = 10;
        state.startDate = subDays(new Date(), 30)
        state.endDate = new Date()
        state.sortBy = "";
        state.sortOrder = "";
        state.data = [];
        state.showCreateModal = false;
        state.showEditModal = false;
        state.selectedItemsDetails = "";
        state.search = "";
      })
    );
  };

  const handleDateRangeChange = (range) => {
    const startDate = range[0];
    const endDate = range[1];

    setDate({
      startDate,
      endDate,
    });

    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = startDate;
        state.endDate = endDate;
      })
    );
  };

  return {
    loadingExport,
    usersListData,
    handleClearClick,
    hasExportPermission,
    usersList,
    imageData,
    showImageModal,
    date,
    setShowImageModal,
    currentPage: usersList.currentPage,
    paginationOptions,
    isFetching,
    isLoading,
    actionOptions,
    handleDateRangeChange,
    handleActionChange,
    handlePagination,
    handleDateChange,
    handlePageSize,
    handleSort,
    handleSearch,
    getRow,
    handleEditClick,
    closeModal,
    closeEditModal,
    showEditModal,
    refetch,

    updateUsersListFields,
  };
};

export default useUsersList;
