import React from "react";
import useInvoice from "./useInvoice";
import { useSelector } from "react-redux";
import Style from "./invoice.module.scss";
import Assets from "../../../../assets/Assets";
import InvoiceShimmer from "../../../Global/Shimmers/InvoiceShimmer";

const Invoice = () => {
  const {
    handleDownloadClick,
    handlePrintClick,
    globalState,
    invoice,
    invoiceRef,
  } = useInvoice();
  const { invoiceLoading } = useSelector((state) => state.transactions);

  return (
    //FIXME style
    <div className="pro-py-5 pro-pt-0">
      {invoiceLoading ? (
        <InvoiceShimmer />
      ) : (
        <div className="pro-w-100">
          <div ref={invoiceRef} className="pro-mx-5 pro-pt-5">
            <div className="pro-w-100 pro-pb-5">
              <div className={`${Style.logoWrapper} pro-pb-3 pro-pt-2`}>
                <figure className="pro-mb-0">
                  <img
                    src={
                      globalState.currentTheme === "light" ||
                      globalState.currentTheme === null
                        ? Assets.HEADERLOGO
                        : Assets.HEADERLOGODARK
                    }
                    alt="logo"
                  />
                </figure>
              </div>
              <table className={Style.user_detail_table}>
                <tbody>
                  <tr>
                    <td>
                      <label
                        htmlFor=""
                        className={`pro-font-xs pro-mb-0 ${Style.label}`}
                      >
                        Name
                      </label>
                      <p className="pro-mb-0 pro-fw-bold">{invoice?.name}</p>
                    </td>
                    <td>
                      <label
                        htmlFor=""
                        className={`pro-font-xs pro-mb-0 ${Style.label}`}
                      >
                        Payment Mode
                      </label>
                      <p className="pro-mb-0 pro-fw-bold">
                        {invoice?.payment_mode}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label
                        htmlFor=""
                        className={`pro-font-xs pro-mb-0 ${Style.label}`}
                      >
                        User ID
                      </label>
                      <p className="pro-mb-0 pro-fw-bold">{invoice?.user_id}</p>
                    </td>
                    <td>
                      <label
                        htmlFor=""
                        className={`pro-font-xs pro-mb-0 ${Style.label}`}
                      >
                        Transaction ID
                      </label>
                      <p className="pro-mb-0 pro-fw-bold">
                        {invoice?.transaction_id}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label
                        htmlFor=""
                        className={`pro-font-xs pro-mb-0 ${Style.label}`}
                      >
                        Date
                      </label>
                      <p className="pro-mb-0 pro-fw-bold">{invoice?.date}</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <table className={Style.invoice_table}>
              <tbody>
                <tr>
                  <th>#</th>
                  <th>Product Name</th>
                  <th>Price</th>
                  <th></th>
                  <th>Amount</th>
                </tr>
                <tr>
                  <td>1</td>
                  <td>{invoice?.product_name}</td>
                  <td>₹ {invoice?.price}</td>
                  <td></td>
                  <td>₹ {invoice?.package_amount}</td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  {/* <td className="pro-text-end">IGST@18%</td>
                <td className="pro-text-end">₹</td> */}
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  {/* <td className="pro-text-end">IGST@18%</td>
                <td className="pro-text-end">₹ 1</td> */}
                </tr>
              </tbody>
            </table>
            <div
              className={`pro-d-flex pro-justify-between pro-py-3 ${Style.table_btm_content}`}
            >
              <p className="pro-mb-0 pro-font-xs pro-flex-shrink-0">
                Total Amount
              </p>
              <div className="pro-text-end">
                <h6 className="pro-mb-1">₹ {invoice?.total_amount}</h6>
                <p className={`pro-mb-0 pro-text-uppercase ${Style.total}`}>
                  {invoice?.amount_in_words}
                </p>
              </div>
            </div>
          </div>
          <div className="pro-d-flex pro-pt-5 pro-mx-5">
            {/* <button className="pro-btn pro-btn-secondary" onClick={handlePrintClick}>print</button>
            <button className="pro-btn pro-btn-secondary pro-ms-3" onClick={handleDownloadClick}>Download</button> */}

            <button
              onClick={handlePrintClick}
              className={`pro-btn-link dark pro-items-center pro-py-2 pro-px-2`}
            >
              <span className="material-symbols-outlined"> print </span>
              <span>
                <p className={`pro-fw-bold pro-mb-0 pro-ms-1`}>Print</p>
              </span>
            </button>

            <button
              onClick={handleDownloadClick}
              className={`pro-btn-link dark pro-items-center pro-py-2 pro-px-2 pro-ms-3`}
            >
              <span className="material-symbols-outlined">
                {" "}
                cloud_download{" "}
              </span>
              <span>
                <p className={`pro-fw-bold pro-mb-0 pro-ms-1`}>Download</p>
              </span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Invoice;
