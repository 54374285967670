import React from "react";
import Style from "./settingsForm.module.scss";
import RowOne from "./RowOne";
import RowTwo from "./RowTwo";

const SettingsForm = ({
  formik,
  phoneVerified,
  handleMenuOpen,
  handleMenuClose,
  menuIsOpen,
  settingsData,
  overViewData,
  optionsData,
  cuntryCodeOptions,
  validatePhoneStatus,
  showPassword,
  otp,
  hasBanPermission,
  hasDeletePermission,
  hasUpdateContactPermission,
  hasUpdatePasswordPermission,
  handlePhoneNumberChange,
  handleShowPassword,
  handleShowConfirmPassword,
  showStaffPassword,
  showConfirmPassword,
  handleShowStaffPassword,
  handleContactUpdateClick,
  handleDeleteUserClick,
  handleBanUserClick,
  handleChangePasswordClick,
  handleVerifyPhone,
  handleValidatePhone,
}) => {
  return (
    <div className={Style.root}>
      <div className="col-12 pro-mb-4 pro-px-5 pro-mt-5">
        {/**
         * 
       * Email
       * Phone Number
       * OTP
      
       */}
        <RowOne
          formik={formik}
          phoneVerified={phoneVerified}
          handleMenuOpen={handleMenuOpen}
          handleMenuClose={handleMenuClose}
          menuIsOpen={menuIsOpen}
          otp={otp}
          overViewData={overViewData}
          handlePhoneNumberChange={handlePhoneNumberChange}
          settingsData={settingsData}
          hasUpdateContactPermission={hasUpdateContactPermission}
          validatePhoneStatus={validatePhoneStatus}
          cuntryCodeOptions={cuntryCodeOptions}
          handleContactUpdateClick={handleContactUpdateClick}
          handleVerifyPhone={handleVerifyPhone}
          handleValidatePhone={handleValidatePhone}
        />

        {/**
         * 
       * New  Password
       * Reason for change
       * Reason for Ban
       * Period
       * Reason
       * Staff Password
      
       */}
        <RowTwo
          formik={formik}
          showPassword={showPassword}
          optionsData={optionsData}
          overViewData={overViewData}
          handleShowPassword={handleShowPassword}
          handleShowConfirmPassword={handleShowConfirmPassword}
          showConfirmPassword={showConfirmPassword}
          showStaffPassword={showStaffPassword}
          handleShowStaffPassword={handleShowStaffPassword}
          handleDeleteUserClick={handleDeleteUserClick}
          handleBanUserClick={handleBanUserClick}
          hasBanPermission={hasBanPermission}
          hasDeletePermission={hasDeletePermission}
          hasUpdatePasswordPermission={hasUpdatePasswordPermission}
          handleChangePasswordClick={handleChangePasswordClick}
        />
      </div>
    </div>
  );
};

export default SettingsForm;
