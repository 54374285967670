import Style from "./profileCardShimmer.module.scss";

const ProfileCardShimmer = ({ loading }) => {
  return (
    <div
      className={`row ${Style.root} pro-mx-5`}
      style={{ "--blink-duration": "1.8s" }}
    >
      <div>
        <div
          className={`pro-w-100 ${Style.item}`}
          style={{ "--blink-delay": "0s" }}
        >
          <div className="img blink"></div>
          <div className="pro-p-3 pro-d-flex pro-flex-column pro-gap-3">
            <div className="letter shimmer"></div>
            <div className="letter shimmer"></div>
            <div className="pro-d-flex pro-gap-1">
              <span className="shimmer  pro-px-3 pro-py-1 pro-rounded-3"></span>
              <span className="shimmer  pro-px-3 pro-py-1 pro-rounded-3"></span>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div
          className={`pro-w-100 ${Style.item}`}
          style={{ "--blink-delay": "0.2s" }}
        >
          <div className="img blink"></div>
          <div className="pro-p-3 pro-d-flex pro-flex-column pro-gap-3">
            <div className="letter shimmer"></div>
            <div className="letter shimmer"></div>
            <div className="pro-d-flex pro-gap-1">
              <span className="shimmer  pro-px-3 pro-py-1 pro-rounded-3"></span>
              <span className="shimmer  pro-px-3 pro-py-1 pro-rounded-3"></span>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div
          className={`pro-w-100 ${Style.item}`}
          style={{ "--blink-delay": "0.4s" }}
        >
          <div className="img blink"></div>
          <div className="pro-p-3 pro-d-flex pro-flex-column pro-gap-3">
            <div className="letter shimmer"></div>
            <div className="letter shimmer"></div>
            <div className="pro-d-flex pro-gap-1">
              <span className="shimmer  pro-px-3 pro-py-1 pro-rounded-3"></span>
              <span className="shimmer  pro-px-3 pro-py-1 pro-rounded-3"></span>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div
          className={`pro-w-100 ${Style.item}`}
          style={{ "--blink-delay": "0.6s" }}
        >
          <div className="img blink"></div>
          <div className="pro-p-3 pro-d-flex pro-flex-column pro-gap-3">
            <div className="letter shimmer"></div>
            <div className="letter shimmer"></div>
            <div className="pro-d-flex pro-gap-1">
              <span className="shimmer  pro-px-3 pro-py-1 pro-rounded-3"></span>
              <span className="shimmer  pro-px-3 pro-py-1 pro-rounded-3"></span>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div
          className={`pro-w-100 ${Style.item}`}
          style={{ "--blink-delay": "0.8s" }}
        >
          <div className="img blink"></div>
          <div className="pro-p-3 pro-d-flex pro-flex-column pro-gap-3">
            <div className="letter shimmer"></div>
            <div className="letter shimmer"></div>
            <div className="pro-d-flex pro-gap-1">
              <span className="shimmer  pro-px-3 pro-py-1 pro-rounded-3"></span>
              <span className="shimmer  pro-px-3 pro-py-1 pro-rounded-3"></span>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div
          className={`pro-w-100 ${Style.item}`}
          style={{ "--blink-delay": "1s" }}
        >
          <div className="img blink"></div>
          <div className="pro-p-3 pro-d-flex pro-flex-column pro-gap-3">
            <div className="letter shimmer"></div>
            <div className="letter shimmer"></div>
            <div className="pro-d-flex pro-gap-1">
              <span className="shimmer  pro-px-3 pro-py-1 pro-rounded-3"></span>
              <span className="shimmer  pro-px-3 pro-py-1 pro-rounded-3"></span>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div
          className={`pro-w-100 ${Style.item}`}
          style={{ "--blink-delay": "1.2s" }}
        >
          <div className="img blink"></div>
          <div className="pro-p-3 pro-d-flex pro-flex-column pro-gap-3">
            <div className="letter shimmer"></div>
            <div className="letter shimmer"></div>
            <div className="pro-d-flex pro-gap-1">
              <span className="shimmer  pro-px-3 pro-py-1 pro-rounded-3"></span>
              <span className="shimmer  pro-px-3 pro-py-1 pro-rounded-3"></span>
            </div>
          </div>
        </div>
      </div>
      {!loading && (
        <>
          <div>
            <div
              className={`pro-w-100 ${Style.item}`}
              style={{ "--blink-delay": "1.4s" }}
            >
              <div className="img blink"></div>
              <div className="pro-p-3 pro-d-flex pro-flex-column pro-gap-3">
                <div className="letter shimmer"></div>
                <div className="letter shimmer"></div>
                <div className="pro-d-flex pro-gap-1">
                  <span className="shimmer  pro-px-3 pro-py-1 pro-rounded-3"></span>
                  <span className="shimmer  pro-px-3 pro-py-1 pro-rounded-3"></span>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              className={`pro-w-100 ${Style.item}`}
              style={{ "--blink-delay": "1.6s" }}
            >
              <div className="img blink"></div>
              <div className="pro-p-3 pro-d-flex pro-flex-column pro-gap-3">
                <div className="letter shimmer"></div>
                <div className="letter shimmer"></div>
                <div className="pro-d-flex pro-gap-1">
                  <span className="shimmer  pro-px-3 pro-py-1 pro-rounded-3"></span>
                  <span className="shimmer  pro-px-3 pro-py-1 pro-rounded-3"></span>
                </div>
              </div>
            </div>
          </div>{" "}
        </>
      )}
    </div>
  );
};

export default ProfileCardShimmer;
