import InputShimmer from "../InputShimmer";
import ProfileCardShimmer from "../ProfileCardShimmer";
import ReactSliderShimmer from "../ReactSliderShimmer";

const MatchPrefecenceShimmer = ({ profileCard = true }) => {
  return (
    <>
      <div className="pro-d-flex pro-justify-between pro-pt-6 pro-pb-5 pro-px-5">
        <div className="shimmer pro-d-flex">
          <div className="pro-px-6 pro-py-4"></div>
          <div className="pro-px-6 pro-py-4"></div>
        </div>
        <div className="pro-d-flex pro-gap-3">
          <div className="pro-py-4 pro-px-6 shimmer"></div>
          <div className="pro-py-4 pro-px-6 shimmer"></div>
        </div>
      </div>
      <div className="pro-m-4 pro-mx-5">
        <div className="row row-cols-4">
          <div>
            <InputShimmer />
          </div>
        </div>
      </div>
      <div className="pro-m-4 pro-mx-5">
        <div className="row row-cols-4">
          <div className="pro-mb-5 pro-mt-2">
            <ReactSliderShimmer />
          </div>
          <div className="pro-mb-5 pro-mt-2">
            <ReactSliderShimmer />
          </div>
          <div className="pro-mb-5 pro-mt-2">
            <ReactSliderShimmer />
          </div>
          <div className="pro-mb-5 pro-mt-2">
            <InputShimmer />
          </div>

          <div className="pro-mb-5 pro-mt-2">
            <InputShimmer />
          </div>
          <div className="pro-mb-5 pro-mt-2">
            <InputShimmer />
          </div>
          <div className="pro-mb-5 pro-mt-2">
            <InputShimmer />
          </div>
          <div className="pro-mb-5 pro-mt-2">
            <InputShimmer />
          </div>
          <div className="pro-mb-5 pro-mt-2">
            <InputShimmer />
          </div>
          <div className="pro-mb-5 pro-mt-2">
            <InputShimmer />
          </div>
          <div className="pro-mb-5 pro-mt-2">
            <InputShimmer />
          </div>

          <div className="pro-mb-5 pro-mt-2">
            <InputShimmer />
          </div>
          <div className="pro-mb-5 pro-mt-2">
            <InputShimmer />
          </div>
          <div className="pro-mb-5 pro-mt-2">
            <InputShimmer />
          </div>
          <div className="pro-mb-5 pro-mt-2">
            <InputShimmer />
          </div>

          <div className="pro-mb-5 pro-mt-2">
            <InputShimmer />
          </div>
          <div className="pro-mb-5 pro-mt-2">
            <InputShimmer />
          </div>
        </div>
      </div>
      {profileCard && <ProfileCardShimmer />}
    </>
  );
};

export default MatchPrefecenceShimmer;
