import { Button } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useAddBranchForm from "./useAddForm";
import AddDioceseForm from "../AddDioceseForm";

const AddDioceseFormContainer = ({
  setShowform,
  hasUpdatePermission,
  refetch,
}) => {
  const {
    formik,
    errors,
    loading,
    communityList,
    message,
    optionsWithIcons,
    staffList,
    selectedOption,
    setSelectedOption,
    options,
  } = useAddBranchForm(setShowform, refetch);

  return (
    <div className={`pro-p-4`}>
      <AddDioceseForm
        optionsWithIcons={optionsWithIcons}
        staffList={staffList}
        formik={formik}
        communityList={communityList}
        setShowform={setShowform}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        options={options}
      />
      <div className="col-12 pro-mt-4 pro-d-flex pro-justify-end">
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => setShowform(false)}
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          disabled={hasUpdatePermission === 1 ? false : true}
          onClick={formik.handleSubmit}
        >
          {loading ? (
            <i
              className="spinner-border spinner-border-sm me-3"
              role="status"
              aria-hidden="true"
            ></i>
          ) : (
            ""
          )}
          Save
        </Button>
      </div>

      {errors && <span className="error-text">{errors}</span>}

      {Object.keys(message)?.map((key, item) => {
        return <div className="error-text">{key + " : " + message[key]} </div>;
      })}
    </div>
  );
};

export default AddDioceseFormContainer;
