import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  sortBy: "",
  sortOrder: "desc",
  data: [],
  showCreateModal: false,
  clearSelection: false,
  selectedBranch: "",
  editBranch: false,
  search: "",
};

export const saveStaffDetails = createAsyncThunk(
  "branches/saveStaffDetails",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("admin/branches/staff/create", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteStaffFromBranches = createAsyncThunk(
  "branches/deleteStaffFromBranches",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.put("admin/branches/staff/update", params);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const branchesDetailsPageSlice = createSlice({
  name: "branchesDetailsPage",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
    selectBranch: (state, action) => {
      state.selectedBranch = action.payload;
    },
    editBranch: (state, action) => {
      state.editBranch = action.payload;
    },
  },
  extraReducers: {
    [saveStaffDetails.pending]: (state, action) => {
      state.status = "loading";
    },
    [saveStaffDetails.fulfilled]: (state, action) => {
      state.status = "loading";
    },
    [saveStaffDetails.rejected]: (state, action) => {
      state.status = "error";
    },
  },
});

export const { updateConfig, selectBranch, editBranch } =
  branchesDetailsPageSlice.actions;

export default branchesDetailsPageSlice.reducer;
