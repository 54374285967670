import React from "react";
import ReactDOM from "react-dom/client";

import { configureStore } from "@reduxjs/toolkit";
import App from "./components/App";
import { Provider } from "react-redux";
import appReducer from "./store";
import { members } from "./store/queries/members";
import { staff } from "./store/queries/settings";
import { payment } from "./store/queries/payment";
import { profile } from "./store/queries/Profile";
import { faqsRtk } from "./store/queries/cms";
import { usersRtk } from "./store/queries/users";
import { notificationRtk } from "./store/queries/notifications";
import { dashboard } from "./store/queries/Dashboard";

const store = configureStore({
  reducer: appReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      members.middleware,
      staff.middleware,
      payment.middleware,
      profile.middleware,
      faqsRtk.middleware,
      usersRtk.middleware,
      notificationRtk.middleware,
      dashboard.middleware
    ),
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
