import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateFormData } from "../../../../../store/slices/Users/createMemberSlice";
import { updateConfig } from "../../../../../store/slices/Users/membersSlice";

import { toast } from "react-toastify";
import {
  profile,
  useUpdateProfileDetailsMutation,
} from "../../../../../store/queries/Profile";
import { members } from "../../../../../store/queries/members";

const useEmployement = ({
  isOptionDataSuccess,
  isEditProfile,
  profileRefetch,
}) => {
  const occupationRef = useRef(" ");
  const stateRef = useRef(" ");
  const districtRef = useRef(" ");

  const { currentFormData, backendErrors } = useSelector(
    (state) => state.createMembers
  );
  const [sendRequest, { isLoading }] = useUpdateProfileDetailsMutation();
  const dispatch = useDispatch();

  let createMemberEmpVal = Yup.object({
    // state: Yup.object().required("*Required"),
    // country: Yup.object().required("*Required"),
    organization: Yup.string()
      .max(50, "max 50 characters allowed")
     ,
    // emp_sector: Yup.object().required("*Required"),
    // occupation_cat: Yup.object().required("*Required"),
    // occupation: Yup.object().required("*Required"),
    // annual_income: Yup.object().required("*Required"),
  });

  let editRequiredData = {
    user_id: currentFormData?.user_id,
    first_name: currentFormData?.first_name,
    last_name: currentFormData?.last_name,
    email: currentFormData?.email,
    country_code: currentFormData?.country_code,
    phone_number: currentFormData?.phone_number,
    gender: currentFormData?.gender?.id,
    dob: currentFormData?.dob,
    community_id: currentFormData?.community_id?._id,
    diocese_id: currentFormData?.diocese_id?._id,
  };

  const formik = useFormik({
    initialValues: {
      employment_sector: "",
      occupation_category: "",
      occupation: "",
       occupation_detail:"",
      organization: "",
      annual_income: "",
      work_country: "",
      work_state: "",
      work_district: "",
    },

    validationSchema: !isEditProfile ? createMemberEmpVal : "",
    enableReinitialize: true,

    onSubmit: (values, { resetForm }) => {
      try {
        let obj = {
          ...values,
        };

        let editObj = {
          employment_sector: values?.employment_sector?.id,
          occupation_category: values?.occupation_category?._id,
          occupation: values?.occupation?._id,
           occupation_detail: values?.occupation_detail,
          organization: values?.organization,
          annual_income: values?.annual_income?._id,
          work_country: values?.work_country?._id,
          work_state: values?.work_state?._id,
          work_district: values?.work_district?._id,
          type:4
        };

        let newObj = Object.entries(editObj);

        newObj = newObj
          .filter(
            (item) =>
              item[1] !== undefined && item[1] !== "" && item[1] !== null
          )
          .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {});

        if (isEditProfile) {
          let formData = new FormData();
          formData.append("_method", "PUT");

          Object.keys(newObj).forEach((key) => {
            return formData.append(key, newObj[key]);
          });
          Object.keys(editRequiredData).forEach((key) => {
            return formData.append(key, editRequiredData[key]);
          });

          sendRequest(formData).then((response) => {
            dispatch(
              updateFormData((state) => {
                state.backendErrors = "";
              })
            );

            if (response?.data?.status_code === 200) {
              resetForm();

              dispatch(
                updateFormData((state) => {
                  state.currentFormData = {};
                  state.isEditProfile = false;
                  state.activeTab = "Basic";
                  state.completedTabs = {
                    ...state.completedTabs,
                    Basic: false,
                    Family: false,
                    Job: false,
                    Edu: false,
                    Contact: false,
                  };
                  state.CompletedPercentage = {
                    ...state.CompletedPercentage,
                    Basic: 0,
                    Family: 0,
                    Job: 0,
                    Edu: 0,
                    Contact: 0,
                  };
                })
              );

              dispatch(
                updateConfig((state) => {
                  state.showCreateModal = false;
                  state.clearSelection = true;
                })
              );
              dispatch(members.util.invalidateTags(["Members", "Incomplete"]));
              dispatch(profile.util.invalidateTags(["Overview"]));

              toast.success("Successfully submitted");
            } else if (response?.data?.status_code === 400) {
              let message = Object.values(response?.data?.message)?.[0]?.[0];

              
              toast.error(`Failed to  update, ${message}`);
              dispatch(
                updateFormData((state) => {
                  // formik.setErrors(response?.data?.message);
                  state.backendErrors = response?.data?.message;
                })
              );
            } else {
              toast.error("Failed to  update");
            }
          });
        } else {
          dispatch(
            updateFormData((state) => {
              state.currentFormData = { ...state.currentFormData, ...obj };
              state.activeTab = "Contact";
              state.completedTabs = { ...state.completedTabs, Job: true };
            })
          );
        }
      } catch (error) {
        toast.error("Failed to  submit");
      }
    },
  });

  useEffect(() => {
    if (isOptionDataSuccess) {
      Object.keys(currentFormData || {}).forEach((key) => {
        formik.setFieldValue(key, currentFormData?.[key]);
      });
      let filteredTouchedData = Object.entries(currentFormData || {});

      filteredTouchedData = filteredTouchedData
        .filter(
          (item) => item[1] === undefined || item[1] === "" || item[1] === null
        )
        .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {});

      Object.keys(filteredTouchedData || {}).forEach((key) => {
        formik.setFieldTouched(key, true);
      });
    }
    // eslint-disable-next-line
  }, [isOptionDataSuccess, currentFormData]);

  useEffect(() => {
    if (backendErrors) {
      formik.setErrors(backendErrors);
    }
    // eslint-disable-next-line
  }, [backendErrors]);

  useEffect(() => {
    let totalFields = Object.values(formik.initialValues).length;

    const filterDataFromTab = (fullObject, tabObject) => {
      const filteredData = {};

      for (const key in tabObject) {
        if (fullObject.hasOwnProperty(key)) {
          filteredData[key] = fullObject[key];
        }
      }

      return filteredData;
    };

    const filteredData = filterDataFromTab(formik.values, formik.initialValues);

    const countNonEmptyKeys = (data) => {
      let count = 0;
      for (let key in data) {
        if (
          data.hasOwnProperty(key) &&
          data[key] !== null &&
          data[key] !== ""
        ) {
          count++;
        }
      }
      return count;
    };

    let numberOfNonEmptyKeys = countNonEmptyKeys(filteredData);

    dispatch(
      updateFormData((state) => {
        state.CompletedPercentage = {
          ...state.CompletedPercentage,
          Job: Math.ceil((numberOfNonEmptyKeys / totalFields) * 100),
        };
      })
    );
    // eslint-disable-next-line
  }, [formik.values]);

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
    dispatch(
      updateFormData((state) => {
        state.currentFormData = {};
        state.activeTab = "Basic";
        state.backendErrors = "";
        state.isEditProfile = false;
        state.phoneVerified = {
          verified: false,
          status: false,
        };
        state.completedTabs = {
          ...state.completedTabs,
          Basic: false,
          Family: false,
          Job: false,
          Edu: false,
          Contact: false,
        };
        state.CompletedPercentage = {
          ...state.CompletedPercentage,
          Basic: 0,
          Family: 0,
          Job: 0,
          Edu: 0,
          Contact: 0,
        };
      })
    );
  };

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }

    return "";
  };

  // const getFieldError = (fieldName) => {
  //   if (backendErrors && backendErrors[fieldName]) {
  //     return backendErrors[fieldName];
  //   }
  //   if (formik.touched[fieldName] && formik.errors[fieldName]) {
  //     return formik.errors[fieldName];
  //   }
  //   return "";
  // };

  return {
    stateRef,
    districtRef,
    currentFormData,
    occupationRef,
    formik,
    isLoading,
    handleCloseModal,
    getFieldError,
  };
};

export default useEmployement;
