import { useState } from "react";
import Style from "./ProfileCard.module.scss";

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

export function ProfileCard({ data, handleClose }) {
  
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <>
      <div className={`${Style.root}  pro-p-2`}>
        <TransformWrapper>
          {({ zoomIn, zoomOut }) => (
            <>
              <div className={Style.action_icon}>
                <button
                  className={`${Style.icon} pro-btn pro-p-0 pro-mb-1`}
                  onClick={() => zoomIn()}
                >
                  <span className="material-symbols-outlined">zoom_in</span>
                </button>
                <button
                  className={`${Style.icon} pro-btn pro-p-0`}
                  onClick={() => zoomOut()}
                >
                  <span className="material-symbols-outlined">zoom_out</span>
                </button>
              </div>
              <TransformComponent>
                <div className={`${Style.root_image} pro-mb-1`}>
                  <div className={Style.root_figure}>
                    <figure className={`${Style.image}`}>
                      <img
                        src={data || ""}
                        alt={"profile"}
                        heigh={`100%`}
                        width={`100%`}
                        onLoad={() => setImageLoaded(true)}
                      />
                    </figure>
                  </div>
                  {!imageLoaded && (
                    <div className={Style.root_figure}>
                      <div className={`shine ${Style.thumb}`} />
                    </div>
                  )}
                </div>
              </TransformComponent>
            </>
          )}
        </TransformWrapper>

        <div
          className={`${Style.buttons} pro-d-flex pro-mt-2 pro-m-1 pro-justify-end `}
        >
          {/** #FIXME Aneesh, haddle shine for buttons(border radius is high)   */}
          <button className={`pro-btn pro-btn-primary`} onClick={handleClose}>
            Close
          </button>
        </div>
      </div>
    </>
  );
}
