import { getAxiosInstance } from "../../../../api";

export const getGlobalSearchData = async ({ searchKey, page }) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(
      `admin/global-search?search=${searchKey}&page_size=10&page=${page}`
    );
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const markNotificationRead = async () => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(`user/notifications/all/read`);
    return response;
  } catch (error) {
    return error.response.data;
  }
};
