import React from "react";
import Style from "./blockModal.module.scss";
import {
  Button,
  HeadingGroup,
  Input,
} from "@wac-ui-dashboard/wac_component_library";
import useBlockModal from "./useBlockModal";

const BlockModal = ({
  block = false,
  setShowBlockedModal,
  selectedUser,
  handleClose,
}) => {
  const { formik, handleCloseModal } = useBlockModal({
    block,
    setShowBlockedModal,
    selectedUser,
    handleClose,
  });
  return (
    <div className="row">
      <HeadingGroup
        title={block ? "Block User" : "Unblock User"}
        extraClassName={`pro-mb-4 ${Style.heading}`}
      />
      <div className={`col-12`}>
        {/* <div className="input-wrap">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            User ID
          </label> 
           <Select
            id="user_id"
            name="user_id"
            isClearable={true}
            options={useDataOptions}
            value={useDataOptions?.find(
              (item) => item?.id === formik?.values?.user_id
            )}
            onInputChange={(value) => handleInputChange(value)}
            className={`pro-input multi-select lg ${
              formik.errors.user_id && formik.touched.user_id && " error"
            }`}
            classNamePrefix="pro-input"
            onBlur={formik.handleBlur("user_id")}
            onChange={(value) => {
              formik.setFieldValue("user_id", value?.id);
            }}
          ></Select> 

         

          {formik.touched.user_id && formik.errors.user_id && (
            <span className="error-text">{formik.errors.user_id}</span>
          )}
        </div> */}

        {/* <Input
          label={"User ID"}
          type="text"
          disabled={true}
          id="member_id"
          name="member_id"
          placeholder="In Days"
          className={`pro-input lg ${
            formik.errors.member_id && formik.touched.member_id && " error"
          }`}
          {...formik.getFieldProps("member_id")}
          error={formik.errors.member_id && formik.touched.member_id}
          errorMessage={formik.errors.member_id}
        /> */}
      </div>

      <div className="col-12 pro-mb-4">
        <div className="input-wrap">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Reason
          </label>
          <textarea
            id="reason"
            rows={6}
            name="reason"
            className={`pro-input lg ${
              formik.errors.reason && formik.touched.reason && " error"
            }`}
            onChange={(e) => formik.setFieldValue("reason", e.target.value)}
          ></textarea>
          {formik.touched.reason && formik.errors.reason && (
            <span className="error-text">{formik.errors.reason}</span>
          )}
        </div>
      </div>
      {block ? (
        <div className="col-12 pro-mb-4">
          <Input
            label={"Period"}
            type="text"
            id="duration"
            name="duration"
            placeholder="In Days"
            className={`pro-input lg ${
              formik.errors.duration && formik.touched.duration && " error"
            }`}
            {...formik.getFieldProps("duration")}
            error={formik.errors.duration && formik.touched.duration}
            errorMessage={formik.errors.duration}
          />
        </div>
      ) : (
        <></>
      )}

      <div className={`pro-d-flex  col-6`}>
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => handleCloseModal()}
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-mx-3"}
          onClick={formik.handleSubmit}
          type="submit"
        >
          Confirm
        </Button>
      </div>
    </div>
  );
};

export default BlockModal;
