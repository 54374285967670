import React from "react";
import Style from "./payment.module.scss";

const FAQsFormFields = ({
  formik
}) => {
  return (
    <div className={Style.root}>
      <div className="col-12 pro-mb-4">
        <div className="row">
          <div className="input-wrap pro-mb-4">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Name*
            </label>
            <input
              type="text"
              className={`pro-input lg ${
                formik.errors.name && formik.touched.name && " error"
              }`}
              id="name"
              name="name"
              {...formik.getFieldProps("name")}
            />
            {formik.touched.name && formik.errors.name && (
              <span className="error-text">{formik.errors.name}</span>
            )}
          </div>

         
         
          {/* <div className="col-12 pro-mb-4">
            <div className="input-wrap">
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                Branch Admin
              </label>
              <input
                type="text"
                className={`pro-input lg ${
                  formik.errors.branch_admin && formik.touched.branch_admin && " error"
                }`}
                id="branch_admin"
                name="branch_admin"
                {...formik.getFieldProps("branch_admin")}
              />
              {formik.touched.branch_admin && formik.errors.branch_admin && (
                <span className="error-text">{formik.errors.branch_admin}</span>
              )}
            </div>
          </div> */}

        </div>
      </div>
    </div>
  );
};

export default FAQsFormFields;
