import React from "react";
import MonthPicker from "react-month-picker";
import "react-month-picker/scss/month-picker.scss";
import useProfileVisitedChart from "./useProfileVisitedChart";
import { DataContainer } from "@wac-ui-dashboard/wac_component_library";
import Style from "./chart.module.scss";

const ProfileVisitedChart = ({
  title,
  datasets,
  state,
  value,
  selectedChart,
    setSelectedChart,
  setValue,
  activeProfile,
  index,
}) => {
  const {
    chartRef,
    monthPickerRef,
    lang,
    showPicker,
    
    handlePickerChange,
  } = useProfileVisitedChart({
    datasets,
    state,
    title,
    value,
    selectedChart,
    setSelectedChart,
  setValue,
    activeProfile,
    index,
  });

  return (
    <>
      <DataContainer>
        <div className={`pro-d-flex pro-justify-between ${Style.root}`}>
          <h6 className="pro-ttl ">{title}</h6>
          <MonthPicker
            lang={lang.months}
            ref={monthPickerRef}
            value={value[index]}
            onChange={(...item) => handlePickerChange(item, title)}
          >
            <button
              className={`pro-btn pro-btn-outline ${Style.btn}`}
              onClick={showPicker}
            >
              {lang.months[value[index].month - 1]}
            </button>
          </MonthPicker>
        </div>
        <canvas
          ref={chartRef}
          id="profileVisitedChart"
          style={{ width: "100%", maxWidth: "400px", maxHeight: "300px" }}
        />
      </DataContainer>
    </>
  );
};

export default ProfileVisitedChart;
