import { useDispatch, useSelector } from "react-redux";

import {
  useGetTransferListDataQuery,
  useUpdateTransferListFieldsDataMutation,
} from "../../../store/queries/notifications";

import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { updateConfig } from "../../../store/slices/Notifications/transfersSlice";
import { getFormatedDate } from "../../../utils/functions/table";
import { Image } from "@wac-ui-dashboard/wac_component_library";
import Style from "./transfers.module.scss";
import { useMemo, useState } from "react";
import moment from "moment";
import { subDays } from "date-fns";
import { getExportedData } from "./api";

const useTransfers = () => {
  const dispatch = useDispatch();

  const transfers = useSelector((state) => state.transfer);

  const { showEditModal } = useSelector((state) => state.global);

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const [date, setDate] = useState({
    startDate: transfers.startDate,
    endDate: transfers.endDate,
    key: "selection",
  });

  const [updateTransfersFields] = useUpdateTransferListFieldsDataMutation();

  const {
    data: transfersList = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetTransferListDataQuery({
    page_size: transfers.currentPageSize,
    page: transfers.currentPage,
    sort_by: transfers.sortBy,
    sort_order: transfers.sortOrder,
    start: getFormatedDate(transfers.startDate),
    end: getFormatedDate(transfers.endDate),
    search: transfers.search,
  });

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleDateChange = (range) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = range.startDate;
        state.endDate = range.endDate
      })
    );
  };

  const hasExportPermission = useMemo(() => {
    let permission = transfersList?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_export")
    );

    return permission?.[0]?.can_export ?? 0;
  }, [transfersList]);

  const handleSort = (label) => {
    if (transfers.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = transfers.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };
  const handleEditClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      staff_id: (feild, data) => (
        <p className="pro-mb-0">{data[feild]?.name ?? ""}</p>
      ),
      transferred_from: (feild, data) => (
        <div className="pro-d-flex pro-gap-2">
          <div
            className={`pro-rounded-circle pro-overflow-hidden ${Style.img_wrap}`}
          >
            <Image
              width={16}
              height={16}
              src={data[feild]?.image_url}
              alt="icon"
            />
          </div>
          <p className="pro-mb-0">{data[feild]?.name}</p>
        </div>
      ),
      transferred_to: (feild, data) => (
        <div className="pro-d-flex pro-gap-2">
          <div
            className={`pro-rounded-circle pro-overflow-hidden ${Style.img_wrap}`}
          >
            <Image
              height={16}
              width={16}
              src={data[feild]?.image_url}
              alt="icon"
            />
          </div>
          <p className="pro-mb-0">{data[feild]?.name}</p>
        </div>
      ),
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  let actionOptions = [
    { label: "Edit Columns", value: 0 },
    { label: "Export view", value: 1 },
  ];

  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    } else {
      getExportedData({
        fields: Object.keys(transfersList?.data?.fields),
        filter: transfers.currentFilter,
        sort_by: transfers.sortBy,
        sort_order: transfers.sortOrder,
        start: getFormatedDate(transfers.startDate),
        end: getFormatedDate(transfers.endDate),
        search: transfers.search,
      }).then((response) => {
        let dataType = response?.data?.type;
        let binaryData = [];
        binaryData.push(response?.data);
        let downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(
          new Blob(binaryData, { type: dataType })
        );
        downloadLink.setAttribute(
          "download",
          `${moment(Date.now()).format("MM_DD_YYYY_HH_mm_ss")}.xlsx`
        );
        document.body.appendChild(downloadLink);
        downloadLink.click();
      });
    }
  };

  const handleClearClick = () => {
    setDate({
      startDate: subDays(new Date(), 30),
      endDate: new Date(),
      key: "selection",
    });
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentPageSize = 10;
        state.startDate = subDays(new Date(), 30)
        state.endDate = new Date()
        state.sortBy = "";
        state.sortOrder = "";
        state.data = [];
        state.showCreateModal = false;
        state.showEditModal = false;
        state.selectedItemsDetails = "";
        state.search = "";
      })
    );
  };

  const handleDateRangeChange = (range) => {
    const startDate = range[0];
    const endDate = range[1];

    setDate({
      startDate,
      endDate,
    });

    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = startDate;
        state.endDate = endDate;
      })
    );
  };

  return {
    transfersList,
    handleClearClick,
    transfers,
    currentPage: transfers.currentPage,
    paginationOptions,
    isFetching,
    isLoading,
    actionOptions,
    hasExportPermission,
    date,
    handleDateRangeChange,
    handleActionChange,
    handlePagination,
    handleDateChange,
    handlePageSize,
    handleSort,
    handleSearch,
    getRow,
    handleEditClick,
    closeEditModal,
    showEditModal,
    refetch,

    updateTransfersFields,
  };
};

export default useTransfers;
