import React from "react";
import { HeadingGroup, Input } from "@wac-ui-dashboard/wac_component_library";
import Style from "../settingsForm.module.scss";
import Select from "react-select";

const RowTwo = ({
  formik,
  showPassword,
  optionsData,
  handleShowPassword,
  hasDeletePermission,
  hasBanPermission,
  hasUpdatePasswordPermission,
  overViewData,
  handleShowConfirmPassword,
  showStaffPassword,
  showConfirmPassword,
  handleShowStaffPassword,
  handleDeleteUserClick,
  handleBanUserClick,
  handleChangePasswordClick,
}) => {
  return (
    <>
      <>
        <div className="row">
          <div className="col-12">
            <HeadingGroup
              title={"Change Password"}
              buttonTitle={
                hasUpdatePasswordPermission === 1 &&
                overViewData?.data?.data?.assigned_profile === 1
                  ? "Update"
                  : ""
              }
              extraClassName={`pro-mb-4 ${Style.heading}`}
              handleClick={handleChangePasswordClick}
              buttonProps={{
                disabled: !(
                  formik.values.password?.length > 0 &&
                  formik.values.confirm_password?.length > 0 &&
                  Object.keys(formik.values.reset_reason_id || {})?.length >
                    0 &&
                  overViewData?.data?.data?.assigned_profile === 1
                ),
              }}
            ></HeadingGroup>
          </div>

          <div className="col-4">
            <div className="p-re">
              <Input
                label={"New Password"}
                type="password"
                id="password"
                autoComplete="new-password"
                name="password"
                disabled={
                  !(
                    hasUpdatePasswordPermission === 1 &&
                    overViewData?.data?.data?.assigned_profile === 1
                  )
                }
                className={`pro-input lg ${
                  formik.errors.password && formik.touched.password && " error"
                } hide-icon-input`}
                {...formik.getFieldProps("password")}
                error={formik.errors.password && formik.touched.password}
                errorMessage={formik.errors.password}
              />
              {/* {showPassword ? (
            <button
              className={`hide-icon`}
              onClick={(e) => handleShowPassword(e)}
            >
              <span className="material-symbols-outlined"> visibility_off </span>
            </button>
          ) : (
            <button
              className={`hide-icon`}
              onClick={(e) => handleShowPassword(e)}
            >
              <span className="material-symbols-outlined"> visibility </span>
            </button>
          )} */}
            </div>
          </div>

          <div className="col-4">
            <div className="p-re">
              <Input
                label={"Confirm Password"}
                type="password"
                id="confirm_password"
                autoComplete="new-password"
                disabled={
                  !(
                    hasUpdatePasswordPermission === 1 &&
                    overViewData?.data?.data?.assigned_profile === 1
                  )
                }
                name="confirm_password"
                className={`pro-input lg ${
                  formik.errors.confirm_password &&
                  formik.touched.confirm_password &&
                  " error"
                } hide-icon-input`}
                {...formik.getFieldProps("confirm_password")}
                error={
                  formik.errors.confirm_password &&
                  formik.touched.confirm_password
                }
                errorMessage={formik.errors.confirm_password}
              />
              {showConfirmPassword ? (
                <button
                  className={`hide-icon`}
                  onClick={(e) => handleShowConfirmPassword(e)}
                >
                  <span className="material-symbols-outlined">
                    {" "}
                    visibility_off{" "}
                  </span>
                </button>
              ) : (
                <button
                  className={`hide-icon`}
                  onClick={(e) => handleShowConfirmPassword(e)}
                >
                  <span className="material-symbols-outlined">
                    {" "}
                    visibility{" "}
                  </span>
                </button>
              )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-4">
            <div className={"input-wrap pro-mb-5"}>
              <label
                htmlFor="current_location"
                className="pro-font-sm pro-mb-1 pro-fw-medium"
              >
                Reason for change
              </label>
              <Select
                id="reset_reason_id"
                isClearable={true}
                menuPlacement={"auto"}
                placeholder={"Select"}
                isDisabled={
                  !(
                    hasUpdatePasswordPermission === 1 &&
                    overViewData?.data?.data?.assigned_profile === 1
                  )
                }
                className={"pro-input lg"}
                classNamePrefix="pro-input"
                options={optionsData?.password_change_reason}
                getOptionValue={(option) => option?._id}
                getOptionLabel={(option) => option?.reason}
                value={optionsData?.password_change_reason?.filter(
                  (m) => formik.values.reset_reason_id?._id === m?._id
                )}
                onChange={(value) => {
                  formik.setFieldValue("reset_reason_id", value || null);
                }}
              />
            </div>
            {formik.errors.reset_reason_id &&
              formik.touched.reset_reason_id && (
                <span className="error-text">
                  {formik.errors.reset_reason_id}
                </span>
              )}
          </div>
        </div>
      </>

      {overViewData?.data?.data?.status !== 3 &&
      overViewData?.data?.data?.status !== 2 ? (
        <div className="row">
          <div className="col-12">
            <HeadingGroup
              title={"Ban user"}
              buttonTitle={
                hasBanPermission === 1 &&
                overViewData?.data?.data?.status !== 3 &&
                overViewData?.data?.data?.status !== 2 &&
                overViewData?.data?.data?.assigned_profile === 1
                  ? "Update"
                  : ""
              }
              extraClassName={`pro-mb-4 ${Style.heading}`}
              handleClick={handleBanUserClick}
              buttonProps={{
                disabled: !(
                  formik.values.duration?.length > 0 &&
                  Object.keys(formik.values.reason || {})?.length > 0 &&
                  overViewData?.data?.data?.assigned_profile === 1
                ),
              }}
            ></HeadingGroup>
          </div>
          <div className="col-4">
            <Input
              label={"Reason"}
              type="text"
              id="reason"
              name="reason"
              disabled={
                !(
                  hasBanPermission === 1 &&
                  overViewData?.data?.data?.assigned_profile === 1
                )
              }
              className={`pro-input lg ${
                formik.errors.reason && formik.touched.reason && " error"
              }`}
              {...formik.getFieldProps("reason")}
              error={formik.errors.reason && formik.touched.reason}
              errorMessage={formik.errors.reason}
            />

            {/* <div className={"input-wrap pro-mb-5"}>
            <label
              htmlFor="current_location"
              className="pro-font-sm pro-mb-1 pro-fw-medium"
            >
              Reason
            </label>
            <Select
              id="reason_for_ban"
              isClearable={true}
              placeholder={"Select"}
              className={"pro-input lg"}
              classNamePrefix="pro-input"
              options={optionsData?.reason}
              getOptionValue={(option) => option?._id}
              getOptionLabel={(option) => option?.name}
              value={optionsData?.reason?.filter(
                (m) => formik.values.reason_for_ban?._id === m?._id
              )}
              onChange={(value) => {
                formik.setFieldValue("reason_for_ban", value || null);
              }}
            />
          </div>
          {formik.errors.reason_for_ban && formik.touched.reason_for_ban && (
            <span className="error-text">{formik.errors.reason_for_ban}</span>
          )} */}
          </div>

          <div className="col-4">
            <Input
              label={"Period"}
              type="text"
              id="duration"
              disabled={
                !(
                  hasBanPermission === 1 &&
                  overViewData?.data?.data?.assigned_profile === 1
                )
              }
              name="duration"
              placeholder="In Days"
              className={`pro-input lg ${
                formik.errors.duration && formik.touched.duration && " error"
              }`}
              {...formik.getFieldProps("duration")}
              onClick={() => formik.setFieldTouched("duration", true)}
              error={formik.errors.duration && formik.touched.duration}
              errorMessage={formik.errors.duration}
            />
          </div>
        </div>
      ) : (
        <></>
      )}

      {overViewData?.data?.data?.status !== 3 ? (
        <div className="row">
          <div className="col-12">
            <HeadingGroup
              title={"Delete user"}
              buttonTitle={
                hasDeletePermission === 1 &&
                overViewData?.data?.data?.status !== 3 &&
                overViewData?.data?.data?.assigned_profile === 1
                  ? "Delete"
                  : ""
              }
              extraClassName={`pro-mb-4 ${Style.heading}`}
              handleClick={handleDeleteUserClick}
              buttonProps={{
                disabled: !(
                  formik.values.staff_password?.length > 0 &&
                  Object.keys(formik.values.delete_reason_id || {})?.length >
                    0 &&
                  overViewData?.data?.data?.assigned_profile === 1
                ),
              }}
            ></HeadingGroup>
          </div>
          <div className="col-4">
            <div className={"input-wrap pro-mb-5"}>
              <label
                htmlFor="current_location"
                className="pro-font-sm pro-mb-1 pro-fw-medium"
              >
                Reason
              </label>
              <Select
                id="delete_reason_id"
                isClearable={true}
                menuPlacement={"auto"}
                placeholder={"Select"}
                className={"pro-input lg"}
                classNamePrefix="pro-input"
                isDisabled={
                  !(
                    hasDeletePermission === 1 &&
                    overViewData?.data?.data?.assigned_profile === 1
                  )
                }
                options={optionsData?.account_delete_reason}
                getOptionValue={(option) => option?._id}
                getOptionLabel={(option) => option?.name}
                value={optionsData?.account_delete_reason?.filter(
                  (m) => formik.values.delete_reason_id?._id === m?._id
                )}
                onChange={(value) => {
                  formik.setFieldValue("delete_reason_id", value || null);
                }}
              />
            </div>
            {formik.errors.delete_reason_id &&
              formik.touched.delete_reason_id && (
                <span className="error-text">
                  {formik.errors.delete_reason_id}
                </span>
              )}
          </div>

          <div className="col-4">
            <div className="p-re">
              <Input
                label={"Password"}
                type="password"
                id="staff_password"
                disabled={
                  !(
                    hasDeletePermission === 1 &&
                    overViewData?.data?.data?.assigned_profile === 1
                  )
                }
                autoComplete="new-password"
                name="staff_password"
                className={`pro-input lg ${
                  formik.errors.staff_password &&
                  formik.touched.staff_password &&
                  " error"
                } hide-icon-input`}
                {...formik.getFieldProps("staff_password")}
                error={
                  formik.errors.staff_password && formik.touched.staff_password
                }
                errorMessage={formik.errors.staff_password}
              />
              {showStaffPassword ? (
                <button
                  className={`hide-icon`}
                  onClick={(e) => handleShowStaffPassword(e)}
                >
                  <span className="material-symbols-outlined">
                    {" "}
                    visibility_off{" "}
                  </span>
                </button>
              ) : (
                <button
                  className={`hide-icon`}
                  onClick={(e) => handleShowStaffPassword(e)}
                >
                  <span className="material-symbols-outlined">
                    {" "}
                    visibility{" "}
                  </span>
                </button>
              )}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default RowTwo;
