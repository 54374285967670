import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };

    const getQuery = (params) => {
      return getParams(params) !== "" ? `&${getParams(params)}` : "";
    };

    try {
      let url =
        method !== "post"
          ? `${endpoint}?${getParams("start")}${getParams("end")}${getParams(
              "filter"
            )}${getParams("sort_by")}sort_order=${
              params?.sort_order || "desc"
            }&page_size=${params?.page_size || "10"}&${getParams(
              "search"
            )}page=${params?.page || 1}${getQuery("branch_id")}${getQuery(
              "type"
            )}${getQuery("diocese_id")}${getQuery("state_id")}`
          : `${endpoint}`;

      const response = await api[method](url, body);

      return { data: response.data };
    } catch (axiosError) {
      let err = axiosError;

      return {
        data: err?.response?.data || err.message,
      };
    }
  };

export const staff = createApi({
  reducerPath: "staffApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    "Staff",
    "States",
    "CreateStaffForm",
    "Roles",
    "Packages",
    "Adons",
    "Testimonials",
    "TestimonialsDeleteSingle",
    "TestimonialsDeleteMultiple",
    "FAQs",
    "Branches",
    "BranchesStaffs",
  ],
  endpoints: (builder) => ({
    getStaffListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/staffs`,
      }),
      providesTags: ["Staff"],
    }),

    updateStaffsListData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/user/fields/update`,
      }),

      invalidatesTags: ["Staff"],
    }),

    updateStaffDataByID: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/staffs/update`,
      }),

      invalidatesTags: ["Staff"],
    }),

    getCreateStaffFormData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/roles/all`,
      }),
      invalidatesTags: ["CreateStaffForm"],
    }),

    createStaff: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/staffs/register`,
      }),
      invalidatesTags: ["Staff"],
    }),
    getStaffRolesData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/roles`,
      }),
      providesTags: ["Roles"],
    }),
    getPackagesListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/packages`,
      }),
      providesTags: ["Packages"],
    }),

    updatePackagesListData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/user/fields/update`,
      }),

      invalidatesTags: ["Packages"],
    }),

    getAdonsListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/add-on`,
      }),
      providesTags: ["Adons"],
    }),

    getAdonsUpdateFieldsData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/user/fields/update`,
      }),

      invalidatesTags: ["Adons"],
    }),

    getTestimonialsListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/testimonial/list`,
      }),
      providesTags: ["Testimonials"],
    }),

    testimonialsDeleteSingle: builder.mutation({
      query: (values) => ({
        method: "delete",
        params: values,
        endpoint: `/admin/testimonial/delete`,
      }),
      invalidatesTags: ["Testimonials"],
    }),

    updateTestimonialsListData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/user/fields/update`,
      }),

      invalidatesTags: ["Testimonials"],
    }),

    testimonialsDeleteMultiple: builder.mutation({
      query: (values) => ({
        method: "post",
        params: values,
        endpoint: `/admin/testimonial/delete`,
      }),
      invalidatesTags: ["Testimonials"],
    }),

    getFAQsListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/faq/list`,
      }),
      providesTags: ["FAQs"],
    }),

    faqsDeleteSingle: builder.mutation({
      query: (values) => ({
        method: "delete",
        params: values,
        endpoint: `/admin/faq/delete`,
      }),
      invalidatesTags: ["FAQs"],
    }),

    faqsDeleteMultiple: builder.mutation({
      query: (values) => ({
        method: "delete",
        params: values,
        endpoint: `/admin/faq/delete`,
      }),
      invalidatesTags: ["Testimonials"],
    }),

    getBranchesListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/branches/list`,
      }),
      providesTags: ["Branches"],
    }),

    updateBranchesListData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/user/fields/update`,
      }),

      invalidatesTags: ["Branches"],
    }),

    createNewBranchData: builder.mutation({
      query: (formData) => ({
        method: "post",
        endpoint: `/admin/branches/create`,
        body: formData,
      }),
      invalidatesTags: ["Branches"],
    }),
    editBranchData: builder.mutation({
      query: (formData) => ({
        method: "put",
        endpoint: `/admin/branches/update`,
        body: formData,
      }),
      invalidatesTags: ["Branches"],
    }),

    // Branches
    getBranchViewDataByID: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/branches/view`,
      }),
      providesTags: ["BranchesSingle"],
    }),

    editBranchSingleData: builder.mutation({
      query: (formData) => ({
        method: "put",
        endpoint: `/admin/branches/update`,
        body: formData,
      }),
      invalidatesTags: ["BranchesSingle"],
    }),

    updateBranchSingleFieldsData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/user/fields/update`,
      }),

      invalidatesTags: ["BranchesSingle"],
    }),

    getStaffList: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/branches/staff/list` /*type=1,2*/,
      }),
      invalidatesTags: ["BranchesStaffs"],
    }),

    updateStaffOnBranches: builder.mutation({
      query: (formData) => ({
        method: "post",
        endpoint: `/admin/branches/staff/update`,
        body: formData,
      }),
      invalidatesTags: ["BranchesStaffs"],
    }),

    // Diocese

    getDioceseViewData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/diocese/list`,
      }),
      providesTags: ["Diocese"],
    }),

    getStatesViewData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/states`,
      }),
      providesTags: ["States"],
    }),

    getDioceseCommunityListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/members/create`,
      }),
      invalidatesTags: [],
    }),

    getDioceseViewDataByID: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/diocese/view`,
      }),
      invalidatesTags: [],
    }),
    getStatesViewDataByID: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/states/view`,
      }),
      invalidatesTags: [],
    }),

    updateDioceseFieldsData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/user/fields/update`,
      }),

      invalidatesTags: ["Diocese"],
    }),
    updateStatesFieldsData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/user/fields/update`,
      }),

      invalidatesTags: ["States"],
    }),
  }),
});

export const {
  useGetStaffListDataQuery,
  useGetStaffRolesDataQuery,
  useGetPackagesListDataQuery,
  useGetAdonsListDataQuery,
  useGetCreateStaffFormDataQuery,
  useCreateStaffMutation,
  useGetTestimonialsListDataQuery,
  useTestimonialsDeleteMultipleMutation,
  useTestimonialsDeleteSingleMutation,
  useGetFAQsListDataQuery,
  useFaqsDeleteSingleMutation,
  useFaqsDeleteMultipleMutation,
  useGetBranchesListDataQuery,
  useCreateNewBranchDataMutation,
  useEditBranchDataMutation,
  useGetBranchViewDataByIDQuery,
  useGetStaffListQuery,
  useUpdateStaffOnBranchesMutation,
  useGetDioceseViewDataQuery,
  useGetDioceseCommunityListDataQuery,
  useGetDioceseViewDataByIDQuery,
  useUpdateTestimonialsListDataMutation,
  useUpdatePackagesListDataMutation,
  useUpdateBranchesListDataMutation,
  useUpdateStaffsListDataMutation,
  useUpdateStaffDataByIDMutation,
  useUpdateBranchSingleFieldsDataMutation,
  useUpdateDioceseFieldsDataMutation,
  useEditBranchSingleDataMutation,
  useGetAdonsUpdateFieldsDataMutation,
  useGetStatesViewDataByIDQuery,
  useGetStatesViewDataQuery,
  useUpdateStatesFieldsDataMutation
} = staff;
