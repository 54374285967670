import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();

    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };

    try {
      const response = await api[method](
        `${endpoint}?${getParams("start")}${getParams("branch_id")}${getParams(
          "payment_type"
        )}${getParams("end")}filter=${params?.filter || "all"}&${getParams(
          "sort_by"
        )}sort_order=${params?.sort_order || "desc"}&page_size=${
          params?.page_size || "10"
        }&${getParams("search")}page=${params?.page || 1}`,
        body
      );

      return { data: response.data };
    } catch (axiosError) {
      let err = axiosError;

      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export const payment = createApi({
  reducerPath: "paymentApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["Transactions", "Followup"],
  endpoints: (builder) => ({
    getTransactionsListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/payments/list`,
      }),
      providesTags: ["Transactions"],
    }),
    addPayment: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/payments/create`,
      }),
      invalidatesTags: ["Transactions"],
    }),

    updatedPayment: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/payments/amount/update`,
      }),
      invalidatesTags: ["Transactions"],
    }),
    updateTransactionsListData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/user/fields/update`,
      }),
      invalidatesTags: ["Transactions"],
    }),

    getFollowupsListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/follow-up/list`,
      }),
      providesTags: ["Followup"],
    }),
    updateFollowupsFieldsUpdateData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/user/fields/update`,
      }),
      invalidatesTags: ["Followup"],
    }),
  }),
});

export const {
  useGetTransactionsListDataQuery,
  useUpdateTransactionsListDataMutation,
  useGetFollowupsListDataQuery,
  useUpdateFollowupsFieldsUpdateDataMutation,
  useAddPaymentMutation,
  useUpdatedPaymentMutation
} = payment;
