import { IconText } from "@wac-ui-dashboard/wac_component_library";
import React from "react";

export const PhotoRow = ({ data, onClick }) => {
  if (!data?.profile_images_count) {
    return "";
  }
  return (
    <IconText
      icon={<img src={data?.profile_image_status_icon ?? ""} alt={"img"} />}
      title={`${data?.profile_images_count} images`}
      onClick={onClick}
    />
  );
};

export const ProofRow = ({ data, onClick }) => {
  if (!data?.id_proof_count) {
    return "";
  }

  return (
    <IconText
      icon={<img src={data?.id_proof_status_icon ?? ""} alt={"img"} />}
      title={`${data?.id_proof_type}`}
      onClick={onClick}
    />
  );
};
