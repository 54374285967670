import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { updateConfig } from "../../../../store/slices/Settings/faqsSlice";
import { useSelector } from "react-redux";
import {
  addItemToTable,
  updateItemFromTableByID,
  updateConfig as updateTestimonialConfig,
} from "../../../../store/slices/Settings/testimonialsSlice";

const useAddBranchForm = ({ setShowform, refetch }) => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState("");
  const [imagePreview, setImagePreview] = useState("");

  const imageFileInputRef = useRef(null);

  const testimonials = useSelector((state) => state.testimonials);

  useEffect(() => {
    setImagePreview(
      testimonials?.selectedItemsDetails?.testimonial_image ?? ""
    );
  }, [testimonials]);

  const dispatch = useDispatch();

  var validationSchema =
    testimonials?.selectedItemsDetails === ""
      ? Yup.object({
          brideName: Yup.string().required("Required*"),
          groomName: Yup.string().required("Required*"),
          // dateOfMarrige: Yup.string().required("Required"),
          testimonialText: Yup.string().required("Required*"),
          image: Yup.string().required("Required*"),
        })
      : Yup.object({
          brideName: Yup.string().required("Required*"),
          groomName: Yup.string().required("Required*"),
          // dateOfMarrige: Yup.string().required("Required"),
          testimonialText: Yup.string().required("Required*"),
        });

  const [selectedOption, setSelectedOption] = useState(null);

  const formik = useFormik({
    initialValues: {
      brideName:
        testimonials?.selectedItemsDetails.bride_name !== ""
          ? testimonials?.selectedItemsDetails?.bride_name
          : "",
      groomName:
        testimonials?.selectedItemsDetails.groom_name !== ""
          ? testimonials?.selectedItemsDetails?.groom_name
          : "",
      dateOfMarrige:
        testimonials?.selectedItemsDetails.member_marriage_date !== ""
          ? testimonials?.selectedItemsDetails?.member_marriage_date
          : "",
      testimonialText:
        testimonials?.selectedItemsDetails.description !== ""
          ? testimonials?.selectedItemsDetails.description
          : "",
      image:
        testimonials?.selectedItemsDetails.image !== ""
          ? testimonials?.selectedItemsDetails?.image
          : "",
    },

    validationSchema: validationSchema,
    // enableReinitialize:true,
    onSubmit: (values, { resetForm, setFieldError }) => {
      handleFormSubmit(values, resetForm, setFieldError);
    },
  });

  const handleFormSubmit = async (values, resetForm, setFieldError) => {
    try {
      if (true) {
        try {
          let formData = new FormData();

          if (testimonials?.selectedItemsDetails === "") {
            formData.append("groom_name", values.groomName);
            formData.append("bride_name", values.brideName);
            formData.append("description", values.testimonialText);
            if (values.dateOfMarrige) {
              formData.append("marriage_date", values.dateOfMarrige);
            }

            formData.append("image", values.image);
          } else {
            formData.append("_method", "PUT");
            formData.append("groom_name", values.groomName);
            formData.append("bride_name", values.brideName);
            formData.append("description", values.testimonialText);
            if (values.dateOfMarrige) {
              formData.append("marriage_date", values.dateOfMarrige);
            }

            if (values.image !== "" && typeof values?.image !== "string") {
              formData.append("image", values.image);
            }

            formData.append(
              "testimonial_id",
              testimonials?.selectedItemsDetails._id
            );
          }

          dispatch(
            testimonials?.selectedItemsDetails === ""
              ? addItemToTable(formData)
              : updateItemFromTableByID(formData)
          )
            .unwrap()
            .then((data) => {
              setShowform(false);
              refetch();
              dispatch(
                updateTestimonialConfig((state) => {
                  state.clearSelection = true;
                })
              );
              toast.success("Successfully submitted");
            })
            .catch((err) => {
              if (err?.message?.groom_name?.[0] !== "") {
                setFieldError("groomName", err?.message?.groom_name?.[0]);
              }
              if (err?.message?.bride_name?.[0] !== "") {
                setFieldError("brideName", err?.message?.bride_name?.[0]);
              }
              if (err?.message?.description?.[0] !== "") {
                setFieldError(
                  "testimonialText",
                  err?.message?.description?.[0]
                );
              }
              if (err?.message?.image?.[0] !== "") {
                setFieldError("image", err?.message?.image?.[0]);
              }
              if (err?.message?.marriage_date?.[0] !== "") {
                setFieldError(
                  "dateOfMarrige",
                  err?.message?.marriage_date?.[0]
                );
              }
            });
        } catch (error) {}
      } else if (false) {
        /* try {
          let obj = {
            diocese_id: dioceseListingPage?.selectedDiocese._id,
            name: values.dioceseName,
            community_id: values.dioceseCommunity,
            shortcode: values.dioceseCode,
          };

          dispatch(updateDiocesesDetails(obj))
            .unwrap()
            .then((data) => {
              setShowform(false);
              refetchTableList();
              toast.success("Details updated Successfully.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            })
            .catch((err) => {
              if (err?.message?.shortcode?.[0] != "") {
                setFieldError("dioceseCode", err.message.shortcode[0]);
              }
            });
        } catch (error) {}*/
      }
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
  };

  const handleFileChange = (event) => {
    const files = event.currentTarget.files;

    if (files?.[0]?.size / (1024 * 1024) <= 5) {
      formik.setFieldValue("image", event.currentTarget.files?.[0]);

      const file = event.target.files[0];

      const reader = new FileReader();

      reader.onloadend = () => {
        setImagePreview(reader.result);
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    } else if (files?.[0]?.size / (1024 * 1024) > 5) {
      toast.error("The image must be less than 5MB in size.");
      formik.setFieldError("image", "The image must be less than 5MB in size.");
    }
  };

  return {
    setLoading,
    setOptions,
    formik,
    loading,
    handleCloseModal,
    imageFileInputRef,
    selectedOption,
    setSelectedOption,
    options,
    handleFileChange,
    imagePreview,
    testimonials,
  };
};

export default useAddBranchForm;
