import { useFormik } from "formik";
import * as Yup from "yup";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  useGetStaffListQuery,
  useGetBranchViewDataByIDQuery,
} from "../../../../store/queries/settings";
import { useSelector } from "react-redux";
import { saveStaffDetails,updateConfig } from "../../../../store/slices/Settings/branchesDetailsPageSlice";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const useAddBranchForm = ({setShowform,refetch}) => {
  const [errors, setErrors] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const imageFileInputRef = useRef(null);

  const branchesDetailsPage = useSelector((state) => state.branchesDetailsPage);

  const dispatch = useDispatch();
  const { branchID } = useParams();

  let validationSchema = Yup.object({
    selectedStaff: Yup.string().required("Required"),
  });

  const {
    refetch: refetchBranches,
  } = useGetBranchViewDataByIDQuery({
    branch_id: branchID,
    page_size: branchesDetailsPage.currentPageSize,
    page: branchesDetailsPage.currentPage,
    sort_by: branchesDetailsPage.sortBy,
    sort_order: branchesDetailsPage.sortOrder,
    start: branchesDetailsPage.startDate,
    end: branchesDetailsPage.endDate,
    search: branchesDetailsPage.search,
  });

  const {
    data: staffList = {}, 
    refetch : refetchStaffList,
  } = useGetStaffListQuery({ type: "1,2" });

  const optionsWithIcons = [];

  const formik = useFormik({
    initialValues: {
      selectedStaff: "",
    },

    validationSchema: validationSchema,

    onSubmit: (values, { resetForm, setFieldError }) => {
      handleFormSubmit(values, resetForm, setFieldError);
    },
  });

  const handleFormSubmit = async (values, resetForm, setFieldError) => {
    try {
      try {
        let obj = {
          branch_id: branchID,
          user_id: values.selectedStaff.split(","),
        };

        dispatch(saveStaffDetails(obj))
          .unwrap()
          .then((res) => {

            if (res?.data?.status_code === 200) {
              toast.success(res?.data?.message);
              refetch?.()
              dispatch(
                updateConfig((state) => {
                  state.clearSelection = true;
                })
              );
            }
            else{
              toast.error("Failed to Assign Staff!");
            }
            refetchBranches();
            refetchStaffList();
            setShowform(false);
          });
      } catch (error) {}
    } catch (error) {
      setErrors("Something went wrong!");
    }
  };
 

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
  };

  return {
    errors,
    message,
    setLoading,
    setMessage,
    formik,
    loading,
    handleCloseModal,
    imageFileInputRef,
    optionsWithIcons,
    staffList,
  };
};

export default useAddBranchForm;
