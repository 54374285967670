import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateFormData } from "../../../../../store/slices/Users/createMemberSlice";
import { updateConfig } from "../../../../../store/slices/Users/membersSlice";
import { updateConfig as updatincompleteConfig } from "../../../../../store/slices/Users/incompleteReg.Slice";
import { toast } from "react-toastify";
import {
  profile,
  useUpdateProfileDetailsMutation,
} from "../../../../../store/queries/Profile";
import { members } from "../../../../../store/queries/members";

const useFamily = ({ isOptionDataSuccess, isEditProfile, profileRefetch }) => {
  const { currentFormData, backendErrors } = useSelector(
    (state) => state.createMembers
  );

  const [sendRequest, { isLoading }] = useUpdateProfileDetailsMutation();

  const schema = Yup.object().shape({
    father_details: Yup.object().shape({
      name: Yup.string()
        .max(20, "max 20 characters allowed")
        .nullable()
        .matches(/^[a-zA-Z\s]*$/, "Only characters are allowed"),
      house_name: Yup.string().nullable(),
      occupation: Yup.object().nullable(),
      native_place: Yup.string().nullable(),
    }),
    mother_details: Yup.object().shape({
      name: Yup.string()
        .max(20, "max 20 characters allowed")
        .nullable()
        .matches(/^[a-zA-Z\s]*$/, "Only characters are allowed"),
      house_name: Yup.string().nullable(),
      occupation: Yup.object().nullable(),
      native_place: Yup.string().nullable(),
    }),
    sibling_details: Yup.object().shape({
      no_of_brothers: Yup.number()
        .typeError("no of brothers must be a number")
        .nullable()
        .integer(),
      brothers_married: Yup.number()
        .typeError("no of brothers married must be a number")
        .nullable()
        .integer()
        .test(
          "brothers-married-validation",
          "Number of brothers married should be less than or equal to the total number of brothers",
          function (value) {
            const { no_of_brothers, brothers_married } = this.parent;
            if (brothers_married === null || brothers_married === undefined) {
              return true;
            }
            return brothers_married <= no_of_brothers;
          }
        ),
      no_of_sisters: Yup.number()
        .typeError("no of sisters must be a number")
        .nullable()
        .integer(),
      sisters_married: Yup.number()
        .typeError("no of sisters married must be a number")
        .nullable()
        .integer()
        .test(
          "sisters-married-validation",
          "Number of sisters married should be less than or equal to the total number of sisters",
          function (value) {
            const { no_of_sisters, sisters_married } = this.parent;
            if (no_of_sisters === null || sisters_married === undefined) {
              return true;
            }
            return sisters_married <= no_of_sisters;
          }
        ),
    }),
    no_of_priests: Yup.number()
      .typeError("no of priests must be a number")
      .nullable()
      .integer(),
    no_of_nuns: Yup.number()
      .typeError("no of nuns must be a number")
      .nullable()
      .integer(),
  });

  const dispatch = useDispatch();

  let editRequiredData = {
    user_id: currentFormData?.user_id,
    first_name: currentFormData?.first_name,
    last_name: currentFormData?.last_name,
    email: currentFormData?.email,
    country_code: currentFormData?.country_code,
    phone_number: currentFormData?.phone_number,
    gender: currentFormData?.gender?.id,
    dob: currentFormData?.dob,
    community_id: currentFormData?.community_id?._id,
    diocese_id: currentFormData?.diocese_id?._id,
    
  };

  const formik = useFormik({
    initialValues: {
      father_details: {
        name: "",
        house_name: "",
        occupation: "",
         occupation_detail:"",
        native_place: "",
      },
      mother_details: {
        name: "",
        house_name: "",
        occupation: "",
         occupation_detail:"",
        native_place: "",
      },
      family_type: "",
      family_status: "",
      sibling_details: {
        no_of_brothers: "",
        brothers_married: "",
        no_of_sisters: "",
        sisters_married: "",
      },
      no_of_priests: "",
      no_of_nuns: "",
    },

    validationSchema: schema,
    enableReinitialize: true,

    onSubmit: (values, { resetForm }) => {
      try {
        let obj = {
          ...values,
        };

        let editObj = {
          "father_details[name]": values.father_details.name,
          "father_details[house_name]": values.father_details.house_name,
          "father_details[occupation]": values.father_details.occupation?._id,
          "father_details[occupation_detail]":
            values.father_details.occupation_detail,
          "father_details[native_place]": values.father_details.native_place,
          "mother_details[name]": values.mother_details.name,
          "mother_details[house_name]": values.mother_details.house_name,
          "mother_details[occupation]": values.mother_details.occupation?._id,
          "mother_details[occupation_detail]":
            values.mother_details.occupation_detail,
          "mother_details[native_place]": values.mother_details.native_place,
          family_type: values.family_type?.id,
          family_status: values.family_status?.id,
          "sibling_details[no_of_brothers]":
            values.sibling_details.no_of_brothers === ""
              ? ""
              : values.sibling_details.no_of_brothers,
          "sibling_details[brothers_married]":
            values.sibling_details.brothers_married === ""
              ? ""
              : values.sibling_details.brothers_married,
          "sibling_details[no_of_sisters]":
            values.sibling_details.no_of_sisters === ""
              ? ""
              : values.sibling_details.no_of_sisters,
          "sibling_details[sisters_married]":
            values.sibling_details.sisters_married === ""
              ? ""
              : values.sibling_details.sisters_married,
          no_of_priests:
            values.no_of_priests === "" ? "" : values.no_of_priests,
          no_of_nuns: values.no_of_nuns === "" ? "" : values.no_of_nuns,
          type:2
        };

        let newObj = Object.entries(editObj);

        newObj = newObj
          .filter(
            (item) =>
              item[1] !== undefined && item[1] !== "" && item[1] !== null
          )
          .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {});

        if (isEditProfile) {
          let formData = new FormData();
          formData.append("_method", "PUT");

          Object.keys(newObj).forEach((key) => {
            return formData.append(key, newObj[key]);
          });
          Object.keys(editRequiredData).forEach((key) => {
            return formData.append(key, editRequiredData[key]);
          });

          sendRequest(formData).then((response) => {
            dispatch(
              updateFormData((state) => {
                state.backendErrors = "";
              })
            );

            if (response?.data?.status_code === 200) {
              resetForm();

              dispatch(
                updateFormData((state) => {
                  state.currentFormData = {};
                  state.activeTab = "Basic";
                  state.isEditProfile = false;
                  state.isUpdateProfile=false;
                  state.selectedUser="";
                  state.updatedata="";
                  state.completedTabs = {
                    ...state.completedTabs,
                    Basic: false,
                    Family: false,
                    Job: false,
                    Edu: false,
                    Contact: false,
                  };
                  state.CompletedPercentage = {
                    ...state.CompletedPercentage,
                    Basic: 0,
                    Family: 0,
                    Job: 0,
                    Edu: 0,
                    Contact: 0,
                  };
                })
              );

              dispatch(
                updateConfig((state) => {
                  state.showCreateModal = false;
                  state.clearSelection = true;
                })
              );
              dispatch(
                updatincompleteConfig((state) => {
                  state.showCreateModal = false;
                  state.clearSelection = true;
                })
              );
              dispatch(members.util.invalidateTags(["Members", "Incomplete"]));
              dispatch(profile.util.invalidateTags(["Overview"]));

              toast.success("Successfully submitted");
            } else if (response?.data?.status_code === 400) {
              let message = Object.values(response?.data?.message)?.[0]?.[0];

              ;
              toast.error(`Failed to  update, ${message}`);
              formik.setErrors(response?.data?.message);
              dispatch(
                updateFormData((state) => {
                  state.backendErrors = response?.data?.message;
                })
              );
            } else {
              toast.error("Failed to  update");
            }
          });
        } else {
          dispatch(
            updateFormData((state) => {
              state.currentFormData = { ...state.currentFormData, ...obj };
              state.activeTab = "Edu";
              state.completedTabs = { ...state.completedTabs, Family: true };
            })
          );
        }
      } catch (error) {
        toast.error("Failed to  submit");
      }
    },
  });

  useEffect(() => {
    let totalFields = Object.values(formik.initialValues).length;

    const filterDataFromTab = (fullObject, tabObject) => {
      const filteredData = {};

      for (const key in tabObject) {
        if (fullObject.hasOwnProperty(key)) {
          filteredData[key] = fullObject[key];
        }
      }

      return filteredData;
    };

    const filteredData = filterDataFromTab(formik.values, formik.initialValues);

    const countNonEmptyKeys = (data) => {
      let count = 0;
      for (let key in data) {
        if (
          data.hasOwnProperty(key) &&
          data[key] !== null &&
          data[key] !== ""
        ) {
          count++;
        }
      }
      return count;
    };

    let numberOfNonEmptyKeys = countNonEmptyKeys(filteredData);

    dispatch(
      updateFormData((state) => {
        state.CompletedPercentage = {
          ...state.CompletedPercentage,
          Family: Math.ceil((numberOfNonEmptyKeys / totalFields) * 100),
        };
      })
    );
    // eslint-disable-next-line
  }, [formik.values]);

  useEffect(() => {
    if (isOptionDataSuccess) {
      let newSetObj = Object.entries(currentFormData);

      newSetObj = newSetObj
        .filter(
          (item) => item[1] !== undefined && item[1] !== "" && item[1] !== null
        )
        .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {});

      Object.keys(newSetObj || {}).forEach((key) => {
        formik.setFieldValue(key, newSetObj?.[key]);
      });

      let filteredTouchedData = Object.entries(currentFormData || {});

      filteredTouchedData = filteredTouchedData
        .filter(
          (item) => item[1] === undefined || item[1] === "" || item[1] === null
        )
        .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {});

      Object.keys(filteredTouchedData || {}).forEach((key) => {
        formik.setFieldTouched(key, true);
      });
    }
    // eslint-disable-next-line
  }, [isOptionDataSuccess, currentFormData]);

  useEffect(() => {
    if (backendErrors) {
      formik.setErrors(backendErrors);
    }
    // eslint-disable-next-line
  }, [backendErrors]);

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
    dispatch(
      updatincompleteConfig((state) => {
        state.showCreateModal = false;
      })
    );
    dispatch(
      updateFormData((state) => {
        state.currentFormData = {};
        state.activeTab = "Basic";
        state.isEditProfile = false;
        state.backendErrors = "";
        state.phoneVerified = {
          verified: false,
          status: false,
        };
        state.completedTabs = {
          ...state.completedTabs,
          Basic: false,
          Family: false,
          Job: false,
          Edu: false,
          Contact: false,
        };
        state.CompletedPercentage = {
          ...state.CompletedPercentage,
          Basic: 0,
          Family: 0,
          Job: 0,
          Edu: 0,
          Contact: 0,
        };
      })
    );
  };

  const getFieldError = (fieldName) => {
    const fieldNames = fieldName.split(".");

    if (
      formik.touched[fieldNames?.[0]]?.[fieldNames?.[1]] &&
      formik.errors[fieldNames?.[0]]?.[fieldNames?.[1]]
    ) {
      return formik.errors[fieldNames?.[0]]?.[fieldNames?.[1]];
    }

    return "";
  };

  // const getFieldError = (fieldName) => {
  //   if (backendErrors && backendErrors[fieldName]) {
  //     return backendErrors[fieldName];
  //   }
  //   if (formik.errors[fieldName]) {
  //     return formik.errors[fieldName];
  //   }
  //   return "";
  // };

  return {
    formik,
    isLoading,
    currentFormData,
    handleCloseModal,
    getFieldError,
  };
};

export default useFamily;
