import {
  Table,
  HeadingGroup,
  Pagination,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { FaSort } from "react-icons/fa";
import useProfilePayment from "./useProfilePayment";
import AddPaymentForm from "../../Payment/AddPaymentForm";
import EmptyData from "../../Global/EmptyData";

const ProfilePayment = () => {
  const {
    profilePaymentList,
    profilePaymentState,
    currentPage,
    paginationOptions,
    isFetching,
    activeProfile,
    hasCreatePermission,
    overViewData,
    refetch,
    handlePagination,
    handlePageSize,
    handleSort,
    getRow,
  } = useProfilePayment();

  return (
    <>
      <div className={`pro-m-4 pro-mx-5`}>
        {hasCreatePermission === 1 &&
        overViewData?.data?.data?.assigned_profile === 1 ? (
          <>
            <AddPaymentForm
              profilePayment={true}
              activeProfile={activeProfile}
              refetch={refetch}
            />
            <hr />
            <div className="pro-w-100 pro-pb-4"></div>
          </>
        ) : (
          <></>
        )}

        <HeadingGroup
          title={"Payment History"}
          extraClassName={`pro-pt-4`}
        ></HeadingGroup>
        <div className={`pro-pt-3 pro-pb-6 `}>
          {profilePaymentList?.data?.data?.length === 0 ? (
            <EmptyData />
          ) : (
            <Table
              data={profilePaymentList?.data?.data || []}
              uniqueID={"transaction_id"}
              fields={profilePaymentList?.data?.fields}
              showCheckBox={false}
              SortIcon={<FaSort />}
              editIcon={<span className="material-symbols-outlined">edit</span>}
              deleteIcon={
                <span className="material-symbols-outlined">delete</span>
              }
              handleSort={handleSort}
              getRow={getRow}
              loading={isFetching}
              perpage={profilePaymentState?.currentPageSize}
              assignable={false}
            />
          )}

          {profilePaymentList?.data?.data?.length > 0 && (
            <Pagination
              currentPage={currentPage}
              totalPageCount={Math.ceil(
                profilePaymentList?.data?.total /
                  profilePaymentState.currentPageSize
              )}
              defaultValue={paginationOptions?.filter(
                (item) => item.value === profilePaymentState?.currentPageSize
              )}
              onPageChange={handlePagination}
              options={paginationOptions}
              onActionChange={handlePageSize}
              center
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ProfilePayment;
