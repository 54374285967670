import { useDispatch, useSelector } from "react-redux";
import {
  useGetDioceseViewDataByIDQuery,
  useGetStaffListQuery,
  useUpdateDioceseFieldsDataMutation,
} from "../../../store/queries/settings";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import {
  updateConfig,
  editDiocese,
  selectDiocese,
  deleteParishFromDiocese,
} from "../../../store/slices/Settings/dioceseSinglePageSlice";
import { getFormatedDate } from "../../../utils/functions/table";
import { useEffect, useMemo, useState } from "react";
import { AdminRow } from "./Rows";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getExportedData } from "./api";
import moment from "moment";

const useDioceseSingle = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dioceseSinglePage = useSelector((state) => state.dioceseSinglePage);

  const { showEditModal } = useSelector((state) => state.global);

  const [showTableDeleteModal, setShowTableDeleteModal] = useState(false);
  const [selectedIDs, setSelectedIDs] = useState("");
  const [showform, setShowform] = useState(false);

  const { dioceseID } = useParams();

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const [updateDioceseSingleFields] = useUpdateDioceseFieldsDataMutation();

  useEffect(() => {
    if (showform === false) {
      dispatch(selectDiocese(""));
      dispatch(editDiocese(false));
    }
    // eslint-disable-next-line
  }, [showform]);

  useEffect(() => {
    if (dioceseSinglePage.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [dioceseSinglePage.clearSelection]);

  const {
    data: dioceseSinglePageList = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetDioceseViewDataByIDQuery({
    diocese_id: dioceseID,
    page_size: dioceseSinglePage.currentPageSize,
    page: dioceseSinglePage.currentPage,
    sort_by: dioceseSinglePage.sortBy,
    sort_order: dioceseSinglePage.sortOrder,
    start: dioceseSinglePage.startDate,
    end: dioceseSinglePage.endDate,
    search: dioceseSinglePage.search,
  });

  const hasExportPermission = useMemo(() => {
    let permission = dioceseSinglePageList?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_export")
    );

    return permission?.[0]?.can_export ?? 0;
  }, [dioceseSinglePageList]);

  const hasCreatePermission = useMemo(() => {
    let permission = dioceseSinglePageList?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_create")
    );

    return permission?.[0]?.can_create ?? 0;
  }, [dioceseSinglePageList]);

  
  

  const { refetch: refetchStaffList } = useGetStaffListQuery({ type: "1,2" });

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.menuDrawer = {
          show: true,
          backTo: "/configure/diocese",
          text: "Back to Diocese",
        };
      })
    );

    return () => {
      dispatch(
        globalUpdateConfig((state) => {
          state.menuDrawer = {};
        })
      );
    };
    // eslint-disable-next-line
  }, []);

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleDateChange = (range) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = getFormatedDate(range.startDate);
        state.endDate = getFormatedDate(range.endDate);
      })
    );
  };

  const handleDelete = async (ids) => {
    setShowTableDeleteModal(true);
    setSelectedIDs(ids[0]);
  };

  const ActionDelete = async (ids) => {
    dispatch(deleteParishFromDiocese({ parish_id: [selectedIDs] }))
      .unwrap()
      .then((res) => {
        if (res?.data?.status_code === true) {
          refetch();
          dispatch(
            updateConfig((state) => {
              state.clearSelection = true;
            })
          );
          refetchStaffList();
          toast.success("Deleted Successfully");
        } else {
          toast.error("Failed to Delete parish!");
        }
      });
    setShowTableDeleteModal(false);
    setSelectedIDs("");
  };

  const handleSort = (label) => {
    if (dioceseSinglePage.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder =
            dioceseSinglePage.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleEditClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };

  const handleEditBranch = (branchID) => {
    let data = dioceseSinglePageList.data.data.filter((x) => {
      return x.branch_id === branchID;
    });

    if (data.length > 0) {
      dispatch(selectDiocese(data[0]));
      dispatch(editDiocese(true));
      setShowform(true);
    }
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      staff_name: (feild, data) => <AdminRow data={data} onClick={() => {}} />,
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const closeTableEditModal = () => {
    setSelectedIDs("");
    setShowTableDeleteModal(false);
  };

  const handleClearClick = () => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentPageSize = 10;
        state.sortBy = "";
        state.sortOrder = "";
        state.data = [];
        state.showCreateModal = false;
        state.selectedDiocese = "";
        state.editDiocese = false;
        state.preSelectedCommunity = null;
        state.search = "";
      })
    );
  };
  let actionOptions = [
    { label: "Edit Columns", value: 0 },
    { label: "Export view", value: 1 },
  ];

  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    } else {
      getExportedData({
        fields: Object.keys(dioceseSinglePageList?.data?.fields),
        filter: dioceseSinglePage.currentFilter,
        sort_by: dioceseSinglePage.sortBy,
        sort_order: dioceseSinglePage.sortOrder,
        start: dioceseSinglePage.startDate,
        end: dioceseSinglePage.endDate,
        search: dioceseSinglePage.search,
      }).then((response) => {
        let dataType = response?.data?.type;
        let binaryData = [];
        binaryData.push(response?.data);
        let downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(
          new Blob(binaryData, { type: dataType })
        );
        downloadLink.setAttribute(
          "download",
          `${moment(Date.now()).format("MM_DD_YYYY_HH_mm_ss")}.xlsx`
        );
        document.body.appendChild(downloadLink);
        downloadLink.click();
      });
    }
  };

  const handleLinkClick = (item) => {
    if (item === "Diocese") {
      navigate(`/configure/diocese`);
    }
  };

  return {
    handleClearClick,
    dioceseSinglePageList,
    dioceseSinglePage,
    currentPage: dioceseSinglePage.currentPage,
    paginationOptions,
    isFetching,
    isLoading,
    actionOptions,
    hasExportPermission,
    hasCreatePermission,
    handleActionChange,
    handlePagination,
    handleDateChange,
    handlePageSize,
    handleSort,
    handleSearch,
    getRow,
    handleEditClick,
    closeEditModal,
    showEditModal,
    refetch,
    handleDelete,
    closeTableEditModal,
    showTableDeleteModal,
    ActionDelete,
    showform,
    setShowform,
    handleEditBranch,
    handleLinkClick,
    updateDioceseSingleFields,
  };
};

export default useDioceseSingle;
