import React from "react";
import Style from "./userCard.module.scss";
import PREMIUM from "./tick.svg";
import { Image } from "@wac-ui-dashboard/wac_component_library";
import Assets from "../../../assets/Assets";
export function UserCard({
  src,
  width,
  height,
  alt,
  title,
  age,
  data,
  shortlisteCard = false,
  isShortlisted,
  checked,
  handleSelect,
  subtitle,
  ViewIcon,
  ViewCount,
  graphIcon,
  GraphPercentage,
  isCheckBox,
  isPremium,
  lastElement,
  handleProfileRedirect,
}) {
  return (
    <div
      className={`${Style.root} ${data?.is_deleted ? Style.unAvailable : ""} ${
        data?.is_deleted && shortlisteCard ? Style.shortlist : ""
      }`}
      ref={lastElement || null}
    >
      <div className={`${Style.root_inner}`}>
        <div
          className={`${Style.root_image}`}
          onClick={(e) =>
            handleProfileRedirect(data?.member_id, data?.profile_view_status)
          }
        >
          <Image
            src={
              data?.profile_image ??
              (data?.gender?.name === "Female"
                ? Assets.FEMALEPROFILE
                : Assets.MALEPROFILE)
            }
            width={width || 100}
            height={height || 100}
            alt={alt || `user image`}
          />
        </div>

        <div className={`${Style.root_text_wrapper}`}>
          <div
            className={`${Style.root_text} pro-mb-2 pro-px-1 pro-pt-2`}
            onClick={(e) =>
              handleProfileRedirect(data?.member_id, data?.profile_view_status)
            }
          >
            <p className="pro-fw-bolder pro-mb-0">
              {`${title} (${age})` || ``}

              {isPremium && (
                <span className={Style.premium}>
                  <img src={PREMIUM} alt="icon" />
                  <span className="material-symbols-outlined"> done </span>
                </span>
              )}
            </p>
            <p className="pro-fw-regular pro-mb-0">
              {data?.occupation || `Not specified`}
            </p>
          </div>

          <div className={`${Style.review} pro-px-2 pro-mb-1 pro-mt-auto `}>
            <ul className={`pro-d-flex pro-flex-wrap`}>
              {ViewIcon && ViewCount !== null ? (
                <li
                  className={`pro-d-flex pro-items-center pro-badge badge-grey-outline pro-px-1 pro-py-0 pro-my-1`}
                >
                  <span className={`pro-d-flex`}>{ViewIcon}</span>
                  <span className="pro-fw-medium pro-font-xs pro-mb">
                    {ViewCount}
                  </span>
                </li>
              ) : (
                <></>
              )}
              {graphIcon && GraphPercentage !== null ? (
                <li
                  className={`pro-d-flex pro-items-center pro-badge badge-grey-outline pro-px-1 pro-py-0 pro-my-1`}
                >
                  <span className={`pro-d-flex`}>{graphIcon}</span>
                  <span className="pro-fw-medium pro-font-xs pro-mb">
                    {GraphPercentage}
                  </span>
                </li>
              ) : (
                <></>
              )}
            </ul>
          </div>

          {isCheckBox && (
            <div
              className={`pro-check-box rounded-tick ${
                !isShortlisted ? Style.input_hover : ""
              }`}
            >
              <input
                type="checkbox"
                className={`pro-check`}
                id="checkbox"
                name="pro-checkbox"
                checked={checked?.includes(data?.member_id)}
                onClick={(e) => e.stopPropagation()}
                onChange={(e) =>
                  handleSelect(
                    data?.member_id,
                    e.target.checked
                    // data?.first_name
                  )
                }
              />
            </div>
          )}

          {data?.is_deleted ? (
            <div className={`pro-badge badge-danger ${Style.badge}`}>
              Unavailable
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}
