import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../../api";

const initialState = {
  currentPage: 1,
  currentPageSize: 20,
  sortBy: "",
  sortOrder: "desc",
  activityData: {},
  activityStatus: "idle",
  activityChartData: {},
  activityChartStatus: "idle",
};
const cache = {};

export const getActivityChartData = createAsyncThunk(
  "followUp/getActivityChartData",
  async (params, { rejectWithValue }) => {
    const cacheKey = JSON.stringify(params);

    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };

    if (cache[cacheKey]) {
      return cache[cacheKey];
    }
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `/admin/members/activities/graph?${getParams("member_id")}${getParams(
          "profile_view_month"
        )}${getParams("profile_view_year")}${getParams(
          "interest_month"
        )}${getParams("interest_year")}${getParams("contact_year")}${getParams(
          "contact_month"
        )}`
      );
      cache[cacheKey] = response;
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getActivityData = createAsyncThunk(
  "followUp/getActivityData",
  async (params, { rejectWithValue }) => {
    const cacheKey = JSON.stringify(params);

    if (cache[cacheKey]) {
      return cache[cacheKey];
    }
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `admin/members/profile/activities?member_id=${params.member_id}&page_size=${params?.per_page}&page=${params?.page}&filter_days=${params?.filter_days}`
      );
      cache[cacheKey] = response;
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const ProfileActivitySlice = createSlice({
  name: "ProfileActivity",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: {
    [getActivityData.pending]: (state, action) => {
      state.activityStatus = "pending";
    },
    [getActivityData.fulfilled]: (state, action) => {
      if (
        state.activityData?.[action.meta?.arg?.member_id] &&
        !action.meta?.arg?.clearState
      ) {
        if (action.payload?.data?.data?.data) {
          state.activityData[action.meta?.arg?.member_id] = {
            ...action.payload?.data?.data,
            activityStatus: "fulfilled",
            data: [
              ...state.activityData[action.meta?.arg?.member_id].data,
              ...action.payload?.data?.data?.data,
            ],
          };
        } else {
          state.activityData[action.meta?.arg?.member_id] = {
            ...action.payload?.data?.data,
            activityStatus: "fulfilled",
            data: [...state.activityData[action.meta?.arg?.member_id].data],
          };
        }
      } else {
        state.activityData[action.meta?.arg?.member_id] = {
          ...action.payload?.data?.data,
          activityStatus: "fulfilled",
          page: 1,
        };
      }
      state.activityStatus = "fulfilled";
    },
    [getActivityData.rejected]: (state, action) => {
      state.activityStatus = "failed";
    },
    [getActivityChartData.pending]: (state, action) => {
      state.activityChartStatus = "pending";
    },
    [getActivityChartData.fulfilled]: (state, action) => {
      state.activityChartData[action.meta?.arg?.member_id] = {
        ...action.payload?.data,
        activityChartStatus : "fulfilled"
      };
      state.activityChartStatus = "fulfilled";
    },
    [getActivityChartData.rejected]: (state, action) => {
      state.activityChartStatus = "failed";
    },
  },
});

export const { updateConfig } = ProfileActivitySlice.actions;

export default ProfileActivitySlice.reducer;
