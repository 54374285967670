import { IconText } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { Link } from "react-router-dom";

export const AdminRow = ({ data, onClick }) => {
  if (!data?.staff_name.name) {
    return "";
  }
  return (
    <IconText
      icon={<img src={data?.staff_name?.image_url ?? ""} alt={"img"} />}
      title={`${data?.staff_name?.name} images`}
      onClick={onClick}
    />
  );
};

export const BranchName = ({ data, onClick ,hasViewPermission}) => {
  if (!data?.name) {
    return "";
  }
  return hasViewPermission === 1 ? (
    <p className="pro-mb-0">
      {" "}
      <Link className={"pro-pnt"} to={`single/${data?.branch_id}`}>
        {data?.name}
      </Link>{" "}
    </p>
  ) : (
    <p className="pro-mb-0"> {data?.name} </p>
  );
};
