import { useDispatch, useSelector } from "react-redux";

import {
  useGetBranchesListDataQuery,
  useUpdateBranchesListDataMutation,
} from "../../../store/queries/settings";

import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import {
  updateConfig,
  selectBranch,
  editBranch,
} from "../../../store/slices/Settings/branchesSlice";
import { getFormatedDate } from "../../../utils/functions/table";
import { useEffect, useMemo, useState } from "react";
import { AdminRow, BranchName } from "./Rows";
import moment from "moment";
import { getExportedData } from "./api";

const useBranches = () => {
  const dispatch = useDispatch();

  const branches = useSelector((state) => state.branches);

  const { showEditModal } = useSelector((state) => state.global);

  const [showTableDeleteModal, setShowTableDeleteModal] = useState(false);
  const [selectedIDs, setSelectedIDs] = useState("");
  const [showform, setShowform] = useState(false);

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  useEffect(() => {
    if (showform === false) {
      dispatch(selectBranch(""));
      dispatch(editBranch(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showform]);

  useEffect(() => {
    if (branches.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [branches.clearSelection]);

  const [updateBranchesFields] = useUpdateBranchesListDataMutation();

  const {
    data: branchesList = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetBranchesListDataQuery({
    page_size: branches.currentPageSize,
    page: branches.currentPage,
    sort_by: branches.sortBy,
    sort_order: branches.sortOrder,
    start: branches.startDate,
    end: branches.endDate,
    search: branches.search,
  });

  const hasExportPermission = useMemo(() => {
    let permission = branchesList?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_export")
    );

    return permission?.[0]?.can_export ?? 0;
  }, [branchesList]);
  
  const hasCreatePermission = useMemo(() => {
    let permission = branchesList?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_create")
    );

    return permission?.[0]?.can_create ?? 0;
  }, [branchesList]);

  const hasEditPermission = useMemo(() => {
    let permission = branchesList?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_edit")
    );

    return permission?.[0]?.can_edit ?? 0;
  }, [branchesList]);

  const hasUpdatePermission = useMemo(() => {
    let permission = branchesList?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_update")
    );

    return permission?.[0]?.can_update ?? 0;
  }, [branchesList]);

  const hasViewPermission = useMemo(() => {
    let permission = branchesList?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_view")
    );

    return permission?.[0]?.can_view ?? 0;
  }, [branchesList]);


  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleDateChange = (range) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = getFormatedDate(range.startDate);
        state.endDate = getFormatedDate(range.endDate);
      })
    );
  };

  const handleDelete = async (ids) => {
    setShowTableDeleteModal(true);
    setSelectedIDs(ids[0]);
  };

  const ActionDelete = async (ids) => {
    setShowTableDeleteModal(false);
    setSelectedIDs("");
  };

  const handleSort = (label) => {
    if (branches.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = branches.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleEditClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };

  const handleEditBranch = (branchID) => {
    let data = branchesList.data.data.filter((x) => {
      return x.branch_id === branchID[0];
    });

    if (data.length > 0) {
      dispatch(selectBranch(data[0]));
      dispatch(editBranch(true));
      setShowform(true);
    }
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      staff_name: (feild, data) => <AdminRow data={data} onClick={() => {}} />,
      name: (feild, data) => <BranchName data={data} hasViewPermission={hasViewPermission} onClick={() => {}} />,
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const closeTableEditModal = () => {
    setSelectedIDs("");
    setShowTableDeleteModal(false);
  };
  let actionOptions = [
    { label: "Edit Columns", value: 0 },
    { label: "Export view", value: 1 },
  ];

  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    } else {
      getExportedData({
        fields: Object.keys(branchesList?.data?.fields),
        filter: branches.currentFilter,
        sort_by: branches.sortBy,
        sort_order: branches.sortOrder,
        start: branches.startDate,
        end: branches.endDate,
        search: branches.search,
      }).then((response) => {
        let dataType = response?.data?.type;
        let binaryData = [];
        binaryData.push(response?.data);
        let downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(
          new Blob(binaryData, { type: dataType })
        );
        downloadLink.setAttribute(
          "download",
          `${moment(Date.now()).format("MM_DD_YYYY_HH_mm_ss")}.xlsx`
        );
        document.body.appendChild(downloadLink);
        downloadLink.click();
      });
    }
  };

  const handleClearClick = () => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentPageSize = 10;
        state.sortBy = "";
        state.sortOrder = "";
        state.data = [];
        state.showCreateModal = false;
        state.selectedBranch = "";
        state.editBranch = false;
        state.search = "";
      })
    );
  };

  return {
    branchesList,
    handleClearClick,
    branches,
    currentPage: branches.currentPage,
    paginationOptions,
    isFetching,
    isLoading,
    hasExportPermission,
    hasCreatePermission,
    hasEditPermission,
    hasUpdatePermission,
    handlePagination,
    handleDateChange,
    handlePageSize,
    handleSort,
    actionOptions,
    handleActionChange,
    handleSearch,
    getRow,
    handleEditClick,
    closeEditModal,
    showEditModal,
    refetch,
    handleDelete,
    closeTableEditModal,
    showTableDeleteModal,
    ActionDelete,
    showform,
    setShowform,
    handleEditBranch,
    selectedIDs,
    setSelectedIDs,
    updateBranchesFields,
  };
};

export default useBranches;
