import { useEffect, useState } from "react";
import { useGetPartnerDashboardDataQuery } from "../../../store/queries/Dashboard";

import ChartData from "../ChartData";

// import { useSearchParams } from "react-router-dom";

const usePartnerDashboardListing = () => {

  const {
    data: dashboardData = {},
    isFetching,
    isSuccess,
  } = useGetPartnerDashboardDataQuery({});

  const [listingType, setListingType] = useState();

  useEffect(() => {
    if (isSuccess) {
      setListingType(Object.keys(dashboardData?.data || {})?.[0] ?? "");
    }

    //eslint-disable-next-line
  }, [isSuccess]);

  const handlTotalUsers = () => {
    setListingType("dir_total_users");
  };
  const handlePaidUsers = () => {
    setListingType("dir_paid_users");
  };

  const handleFreeUsers = () => {
    setListingType("dir_free_users");
  };
  const handleNewUsers = () => {
    setListingType("dir_new_users");
  };
  const handleTotalPayments = () => {
    setListingType("dir_total_payments");
  };
  const handleTodayPayments = () => {
    setListingType("dir_today_payments");
  };
  const handleWeeklyPayments = () => {
    setListingType("dir_weekly_payments");
  };
  const handleMonthlyPayments = () => {
    setListingType("dir_monthly_payments");
  };

  let object = {
    dir_total_users: handlTotalUsers,
    dir_paid_users: handlePaidUsers,
    dir_free_users: handleFreeUsers,
    dir_new_users: handleNewUsers,
    dir_total_payments: handleTotalPayments,
    dir_today_payments: handleTodayPayments,
    dir_weekly_payments: handleWeeklyPayments,
    dir_monthly_payments: handleMonthlyPayments,
  };

  const handleClick = (label) => {
    object[label]();
  };

  let dashboardItems = {
    dir_total_users: {
      label: "dir_total_users",
      title: "Total Users",
      icon: "group",
      handleClick,
      component: (
        <ChartData dashboard={true} chartType={"total_users"} type={1} />
      ),
    },
    dir_paid_users: {
      label: "dir_paid_users",
      title: "Paid users",
      icon: "paid",
      handleClick,
      component: (
        <ChartData dashboard={true} chartType={"paid_users"} type={1} />
      ),
    },
    dir_free_users: {
      label: "dir_free_users",
      title: "Free users",
      icon: "group",
      handleClick,
      component: (
        <ChartData dashboard={true} chartType={"free_users"} type={1} />
      ),
    },
    dir_new_users: {
      label: "dir_new_users",
      title: "New users",
      icon: "group_add",
      handleClick,
      component: (
        <ChartData dashboard={true} chartType={"new_users"} type={1} />
      ),
    },
    dir_total_payments: {
      label: "dir_total_payments",
      title: "Total Payments",
      icon: "payments",
      handleClick,
      component: (
        <ChartData dashboard={true} chartType={"total_payments"} type={2} />
      ),
    },
    dir_today_payments: {
      label: "dir_today_payments",
      title: "Today Payments",
      icon: "savings",
      handleClick,
      component: (
        <ChartData dashboard={true} chartType={"today_payments"} type={2} />
      ),
    },
    dir_weekly_payments: {
      label: "dir_weekly_payments",
      title: "Weekly Payments",
      icon: "account_balance_wallet",
      handleClick,
      component: (
        <ChartData dashboard={true} chartType={"weekly_payments"} type={2} />
      ),
    },
    dir_monthly_payments: {
      label: "dir_monthly_payments",
      title: "Monthly Payments",
      icon: "savings",
      handleClick,
      component: (
        <ChartData dashboard={true} chartType={"monthly_payments"} type={2} />
      ),
    },
  };



  return {
    dashboardItems,
    dashboardData,
    isFetching,
    children: dashboardItems[listingType]?.component,
    listingType,
  };
};

export default usePartnerDashboardListing;
