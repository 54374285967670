import { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import { useDispatch } from "react-redux";
import { getDashboardChartData } from "../../../../store/slices/Global";

const usePartnerDashboardChart = ({
  datasets,
  labels,
  chartData,
  state,
  type,
  chartType,
}) => {
  const dispatch = useDispatch();
  const chartRef = useRef(null);

  useEffect(() => {
    if (Object.keys(state?.dashboardChartData[chartType] ?? {})?.length === 0) {
      dispatch(
        getDashboardChartData({
          type: chartType,
          typeApi: type,
        })
      );
    }
    //eslint-disable-next-line
  }, [chartType]);

  useEffect(() => {
    const chartOptions = {
      type: "bar",
      data: {
        labels: labels,
        datasets: datasets,
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: false,
            position: "top",
            labels: {
              usePointStyle: true,
            },
          },

          tooltip: {
            callbacks: {
              label: function (context) {
                let label = context.dataset.label || "";
                if (label) {
                  label += ": ";
                }
                if (type === 1) {
                  label += new Intl.NumberFormat("en-US", {
                    style: "decimal",
                  }).format(context.parsed.y);
                } else {
                  label += new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "INR",
                  }).format(context.parsed.y);
                }

                return label;
              },
            },
          },
        },

        scales: {
          x: {
            grid: {
              display: false,
            },
            barThickness: 100,
            barPercentage: 0.8,
            categoryPercentage: 0.8,
          },
          y: {
            grid: {
              display: true,
            },
            ticks: {
              beginAtZero: true,
            },
          },
        },
      },
    };

    const chart = new Chart(chartRef.current, chartOptions);

    return () => {
      chart.destroy();
    };
    // eslint-disable-next-line
  }, [datasets]);

  return {
    chartRef,
  };
};

export default usePartnerDashboardChart;
