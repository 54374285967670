import { Button } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useContact from "./useContact";
import RowOne from "./RowOne";
import RowTwo from "./RowTwo";
import RowThree from "./RowThree";

const Contact = ({
  optionsData,
  isOptionDataSuccess,
  isEditProfile,
  profileRefetch,
  className,
  isStickyFooter,
  refetchincom
}) => {
  const {
    formik,
    idFileInputRef,
    permStateRef,
    permDistrictRef,
    prestDistrictRef,
    prestStateRef,
    DocumentType,
    countryCodeOptions,
    currentFormData,
    updateIsLoading,
    createIsLoading,
    handleProofFile,
    handleCheckbox,
    getFieldError,
    handleCloseModal,
  } = useContact({ isOptionDataSuccess, isEditProfile, profileRefetch,refetchincom});

  return (
    <div className={className}>
      {/**
       * House No. / name
       * Street
       * Pincode
       * Country
       * State
       * District
      
       */}
      <RowOne
        formik={formik}
        optionsData={optionsData}
        getFieldError={getFieldError}
        prestStateRef={prestStateRef}
        prestDistrictRef={prestDistrictRef}
      />

      {/**
       * checkbox
       * House No. / name
       * Street
       * Pincode
       * Country
       * State
       * District
       * Id Type
       * Id Proof
      
       */}
      <RowTwo
        formik={formik}
        optionsData={optionsData}
        currentFormData={currentFormData}
        getFieldError={getFieldError}
        permStateRef={permStateRef}
        permDistrictRef={permDistrictRef}
        handleCheckbox={handleCheckbox}
      />

      {/**
      
       * Id Type
       * Id Proof
      
       */}
      <RowThree
        formik={formik}
        handleProofFile={handleProofFile}
        optionsData={optionsData}
        countryCodeOptions={countryCodeOptions}
        getFieldError={getFieldError}
        idFileInputRef={idFileInputRef}
        DocumentType={DocumentType}
        isEditProfile={isEditProfile}
      />

      <div
        className={`col-12 pro-d-flex pro-justify-end ${
          isStickyFooter && "offcanvas-footer-sticky-btns"
        }`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => handleCloseModal()}
        >
          Cancel
        </Button>

        <Button
          className={`pro-btn-primary lg pro-ms-3 ${
            isEditProfile ? updateIsLoading : createIsLoading ? "loading" : ""
          }`}
          type="submit"
          disabled={isEditProfile ? updateIsLoading : createIsLoading}
          onClick={formik.handleSubmit}
        >
          {isEditProfile ? (
            updateIsLoading
          ) : createIsLoading ? (
            <i
              className="spinner-border spinner-border-sm me-3"
              role="status"
              aria-hidden="true"
            ></i>
          ) : (
            ""
          )}
          {isEditProfile ? "Update" : "Create"}
        </Button>
      </div>
    </div>
  );
};

export default Contact;
