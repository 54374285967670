import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };

    try {
      const response = await api[method](
        `${endpoint}?${getParams("start")}${getParams("end")}${getParams(
          "digital_magazine_id"
        )}${getParams("sort_by")}${getParams("sort_order")}page_size=${
          params?.page_size || "10"
        }&${getParams("search")}page=${params?.page || 1}${getParams(
          "faq_id"
        )}`,
        body
      );

      return { data: response.data };
    } catch (axiosError) {
      let err = axiosError;

      return {
        data: err?.response?.data || err.message,
      };
    }
  };

export const faqsRtk = createApi({
  reducerPath: "faqsApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    "FAQs",
    "FAQsDeleteSingle",
    "FAQsDeleteMultiple",
    "UsersList",
    "HighlightedProfiles",
    "Subscribers",
    "Magazine",
    "MagazineView",
  ],
  endpoints: (builder) => ({
    getFAQsListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/faq/list`,
      }),
      providesTags: ["FAQs"],
    }),

    faqsDeleteSingle: builder.mutation({
      query: (values) => ({
        method: "delete",
        params: values,
        endpoint: `/admin/faq/delete`,
      }),
      invalidatesTags: ["FAQsDeleteSingle"],
    }),

    faqsDeleteMultiple: builder.mutation({
      query: (values) => ({
        method: "delete",
        params: values,
        endpoint: `/admin/faq/delete`,
      }),
      invalidatesTags: ["FAQsDeleteMultiple"],
    }),

    createNewFAQData: builder.mutation({
      query: (formData) => ({
        method: "post",
        endpoint: `/admin/faq/create`,
        body: formData,
      }),
      invalidatesTags: ["FAQs"],
    }),

    updateFAQListData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/user/fields/update`,
      }),

      invalidatesTags: ["FAQs"],
    }),

    getUsersListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/members/user`,
      }),
      providesTags: ["UsersList"],
    }),

    updateUsersListFieldsData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/user/fields/update`,
      }),

      invalidatesTags: ["UsersList"],
    }),

    // Highlighted Profiles

    getHighlightedProfilesListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/highlighted-profiles`,
      }),
      providesTags: ["HighlightedProfiles"],
    }),

    updateHighlightedProfilesListFieldsData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/user/fields/update`,
      }),

      invalidatesTags: ["HighlightedProfiles"],
    }),

    getSubscribersListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/newsletter/subscriptions`,
      }),
      providesTags: ["Subscribers"],
    }),

    updateSubscribersListFieldsData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/user/fields/update`,
      }),

      invalidatesTags: ["Subscribers"],
    }),

    getMagazineListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/digital-magazine/list`,
      }),
      providesTags: ["Magazine"],
    }),
    getMagazineViewData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/digital-magazine/view`,
      }),
      providesTags: ["MagazineView"],
    }),

    updateMagazineListFieldsData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/user/fields/update`,
      }),

      invalidatesTags: ["Magazine"],
    }),

    createMagazine: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/digital-magazine/create`,
      }),
      invalidatesTags: ["Magazine"],
    }),

    updateMagazine: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/digital-magazine/update`,
      }),
      invalidatesTags: ["Magazine"],
    }),
  }),
});

export const {
  useFaqsDeleteMultipleMutation,
  useFaqsDeleteSingleMutation,
  useGetFAQsListDataQuery,
  useCreateNewFAQDataMutation,
  useUpdateFAQListDataMutation,
  useGetUsersListDataQuery,
  useUpdateUsersListFieldsDataMutation,
  useGetHighlightedProfilesListDataQuery,
  useUpdateHighlightedProfilesListFieldsDataMutation,
  useGetSubscribersListDataQuery,
  useUpdateSubscribersListFieldsDataMutation,
  useGetMagazineListDataQuery,
  useUpdateMagazineListFieldsDataMutation,
  useCreateMagazineMutation,
  useUpdateMagazineMutation,
  useGetMagazineViewDataQuery,
} = faqsRtk;
