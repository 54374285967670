import {
  useCallback,
  useEffect,
  useMemo,
  //  useMemo,
  useState,
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import useObserver from "../../../utils/hooks/useObserver";
import { useDispatch, useSelector } from "react-redux";
import {
  getShortListedDetails,
  updateConfig,
} from "../../../store/slices/Profile/ShortListed";
import {
  profile,
  useGetOverviewDataQuery,
  //   useGetFilterOptionsDataQuery,
  useShortListProfileMutation,
} from "../../../store/queries/Profile";
import { toast } from "react-toastify";
import { updateConfig as updateConfigMatches } from "../../../store/slices/Profile/Matches";
// import { useFormik } from "formik";

const useShortlisted = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showScroll, setShowScroll] = useState(false);

  const [params] = useSearchParams();
  const activeProfile =
    params.get("active") ?? sessionStorage.getItem("active");
  const state = useSelector((state) => state.shortListed);
  const shortListedState = state?.shortListedData?.[activeProfile];

  const [sendRequest] = useShortListProfileMutation();

  const {
    data: overViewData,
    // refetch,
  } = useGetOverviewDataQuery({
    user_id: activeProfile,
  });

  useEffect(() => {
    if (Object.keys(state.shortListedData[activeProfile] ?? {}).length === 0) {
      dispatch(
        getShortListedDetails({
          user_id: activeProfile,
          page: state?.currentPage,
          clearState: true,
        })
      );
    }

    dispatch(
      updateConfig((state) => {
        state.selectedProfiles = shortListedState?.data
          ?.filter((item) => item)
          ?.map((item) => item?.member_id);
      })
    );

    //eslint-disable-next-line
  }, [activeProfile, shortListedState]);

  useEffect(() => {
    if (Object.keys(state.shortListedData[activeProfile] ?? {}).length > 0) {
      window.scrollTo(0, document.body.scrollHeight);
    }
    function handleWindowResize() {
      if (window.scrollY > 150) {
        setShowScroll(true);
      } else if (window.scrollY === 0) {
        setShowScroll(false);
      }
    }
    window.addEventListener("scroll", handleWindowResize);

    return () => {
      window.removeEventListener("scroll", handleWindowResize);
    };
    //eslint-disable-next-line
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  };
  const hasRemovePermission = useMemo(() => {
    let permission = state?.shortlistedPermissions?.filter((p) =>
      Object.keys(p).includes("can_remove")
    );

    return permission?.[0]?.can_remove ?? 0;
  }, [state]);

  const handleLoadMore = useCallback(() => {
    if (shortListedState?.shortListedStatus === "fulfilled") {
      dispatch(
        getShortListedDetails({
          user_id: activeProfile,
          // page: state?.currentPage + 1,
          page: shortListedState?.current_page + 1,
        })
      );
    }
    //eslint-disable-next-line
  }, [dispatch, activeProfile, shortListedState]);

  const { lastElement } = useObserver({
    loading: shortListedState?.shortListedStatus !== "fulfilled",
    hasMore:
      shortListedState?.per_page * shortListedState?.current_page <
      shortListedState?.total,
    callback: handleLoadMore,
  });

  const handleSelect = (id, checked, name) => {
    sendRequest({
      user_id: activeProfile,
      member_id: id,
      status: 0,
    })
      .then((response) => {
        if (response?.data?.status_code === 200) {
          toast.success("Shortlist Removed Successfully!");
          dispatch(profile.util.invalidateTags(["Overview"]));

          dispatch(
            updateConfigMatches((state) => {
              state.matchesStatus = "idle";
              state.matchesData = {};
              state.matchesPreferenceStatus = "idle";
              state.matchesPreferenceData = {};
              state.currentPage = 1;
            })
          );
          // dispatch(
          //   updateConfig((state) => {
          //     state.shortListedStatus = "idle";
          //     state.shortListedData = {};
          //     state.currentPage = 1;
          //   })
          // );
          dispatch(
            updateConfig((state) => {
              state.shortListedData[activeProfile] = {};
            })
          );
        } else if (response?.error?.data?.status_code === 422) {
          toast.error("Failed to Remove Shortlist!");
        } else {
          toast.error("Failed to Remove Shortlist!");
        }
      })
      .catch(() => {
        toast.error("Failed to Remove Shortlist!");
      });
    dispatch(
      updateConfig((state) => {
        state.selectedProfiles = state.selectedProfiles.filter(
          (prevID) => prevID !== id
        );
        // state.selectedProfileNames = state.selectedProfileNames.filter(
        //   (prevID) => prevID !== name
        // );
      })
    );
  };

  const handleProfileRedirect = (id, viewStatus) => {
    const ids = params.get("id")
      ? [...params.get("id").split(","), activeProfile]
      : [activeProfile];

    const newIds = ids.filter((item) => item !== id).join(",");
    if (viewStatus === 0) {
      toast.error(`User does not have permission to view this profile`);
    } else {
      navigate({
        pathname: "/profile",
        search: `active=${id}&id=${newIds}`,
      });
    }
  };

  return {
    state,
    activeProfile,
    shortListedState,
    showScroll,
    overViewData,
    hasRemovePermission,
    handleScrollToTop,
    lastElement,
    handleSelect,
    handleProfileRedirect,
  };
};
export default useShortlisted;
