import { toast } from "react-toastify";
import { UpdateData } from "./api";
import { useFormik } from "formik";
import * as Yup from "yup";

const useComplaintsModal = ({
  selectedId,
  handleClose,
  refetch,
  currentStatus,
}) => {
  let dataUpdationValidationSchema = Yup.object({
    note: Yup.string().required("*Required"),
  });

  const formik = useFormik({
    initialValues: {
      note: "",
    },

    validationSchema: dataUpdationValidationSchema,

    onSubmit: (values, { resetForm }) => {
      try {
        let formData = new FormData();
        // formData.append("_method", "PUT");
        formData.append("message_id", selectedId);
        formData.append("note", values.note);
        formData.append("status", currentStatus?.value ?? 0);
        UpdateData(formData).then((response) => {
          if (response?.data?.status_code === 200) {
            refetch?.();
            resetForm();
            handleClose?.();
            toast.success(response?.data?.message);
          } else if (response?.status_code === 400) {
            formik.setErrors(response?.message);
            toast.error("Failed to Update");
          } else {
            toast.error("Failed to Update");
          }
        });
      } catch (error) {
        toast.error("Something went wrong");
      }
    },
  });

  return { formik };
};

export default useComplaintsModal;
