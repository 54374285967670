import { useDispatch, useSelector } from "react-redux";

import {
  useGetFAQsListDataQuery,
  useUpdateFAQListDataMutation,
} from "../../../store/queries/cms";

import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import {
  updateConfig,
  deleteItemFromTable,
  setSelectedItemDetails,
  editModeToggle,
} from "../../../store/slices/Settings/faqsSlice";
import { getFormatedDate } from "../../../utils/functions/table";
import { useEffect, useMemo, useState } from "react";
import { subDays } from "date-fns";

const useFAQs = () => {
  const dispatch = useDispatch();

  const faqs = useSelector((state) => state.faqs);

  const { showEditModal } = useSelector((state) => state.global);

  const [showTableDeleteModal, setShowTableDeleteModal] = useState(false);
  const [selectedIDs, setSelectedIDs] = useState("");
  const [showFAQform, setShowFAQform] = useState(false);

  useEffect(() => {
    if (showFAQform === false) {
      if (faqs.formEditMode === true) {
        dispatch(setSelectedItemDetails(""));
        dispatch(editModeToggle(false));
      }
    }
    // eslint-disable-next-line
  }, [showFAQform]);

  useEffect(() => {
    if (faqs.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [faqs.clearSelection]);

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const {
    data: faqsList = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetFAQsListDataQuery({
    page_size: faqs.currentPageSize,
    page: faqs.currentPage,
    sort_by: faqs.sortBy,
    sort_order: faqs.sortOrder,
    start: getFormatedDate(faqs.startDate),
    end: getFormatedDate(faqs.endDate),
    search: faqs.search,
  });

  const hasCreatePermission = useMemo(() => {
    let permission = faqsList?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_create")
    );

    return permission?.[0]?.can_create ?? 0;
  }, [faqsList]);

  const hasDeletePermission = useMemo(() => {
    let permission = faqsList?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_delete")
    );

    return permission?.[0]?.can_delete ?? 0;
  }, [faqsList]);

  const hasEditPermission = useMemo(() => {
    let permission = faqsList?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_edit")
    );

    return permission?.[0]?.can_edit ?? 0;
  }, [faqsList]);

  const hasUpdatePermission = useMemo(() => {
    let permission = faqsList?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_update")
    );

    return permission?.[0]?.can_update ?? 0;
  }, [faqsList]);

  const hasExportPermission = useMemo(() => {
    let permission = faqsList?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_export")
    );

    return permission?.[0]?.can_export ?? 0;
  }, [faqsList]);

  const [updateFAQFields] = useUpdateFAQListDataMutation();

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleDateChange = (range) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = range.startDate;
        state.endDate = range.endDate;
      })
    );
  };

  const handleDelete = async (ids) => {
    setShowTableDeleteModal(true);
    setSelectedIDs(ids[0]);
  };

  const ActionDelete = async (ids) => {
    dispatch(deleteItemFromTable({ faq_id: selectedIDs }))
      .unwrap()
      .then((result) => {
        if (result.data.data === true) {
          refetch();
          dispatch(
            updateConfig((state) => {
              state.clearSelection = true;
            })
          );
          setShowTableDeleteModal(false);
          setSelectedIDs("");
        }
      })
      .catch((error) => {
        alert("something went wrong!");
        setShowTableDeleteModal(false);
        setSelectedIDs("");
      });
  };

  const handleSort = (label) => {
    if (faqs.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = faqs.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleEditFAQClick = (ids) => {
    if (Array?.isArray(ids)) {
      let selectedItem = faqsList.data.data.data?.filter((item) => {
        return item._id === ids[0];
      });
      dispatch(setSelectedItemDetails(selectedItem[0]));
      dispatch(editModeToggle(true));
      setShowFAQform(true);
    }
  };

  const handleEditClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      staff_id: (feild, data) => (
        <p className="pro-mb-0">{data[feild]?.name ?? ""}</p>
      ),
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const closeTableEditModal = () => {
    setSelectedIDs("");
    setShowTableDeleteModal(false);
  };
  let actionOptions = [
    { label: "Edit Columns", value: 0 },
    { label: "Export view", value: 1 },
  ];

  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    } else {
    }
  };

  const handleClearClick = () => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentPageSize = 10;
        state.sortBy = "";
        state.sortOrder = "";
        state.startDate = subDays(new Date(), 30);
        state.endDate = new Date()
        state.data = [];
        state.showCreateModal = false;
        state.formEditMode = false;
        state.selectedItemDetails = "";
        state.search = "";
      })
    );
  };

  return {
    handleClearClick,
    faqsList,
    faqs,
    currentPage: faqs.currentPage,
    paginationOptions,
    isFetching,
    isLoading,
    actionOptions,
    hasCreatePermission,
    hasDeletePermission,
    hasEditPermission,
    hasUpdatePermission,
    hasExportPermission,
    handleActionChange,
    handlePagination,
    handleDateChange,
    handlePageSize,
    handleSort,
    handleSearch,
    getRow,
    handleEditClick,
    handleEditFAQClick,
    closeEditModal,
    showEditModal,
    refetch,
    handleDelete,
    closeTableEditModal,
    showTableDeleteModal,
    ActionDelete,
    showFAQform,
    setShowFAQform,
    updateFAQFields,
  };
};

export default useFAQs;
