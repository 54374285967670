const usePackageRule = (formik) => {
  const { values, errors, touched } = formik;

  const options1 = [
    { id: 1, value: "Profile Views", label: "Profile Views" },
    { id: 2, value: "Contact", label: "Contact" },
  ];
  const options2 = [
    { id: 1, value: 1, label: "Yes" },
    { id: 2, value: 0, label: "No" },
  ];

  const addRow = (e) => {
    e.preventDefault();
    formik.setFieldValue("rules", [...values.rules, { rule1: "", rule2: "" }]);
  };

  const removeRow = (index) => {
    formik.setFieldValue(
      "rules",
      values.rules.filter((_, i) => i !== index)
    );
  };

  return {
    addRow,
    removeRow,
    options1,
    options2,
    values,
    errors,
    touched,
  };
};

export default usePackageRule;
