import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateFormData } from "../../../../../store/slices/Users/createMemberSlice";
// import { createMember } from "../api";
import { updateConfig } from "../../../../../store/slices/Users/membersSlice";
import { toast } from "react-toastify";
import {
  profile,
  useUpdateProfileDetailsMutation,
} from "../../../../../store/queries/Profile";
import {
  members,
  useCreateProfileMutation,
} from "../../../../../store/queries/members";
import countryData from "../../../../../utils/components/countryCode";
import { phoneRegExp } from "../../../../../utils/functions/table";
import { updateConfig as updatincompleteConfig } from "../../../../../store/slices/Users/incompleteReg.Slice";

const useContact = ({ isOptionDataSuccess, isEditProfile, profileRefetch,refetchincom}) => {
  const [loading, setLoading] = useState(false);
  const idFileInputRef = useRef();
  const permStateRef = useRef(" ");
  const permDistrictRef = useRef(" ");
  const prestStateRef = useRef(" ");
  const prestDistrictRef = useRef(" ");

  const DocumentType = [
    { name: "Aadhar Card", type: 1 },
    { name: "Driving License", type: 2 },
    { name: "PAN Card", type: 3 },
    { name: "Passport", type: 4 },
    { name: "SSLC", type: 5 },
    { name: "VotersID", type: 6 },
  ];

  const { currentFormData, completedTabs, backendErrors } = useSelector(
    (state) => state.createMembers
  );


  const [sendRequest, { isLoading: updateIsLoading }] =
    useUpdateProfileDetailsMutation();
  const [createMember, { isLoading: createIsLoading }] =
    useCreateProfileMutation();

  const dispatch = useDispatch();

  const schema = Yup.object().shape({
    communication_details: Yup.array().of(
      Yup.object().shape({
        country_code: Yup.string()
          .nullable()
          .test(
            "country-code-validation",
            "Country code is required ",
            function (value) {
              const phone_number = this.resolve(Yup.ref("phone_number"));
              if (phone_number && phone_number.length > 0) {
                return Yup.string().required().isValidSync(value);
              }
              return true;
            }
          ),
        phone_number: Yup.string()
          .nullable()
          .matches(phoneRegExp, "Please enter a valid phone number")
          .min(7, "The phone number must be between 7 and 14 digits")
          .max(14, "The phone number must be between 7 and 14 digits"),

        name: Yup.string().nullable(),
        // .min(2, "The  name must be at least 2 characters")
        // .matches(/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z])$/, "Invalid name"),
      })
    ),
    present_address: Yup.object().shape({
      house_name: Yup.string()
        .max(50, "House name must not exceed 50 characters")
        .nullable(),
      street: Yup.string()
        .max(50, "Street must not exceed 50 characters")
        .nullable(),
    }),
    permanent_address: Yup.object().shape({
      house_name: Yup.string()
        .max(50, "House name must not exceed 50 characters")
        .nullable(),
      street: Yup.string()
        .max(50, "Street must not exceed 50 characters")
        .nullable(),
    }),

    // id_type: Yup.mixed().required("*Required"),
    // id_proofs: Yup.mixed().required("*Required"),
  });

  let editRequiredData = {
    user_id: currentFormData?.user_id,
    first_name: currentFormData?.first_name,
    last_name: currentFormData?.last_name,
    email: currentFormData?.email,
    country_code: currentFormData?.country_code,
    phone_number: currentFormData?.phone_number,
    gender: currentFormData?.gender?.id,
    dob: currentFormData?.dob,
    community_id: currentFormData?.community_id?._id,
    diocese_id: currentFormData?.diocese_id?._id,
  };

  // const values = formik.values;

  const formik = useFormik({
    initialValues: {
      permanent_address: {
        house_name: "",
        street: "",
        pincode: "",
        country: "",
        state: "",
        district: "",
      },
      present_address: {
        house_name: "",
        street: "",
        pincode: "",
        country: "",
        state: "",
        district: "",
      },
      communication_details: [
        { country_code: "", phone_number: "", name: "", relationship: "" },
        { country_code: "", phone_number: "", name: "", relationship: "" },
        { country_code: "", phone_number: "", name: "", relationship: "" },
      ],
      permanent_address_as_present_address: "",
      id_type: currentFormData?.id_proofs?.[0]?.status === 1 ? currentFormData?.id_proofs?.[0]?.id_type : '',
      id_proofs: "",
    },

    validationSchema: schema,
    enableReinitialize: true,

    onSubmit: (values, { resetForm }) => {
      try {
        const communicationDetails = {};

        const filteredCommunicationDetails =
          values?.communication_details?.filter(
            (detail) => detail?.country_code && detail?.phone_number
          );

        filteredCommunicationDetails.forEach((detail, index) => {
          const country_code = detail?.country_code;
          const phone_number = detail?.phone_number;
          const name = detail?.name;
          const relationship = detail?.relationship?.id;

          communicationDetails[
            `communication_details[${index}][country_code]`
          ] = country_code;
          communicationDetails[
            `communication_details[${index}][phone_number]`
          ] = phone_number;
          communicationDetails[`communication_details[${index}][name]`] = name;
          communicationDetails[
            `communication_details[${index}][relationship]`
          ] = relationship;
        });

        let obj = {
          ...values,
          permanent_address_as_present_address: Boolean(
            values.permanent_address_as_present_address
          ),
        };

        let editObj = {
          "permanent_address[house_name]": values.permanent_address?.house_name,
          "permanent_address[street]": values.permanent_address?.street,
          "permanent_address[pincode]": values.permanent_address?.pincode,
          "permanent_address[country]": values.permanent_address?.country?._id,
          "permanent_address[state]": values.permanent_address?.state?._id,
          "permanent_address[district]":
            values.permanent_address?.district?._id,

          "present_address[house_name]": values.present_address?.house_name,
          "present_address[street]": values.present_address?.street,
          "present_address[pincode]": values.present_address?.pincode,
          "present_address[country]": values.present_address.country?._id,
          "present_address[state]": values.present_address?.state?._id,
          "present_address[district]": values.present_address?.district?._id,
          type: 5,
          permanent_address_as_present_address: +Boolean(
            values.permanent_address_as_present_address
          ),
          ...communicationDetails,
        };

        let newObj = Object.entries(editObj);

        newObj = newObj
          .filter((item) => item[1] !== undefined && item[1] !== "")
          .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {});

        if (isEditProfile) {
          let formData = new FormData();
          formData.append("_method", "PUT");

          Object.keys(newObj).forEach((key) => {
            return formData.append(key, newObj[key]);
          });

          if (
            values.id_proofs?.[0]?.url ||
            values.id_proofs?.length === 0 ||
            values.id_proofs === null
          ) {
            delete newObj.id_proofs;
          } else {
            formData.append(`id_proofs[0][file]`, values?.id_proofs);
            formData.append(`id_proofs[0][id_type]`, values?.id_type);
          }

          Object.keys(editRequiredData).forEach((key) => {
            return formData.append(key, editRequiredData[key]);
          });

          sendRequest(formData).then((response) => {
            dispatch(
              updateFormData((state) => {
                state.backendErrors = "";
              })
            );

            if (response?.data?.status_code === 200) {
              resetForm();
              dispatch(members.util.invalidateTags(["Members", "Incomplete"]));
              dispatch(profile.util.invalidateTags(["Overview"]));
              dispatch(
                updateFormData((state) => {
                  state.currentFormData = {};
                  state.activeTab = "Basic";
                  state.isEditProfile = false;
                  state.completedTabs = {
                    ...state.completedTabs,
                    Basic: false,
                    Family: false,
                    Job: false,
                    Edu: false,
                    Contact: false,
                  };
                  state.CompletedPercentage = {
                    ...state.CompletedPercentage,
                    Basic: 0,
                    Family: 0,
                    Job: 0,
                    Edu: 0,
                    Contact: 0,
                  };
                })
              );

              dispatch(
                updateConfig((state) => {
                  state.showCreateModal = false;
                  state.clearSelection = true;
                })
              );

              toast.success("Successfully submitted");
            } else if (response?.data?.status_code === 400) {
              let message = Object.values(response?.data?.message)?.[0]?.[0];

              toast.error(`Failed to  update, ${message}`);
              dispatch(
                updateFormData((state) => {
                  // formik.setErrors(response?.data?.message);
                  state.backendErrors = response?.data?.message;
                })
              );
            } else {
              toast.error(
                typeof response?.data?.message === "string"
                  ? response?.data?.message
                  : "Failed to  update"
              );
            }
          });
        } else {
          dispatch(
            updateFormData((state) => {
              state.currentFormData = { ...state.currentFormData, ...obj };
              state.completedTabs = { ...state.completedTabs, Contact: true };
            })
          );

          let objForm = {
            ...currentFormData,
            ...newObj,
            gender: values.gender?.id,
            community_id: values.community_id?._id,
            diocese_id: values.diocese_id?._id,
            parish_id: values.parish_id?._id,
            country: values?.country?._id,
            state: values?.state?._id,
            district: values?.district?._id,
            physical_status: values.physical_status?.id,
            weight: values.weight?._id,
            height: values.height?._id,
            marital_status: values.marital_status?.id,
            mother_tongue: values.mother_tongue?._id,
            body_type: values.body_type?.id,
            body_complexion: values.body_complexion?.id,
            "father_details[name]": values.father_details.name,
            "father_details[house_name]": values.father_details.house_name,
            "father_details[occupation]": values.father_details.occupation?._id,
            "father_details[occupation_detail]":
              values.father_details.occupation_detail,
            "father_details[native_place]": values.father_details.native_place,
            "mother_details[name]": values.mother_details.name,
            "mother_details[house_name]": values.mother_details.house_name,
            "mother_details[occupation]": values.mother_details.occupation?._id,
            "mother_details[occupation_detail]":
              values.mother_details.occupation_detail,
            "mother_details[native_place]": values.mother_details.native_place,
            family_type: values.family_type?.id,
            family_status: values.family_status?.id,
            "sibling_details[no_of_brothers]":
              values.sibling_details.no_of_brothers === ""
                ? 0
                : values.sibling_details.no_of_brothers,
            "sibling_details[brothers_married]":
              values.sibling_details.brothers_married === ""
                ? 0
                : values.sibling_details.brothers_married,
            "sibling_details[no_of_sisters]":
              values.sibling_details.no_of_sisters === ""
                ? 0
                : values.sibling_details.no_of_sisters,
            "sibling_details[sisters_married]":
              values.sibling_details.sisters_married === ""
                ? 0
                : values.sibling_details.sisters_married,
            no_of_priests:
              values.no_of_priests === "" ? 0 : values.no_of_priests,
            no_of_nuns: values.no_of_nuns === "" ? 0 : values.no_of_nuns,
            education_category: values?.education_category?._id,
            education: values?.education?._id,
            employment_sector: values?.employment_sector?.id,
            occupation_category: values?.occupation_category?._id,
            occupation: values?.occupation?._id,
            occupation_detail: values?.occupation_detail,
            annual_income: values?.annual_income?._id,
            work_country: values?.work_country?._id,
            work_state: values?.work_state?._id,
            work_district: values?.work_district?._id,
            "permanent_address[country]":
              values.permanent_address?.country?._id,
            "permanent_address[state]": values.permanent_address?.state?._id,
            "permanent_address[district]":
              values.permanent_address?.district?._id,

            permanent_address_as_present_address: +Boolean(
              values.permanent_address_as_present_address
            ),
          };

          if (!values.permanent_address_as_present_address) {
            objForm = {
              ...objForm,
              "present_address[country]": values.present_address.country?._id,
              "present_address[state]": values.present_address?.state?._id,
              "present_address[district]":
                values.present_address?.district?._id,
            };
          }

          let completeDataObj = Object.entries(objForm);

          completeDataObj = completeDataObj
            .filter(
              (item) =>
                item[1] !== undefined && item[1] !== "" && item[1] !== null
            )
            .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {});

          delete completeDataObj.id_type;
          delete completeDataObj.otp;

          delete completeDataObj.father_details;
          delete completeDataObj.mother_details;
          delete completeDataObj.sibling_details;
          delete completeDataObj.permanent_address;
          delete completeDataObj.present_address;
          delete completeDataObj.communication_details;

          let formData = new FormData();

          Object.keys(completeDataObj).forEach((key) => {
            if (key !== "profile_images" && key !== "profile_image_primary")
              return formData.append(key, completeDataObj[key]);
          });

          Object.values(completeDataObj?.profile_images || []).forEach(
            (photo, index) => {
              formData.append(`profile_images[${index}][file]`, photo);
              if (index === currentFormData?.profile_image_primary) {
                formData.append(`profile_images[${index}][is_primary]`, 1);
              } else {
                formData.append(`profile_images[${index}][is_primary]`, 0);
              }
            }
          );

          if (
            values.id_proofs?.[0]?.url ||
            values.id_proofs?.length === 0 ||
            values.id_proofs === null
          ) {
            delete completeDataObj.id_proofs;
          } else {
            formData.append(`id_proofs[0][file]`, values?.id_proofs);
            formData.append(`id_proofs[0][id_type]`, values?.id_type);
          }

          if (
            completedTabs.Basic &&
            completedTabs.Family &&
            completedTabs.Edu &&
            completedTabs.Job
          ) {
            createMember(formData).then((response) => {
              dispatch(
                updateFormData((state) => {
                  state.backendErrors = "";
                })
              );

              if (response?.data?.status_code === 200) {
                submitForm(resetForm);
              } else if (response?.error?.data?.status_code === 400) {
                let message = Object.values(
                  response?.error?.data?.message
                )?.[0]?.[0];

                toast.error(`Failed to  submit, ${message}`);

                if (
                  Object.keys(response?.error?.data?.message).includes(
                    "phone_number",
                    "email",
                    "community_id",
                    "diocese_id",
                    "marital_status",
                    "first_name",
                    "last_name"
                  )
                ) {
                  dispatch(
                    updateFormData((state) => {
                      // formik.setErrors(response?.message);
                      state.activeTab = "Basic";
                      state.backendErrors = response?.error?.data?.message;
                    })
                  );
                } else {
                  dispatch(
                    updateFormData((state) => {
                      // formik.setErrors(response?.message);
                      state.backendErrors = response?.error?.data?.message;
                    })
                  );
                }
              } else {
                toast.error(
                  typeof response?.data?.message === "string"
                    ? response?.data?.message
                    : "Failed to  submit"
                );
              }
            });
          } else {
            toast.error("Failed to  submit");
          }
        }
      } catch (error) {}
    },
  });

  const countryCodeOptions = useMemo(
    () =>
      countryData?.map((opt) => {
        return {
          value: opt.value,
          label: opt.label,
          title: `${opt.name}(${opt.code}) ${" "}  ${opt.label}`,
          code: opt.code,
        };
      }),
    []
  );

  useEffect(() => {
    if (isOptionDataSuccess) {
      Object.keys(currentFormData || {}).forEach((key) => {
        formik.setFieldValue(key, currentFormData?.[key]);
      });

      let filteredTouchedData = Object.entries(currentFormData || {});

      filteredTouchedData = filteredTouchedData
        .filter(
          (item) => item[1] === undefined || item[1] === "" || item[1] === null
        )
        .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {});

      Object.keys(filteredTouchedData || {}).forEach((key) => {
        formik.setFieldTouched(key, true);
      });
    }
    // eslint-disable-next-line
  }, [isOptionDataSuccess, currentFormData]);

  useEffect(() => {
    if (backendErrors) {
      formik.setErrors(backendErrors);
    }
    // eslint-disable-next-line
  }, [backendErrors]);

  useEffect(() => {
    const countTotalFields = (data) => {
      let count = 0;

      for (let key in data) {
        if (data.hasOwnProperty(key)) {
          const value = data[key];

          if (typeof value === "object" && value !== null) {
            count += countTotalFields(value);
          }

          count++;
        }
      }

      return count;
    };
    let totalFields = countTotalFields(formik.initialValues);

    const filterDataFromTab = (fullObject, tabObject) => {
      const filteredData = {};

      for (const key in tabObject) {
        if (fullObject.hasOwnProperty(key)) {
          filteredData[key] = fullObject[key];
        }
      }

      return filteredData;
    };

    const filteredData = filterDataFromTab(formik.values, formik.initialValues);

    const countNonEmptyKeys = (filteredData) => {
      let count = 0;
      for (let key in filteredData) {
        if (filteredData.hasOwnProperty(key)) {
          const value = filteredData[key];

          if (typeof value === "object" && value !== null) {
            count += countNonEmptyKeys(value);
          } else if (value !== null && value !== "") {
            count++;
          }
        }
      }

      return count;
    };

    let numberOfNonEmptyKeys = countNonEmptyKeys(filteredData);

    dispatch(
      updateFormData((state) => {
        state.CompletedPercentage = {
          ...state.CompletedPercentage,
          Contact: Math.ceil((numberOfNonEmptyKeys / totalFields) * 100),
        };
      })
    );
    // eslint-disable-next-line
  }, [formik.values]);

  const submitForm = (resetForm) => {
    if(refetchincom){
      refetchincom()
    }
    resetForm();

    setLoading(false);

    dispatch(
      updateFormData((state) => {
        state.currentFormData = {};
        state.activeTab = "Basic";
        state.completedTabs = {
          ...state.completedTabs,
          Basic: false,
          Family: false,
          Job: false,
          Edu: false,
          Contact: false,
        };
        state.CompletedPercentage = {
          ...state.CompletedPercentage,
          Basic: 0,
          Family: 0,
          Job: 0,
          Edu: 0,
          Contact: 0,
        };
      })
    );

    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
    dispatch(
      updatincompleteConfig((state) => {
        state.showCreateModal = false;
      })
    );
    dispatch(members.util.invalidateTags(["Members"]));
    dispatch(profile.util.invalidateTags(["Overview"]));
    toast.success("Successfully submitted");
  };

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
    dispatch(
      updateFormData((state) => {
        state.currentFormData = {};
        state.activeTab = "Basic";
        state.backendErrors = "";
        state.isEditProfile = false;
        state.phoneVerified = {
          verified: false,
          status: false,
        };
        state.completedTabs = {
          ...state.completedTabs,
          Basic: false,
          Family: false,
          Job: false,
          Edu: false,
          Contact: false,
        };
        state.CompletedPercentage = {
          ...state.CompletedPercentage,
          Basic: 0,
          Family: 0,
          Job: 0,
          Edu: 0,
          Contact: 0,
        };
      })
    );
  };
  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }

    return "";
  };

  // const getFieldError = (fieldName) => {
  //   if (backendErrors && backendErrors[fieldName]) {
  //     return backendErrors[fieldName];
  //   }
  //   if (formik.touched[fieldName] && formik.errors[fieldName]) {
  //     return formik.errors[fieldName];
  //   }
  //   return "";
  // };
  const handleCheckbox = (e) => {};
  const handleProofFile = (e) => {
    const files = e.target.files;

    if (files?.[0]?.size / (1024 * 1024) <= 5) {
      formik.setFieldValue("id_proofs", e?.target?.files?.[0]);
    } else if (files?.[0]?.size / (1024 * 1024) > 5) {
      toast.error("The image must be less than 5MB in size.");
      formik.setFieldError(
        "id_proofs",
        "The image must be less than 5MB in size."
      );
    }
  };

  return {
    backendErrors,
    formik,
    loading,
    updateIsLoading,
    createIsLoading,
    DocumentType,
    permStateRef,
    permDistrictRef,
    prestDistrictRef,
    prestStateRef,
    idFileInputRef,
    currentFormData,
    countryCodeOptions,
    handleProofFile,
    handleCheckbox,
    handleCloseModal,
    getFieldError,
  };
};

export default useContact;
