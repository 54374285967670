import { useDispatch, useSelector } from "react-redux";
import {
  useGetStatesViewDataByIDQuery,
  useGetStaffListQuery,
  useUpdateStatesFieldsDataMutation,
} from "../../../store/queries/settings";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import {
  updateConfig,
  editStates,
  selectStates,
  deleteDistrictFromStates,
} from "../../../store/slices/Settings/stateSingleSlice";
import { getFormatedDate } from "../../../utils/functions/table";
import { useEffect, useMemo, useState } from "react";
import { AdminRow } from "./Rows";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getExportedData } from "./api";
import moment from "moment";

const useStatesSingle = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stateSinglePage = useSelector((state) => state.stateSinglePage);

  const { showEditModal } = useSelector((state) => state.global);

  const [showTableDeleteModal, setShowTableDeleteModal] = useState(false);
  const [selectedIDs, setSelectedIDs] = useState("");
  const [showform, setShowform] = useState(false);

  const { statesID } = useParams();

  

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const [updateStatesSingleFields] = useUpdateStatesFieldsDataMutation();

  useEffect(() => {
    if (showform === false) {
      dispatch(selectStates(""));
      dispatch(editStates(false));
    }
    // eslint-disable-next-line
  }, [showform]);

  useEffect(() => {
    if (stateSinglePage.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [stateSinglePage.clearSelection]);

  const {
    data: statesSinglePageList = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetStatesViewDataByIDQuery({
    state_id: statesID,
    page_size: stateSinglePage.currentPageSize,
    page: stateSinglePage.currentPage,
    sort_by: stateSinglePage.sortBy,
    sort_order: stateSinglePage.sortOrder,
    start: stateSinglePage.startDate,
    end: stateSinglePage.endDate,
    search: stateSinglePage.search,
  });

  const hasExportPermission = useMemo(() => {
    let permission = statesSinglePageList?.data?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_export")
    );

    return permission?.[0]?.can_export ?? 0;
  }, [statesSinglePageList]);

  const hasCreatePermission = useMemo(() => {
    let permission = statesSinglePageList?.data?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_create")
    );

    return permission?.[0]?.can_create ?? 0;
  }, [statesSinglePageList]);

  
  

  const { refetch: refetchStaffList } = useGetStaffListQuery({ type: "1,2" });

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.menuDrawer = {
          show: true,
          backTo: "/configure/states",
          text: "Back to States",
        };
      })
    );

    return () => {
      dispatch(
        globalUpdateConfig((state) => {
          state.menuDrawer = {};
        })
      );
    };
    // eslint-disable-next-line
  }, []);

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleDateChange = (range) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = getFormatedDate(range.startDate);
        state.endDate = getFormatedDate(range.endDate);
      })
    );
  };

  const handleDelete = async (ids) => {
    setShowTableDeleteModal(true);
    setSelectedIDs(ids[0]);
  };

  const ActionDelete = async (ids) => {
    dispatch(deleteDistrictFromStates({ state_id: [selectedIDs] }))
      .unwrap()
      .then((res) => {
        if (res?.data?.status_code === true) {
          refetch();
          dispatch(
            updateConfig((state) => {
              state.clearSelection = true;
            })
          );
          refetchStaffList();
          toast.success("Deleted Successfully");
        } else {
          toast.error("Failed to Delete district!");
        }
      });
    setShowTableDeleteModal(false);
    setSelectedIDs("");
  };

  const handleSort = (label) => {
    if (stateSinglePage.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder =
            stateSinglePage.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleEditClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };

  const handleEditBranch = (branchID) => {
    let data = statesSinglePageList.data.data.filter((x) => {
      return x.branch_id === branchID;
    });

    if (data.length > 0) {
      dispatch(selectStates(data[0]));
      dispatch(editStates(true));
      setShowform(true);
    }
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      staff_name: (feild, data) => <AdminRow data={data} onClick={() => {}} />,
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const closeTableEditModal = () => {
    setSelectedIDs("");
    setShowTableDeleteModal(false);
  };

  const handleClearClick = () => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentPageSize = 10;
        state.sortBy = "";
        state.sortOrder = "";
        state.data = [];
        state.showCreateModal = false;
        state.selectedStates = "";
        state.editStates = false;
        state.preSelectedCommunity = null;
        state.search = "";
      })
    );
  };
  let actionOptions = [
    { label: "Edit Columns", value: 0 },
    { label: "Export view", value: 1 },
  ];

  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    } else {
      getExportedData({
        fields: Object.keys(statesSinglePageList?.data?.data?.fields),
        filter: stateSinglePage.currentFilter,
        sort_by: stateSinglePage.sortBy,
        sort_order: stateSinglePage.sortOrder,
        start: stateSinglePage.startDate,
        end: stateSinglePage.endDate,
        search: stateSinglePage.search,
      }).then((response) => {
        let dataType = response?.data?.type;
        let binaryData = [];
        binaryData.push(response?.data);
        let downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(
          new Blob(binaryData, { type: dataType })
        );
        downloadLink.setAttribute(
          "download",
          `${moment(Date.now()).format("MM_DD_YYYY_HH_mm_ss")}.xlsx`
        );
        document.body.appendChild(downloadLink);
        downloadLink.click();
      });
    }
  };

  const handleLinkClick = (item) => {
    if (item === "States") {
      navigate(`/configure/states`);
    }
  };

  return {
    handleClearClick,
    statesSinglePageList,
    stateSinglePage,
    currentPage: stateSinglePage.currentPage,
    paginationOptions,
    isFetching,
    isLoading,
    actionOptions,
    hasExportPermission,
    hasCreatePermission,
    handleActionChange,
    handlePagination,
    handleDateChange,
    handlePageSize,
    handleSort,
    handleSearch,
    getRow,
    handleEditClick,
    closeEditModal,
    showEditModal,
    refetch,
    handleDelete,
    closeTableEditModal,
    showTableDeleteModal,
    ActionDelete,
    showform,
    setShowform,
    handleEditBranch,
    handleLinkClick,
    updateStatesSingleFields,
  };
};

export default useStatesSingle;
