import { Button } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useAddFAQForm from "./useAddForm";
import FAQsFormFields from "../Fields";

const AddPaymentForm = ({ setShowFAQform, refetch, hasUpdatePermission }) => {
  const { formik, errors, loading, message } = useAddFAQForm(
    setShowFAQform,
    refetch
  );

  return (
    <div className={`pro-p-4`}>
      <FAQsFormFields formik={formik} />
      <div className="col-12 pro-mt-4 pro-d-flex pro-justify-end">
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => setShowFAQform(false)}
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          onClick={formik.handleSubmit}
          disabled={hasUpdatePermission === 1 ? false : true}
        >
          {loading ? (
            <i
              className="spinner-border spinner-border-sm me-3"
              role="status"
              aria-hidden="true"
            ></i>
          ) : (
            ""
          )}
          Save
        </Button>
      </div>

      {errors && <span className="error-text">{errors}</span>}

      {Object.keys(message)?.map((key, item) => {
        return <div className="error-text">{key + " : " + message[key]} </div>;
      })}
    </div>
  );
};

export default AddPaymentForm;
