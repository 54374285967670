import { getAxiosInstance } from "../../../api";

export const getExportedData = async (params) => {
  const getParams = (key) => {
    if (params?.[key]) {
      return `${key}=${params[key]}&`;
    } else {
      return "";
    }
  };

  const api = await getAxiosInstance();
  try {
    const response = await api.get(
      `admin/follow-up/export?${params?.fields
        ?.map((field, index) => `fields[${index}]=${field}`)
        .join("&")}&
        ${getParams("start")}${getParams("end")}&filter=${
        params?.filter || "all"
      }&${getParams("sort_by")}sort_order=${
        params?.sort_order || "desc"
      }&page_size=${params?.page_size || "10"}&${getParams("search")}page=${
        params?.page || 1
      }

      `,
      {
        responseType: "blob",
      }
    );

    return response;
  } catch (error) {
    return error.response.data;
  }
};
