import {
  Button,
  IconText,
  Image,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Select from "react-select";
import Style from "./magazineForm.module.scss";
import useMagazineForm from "./useMagazineForm";
import { limitStrLength } from "../../../../utils/functions/table";

const MagazineForm = ({
  refetch,
  optionsData,
  hasUpdatePermission,
  closeModal,
  isStickyFooter,
}) => {
  const {
    formik,
    imageCoverPreview,
    handleCoverImage,
    selectedItemsDetails,
    handleUploadImage,
    isLoading,
    profilefileInputRef,
  } = useMagazineForm({ refetch, closeModal });

  return (
    <div className={`pro-w-100`}>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Year
        </label>
        <Select
          id="year"
          name="year"
          isClearable={true}
          className={`pro-input lg `}
          classNamePrefix="pro-input"
          isDisabled={true}
          placeholder={formik.values.year}
        />
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Month
        </label>
        <Select
          id="month"
          isClearable={true}
          placeholder={"Select"}
          className={`pro-input lg  ${
            formik.touched.month && formik.errors.month && " error"
          }`}
          classNamePrefix="pro-input"
          options={optionsData?.data?.months}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.name}
          value={optionsData?.data?.months?.filter(
            (m) => formik.values.month === m?.id
          )}
          onBlur={formik.handleBlur("month")}
          onChange={(value) => {
            formik.setFieldValue("month", value?.id || null);
          }}
        />
        {formik.touched.month && formik.errors.month && (
          <span className="error-text">{formik.errors.month}</span>
        )}{" "}
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Cover Photo*
        </label>
        <div className="input-drag">
          <input
            type="file"
            placeholder="placeholder"
            accept=".jpeg, .jpg , .png"
            ref={profilefileInputRef}
            className={`pro-input ${
              formik.errors.cover_photo &&
              formik.touched.cover_photo &&
              " error"
            }`}
            id="cover_photo"
            name="cover_photo"
            onBlur={formik.handleBlur("cover_photo")}
            onChange={(e) => handleCoverImage(e)}
          />
          <span className="input-drag-box">
            <IconText
              title={
                formik?.values?.cover_photo?.[0]?.name !== undefined &&
                selectedItemsDetails === ""
                  ? limitStrLength(formik?.values?.cover_photo?.[0]?.name, 30)
                  : formik?.values?.cover_photo?.name !== undefined &&
                    selectedItemsDetails !== ""
                  ? limitStrLength(formik?.values?.cover_photo?.name, 30)
                  : `Drop files to attach or browse`
              }
            />
          </span>
          {formik.touched.cover_photo && formik.errors.cover_photo && (
            <span className="error-text">{formik.errors.cover_photo}</span>
          )}
        </div>
      </div>
      <div className={`col-2 pro-my-2`}>
        <div className={`${Style.root_image_inner}`}>
          <Image
            width={100}
            height={100}
            src={imageCoverPreview}
            alt={`magazine - 01`}
          />
        </div>
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Upload file*
        </label>
        <div className="input-drag">
          <input
            type="file"
            placeholder="placeholder"
            accept=".pdf"
            ref={profilefileInputRef}
            className={`pro-input ${
              formik.errors.magazine && formik.touched.magazine && " error"
            }`}
            id="magazine"
            name="magazine"
            onBlur={formik.handleBlur("magazine")}
            onChange={(e) => handleUploadImage(e)}
          />
          <span className="input-drag-box">
            <IconText
              title={
                formik?.values?.magazine?.[0]?.name !== undefined &&
                selectedItemsDetails === ""
                  ? limitStrLength(formik?.values?.magazine?.[0]?.name, 30)
                  : formik?.values?.magazine?.name !== undefined &&
                    selectedItemsDetails !== ""
                  ? limitStrLength(formik?.values?.magazine?.name, 30)
                  : `Drop files to attach or browse`
              }
            />
          </span>
          {formik.touched.magazine && formik.errors.magazine && (
            <span className="error-text">{formik.errors.magazine}</span>
          )}
        </div>
      </div>

      <div
        className={`col-12 pro-d-flex pro-justify-end ${
          isStickyFooter && "offcanvas-footer-sticky-btns"
        }`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => closeModal()}
        >
          Cancel
        </Button>

        <Button
          className={`pro-btn-primary lg pro-ms-3 ${
            isLoading ? "loading" : ""
          }`}
          type="submit"
          disabled={hasUpdatePermission === 1 ? false : true}
          onClick={formik.handleSubmit}
        >
          {selectedItemsDetails === "" ? "Create" : "Update"}
        </Button>
      </div>
    </div>
  );
};

export default MagazineForm;
