import {
  MultiColumnTable,
  SimpleTextDetails,
} from "@wac-ui-dashboard/wac_component_library";
import Style from "./profilePreview.module.scss";
import profileStyle from "../../../../pages/Layouts/ProfileLayout/profileLayout.module.scss";
import useProfilePreview from "./useProfilePreview";
import Assets from "../../../../assets/Assets";

const ProfilePreview = ({ profileData, status }) => {
  const {
    educationDetails,
    occupationDetails,
    familyDetails,
    contactDetails,
    basicDetails,
  } = useProfilePreview({ profileData });

  return (
    <>
      <div className={`${Style.root} pro-pb-4`}>
        <div className={Style.img_wrap}>
          <figure className="pro-mb-0">
            <img
              src={
                profileData?.data?.data?.profile_images?.length > 0
                  ? profileData?.data?.data?.profile_images?.filter(
                      (item) => item?.is_primary === 1
                    )?.[0]?.url ??
                    profileData?.data?.data?.profile_images?.[0]?.url
                  : profileData?.data?.data?.gender?.name === "Female"
                  ? Assets.FEMALEPROFILE
                  : Assets.MALEPROFILE
              }
              alt={`pic-${profileData?.data?.data?.first_name}`}
            />
          </figure>
        </div>
        <div className="pro-ps-4 pro-d-flex pro-flex-column">
          <div className="pro-d-flex pro-gap-1">
            {profileData?.data?.data?.premium_status && (
              <span
                className={`${Style.isPremium} ${
                  profileData?.data?.data?.premium_status === "Platinum"
                    ? Style.large
                    : ""
                } pro-py-1 pro-px-2 pro-font-sm`}
                style={{ fontVariationSettings: ` 'FILL' 1` }}
              >
                <span className="material-symbols-outlined">star</span>
                <span>{profileData?.data?.data?.premium_status}</span>
              </span>
            )}

            {status === 2 && (
              <span
                className={`${Style.isBlocked} pro-py-1 pro-px-3 pro-font-sm`}
                style={{ fontVariationSettings: ` 'FILL' 1` }}
              >
                <span>Blocked</span>
              </span>
            )}

            {status === 4 && (
              <span
                className={`${Style.isInactive} pro-py-1 pro-px-3 pro-font-sm`}
                style={{ fontVariationSettings: ` 'FILL' 1` }}
              >
                <span>Inactive</span>
              </span>
            )}

            {status === 1 && (
              <span
                className={`${Style.isActive} pro-py-1 pro-px-3 pro-font-sm`}
                style={{ fontVariationSettings: ` 'FILL' 1` }}
              >
                <span>Active</span>
              </span>
            )}

            {status === 3 && (
              <span
                className={`${Style.isDeleted} pro-py-1 pro-px-3 pro-font-sm`}
                style={{ fontVariationSettings: ` 'FILL' 1` }}
              >
                <span>Deleted</span>
              </span>
            )}

            {status === 0 && (
              <span
                className={`${Style.isPending} pro-py-1 pro-px-3 pro-font-sm`}
                style={{ fontVariationSettings: ` 'FILL' 1` }}
              >
                <span>Pending</span>
              </span>
            )}
            {status === 5 && (
              <span
                className={`${Style.isExpired} pro-py-1 pro-px-3 pro-font-sm`}
                style={{ fontVariationSettings: ` 'FILL' 1` }}
              >
                <span>Expired</span>
              </span>
            )}
          </div>
          <SimpleTextDetails
            isOnline={profileData?.data?.data?.last_login ? true : false}
            title={`${profileData?.data?.data?.first_name ?? ""} ${
              profileData?.data?.data?.last_name ?? ""
            } ${
              profileData?.data?.data?.age
                ? `(${profileData?.data?.data?.age})`
                : ""
            }`}
            uId={profileData?.data?.data?.profile_id ?? ""}
            lastOnline={
              profileData?.data?.data?.last_login &&
              `Last login: ${profileData?.data?.data?.last_login ?? ""}`
            }
            extraClassNames={`pro-mt-3 pro-mb-2 ${profileStyle.profile_name_wrap}`}
          />
        </div>
      </div>

      <div className={`${Style.overview_wrap_list_item} pro-my-5`}>
        <MultiColumnTable
          title={"About"}
          data={[
            {
              label: `${profileData?.data?.data?.about_me ?? "Not specified"}`,
              value: "",
            },
          ]}
          // extraClassNames={`$Style.multi_column_table`}
          extraClassNames={`${Style.table_wrap}`}
        />
      </div>
      <div className={`col-12 pro-py-4 ${Style.overview_wrap_list_item}`}>
        <MultiColumnTable
          title={"Basic Details"}
          data={basicDetails}
          minWidth={`40%`}
          extraClassNames={`${Style.table_wrap}`}
        />
      </div>

      <div className={`col-12 pro-py-4 ${Style.overview_wrap_list_item}`}>
        <MultiColumnTable
          minWidth={`40%`}
          title={"Education Details"}
          data={educationDetails}
          extraClassNames={`${Style.table_wrap}`}
        />
      </div>

      <div className={`col-12 pro-py-4 ${Style.overview_wrap_list_item}`}>
        <MultiColumnTable
          minWidth={`40%`}
          title={"Job Details"}
          data={occupationDetails}
          extraClassNames={`${Style.table_wrap}`}
        />
      </div>

      <div className={`col-12 pro-py-4 ${Style.overview_wrap_list_item}`}>
        <MultiColumnTable
          minWidth={`40%`}
          title={"Family Details"}
          data={familyDetails}
          extraClassNames={`${Style.table_wrap}`}
        />
      </div>

      <div className={`col-12 pro-py-4 ${Style.overview_wrap_list_item}`}>
        <MultiColumnTable
          minWidth={`40%`}
          title={"Contact Details"}
          data={contactDetails}
          extraClassNames={`${Style.table_wrap}`}
        />
      </div>
    </>
  );
};

export default ProfilePreview;
