import { useFormik } from "formik";
import * as Yup from "yup";
import { useRef, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { useSelector } from "react-redux";
import {
  saveStatesDetails,
  updateConfig,
  updateStatesDetails,
} from "../../../../store/slices/Settings/stateSlice";
import { useGetMemberCreationFormDataQuery } from "../../../../store/queries/members";

const useAddForm = (setShowform, refetch) => {
  const [errors, setErrors] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [options, setOptions] = useState("");

  const imageFileInputRef = useRef(null);

  const statesListingPage = useSelector((state) => state.statesListingPage);
  const dispatch = useDispatch();

  let validationSchema = Yup.object({
    stateName: Yup.string().required("Required"),
    country: Yup.string().required("Required"),
  });

  const { data: countryList = {} } = useGetMemberCreationFormDataQuery();

  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    setOptions(
      countryList?.data?.country?.map((item) => {
        return { value: item._id, label: item.name };
      })
    );
    // eslint-disable-next-line
  }, [countryList]);

  const formik = useFormik({
    initialValues: {
      stateName:
        statesListingPage?.editState === true
          ? statesListingPage?.selectedState?.name
          : "",
      country:
        statesListingPage?.editState === true
          ? statesListingPage?.selectedState?.country_id
          : "",
    },

    validationSchema: validationSchema,

    onSubmit: (values, { resetForm, setFieldError }) => {
      handleFormSubmit(values, resetForm, setFieldError);
    },
  });

  let selectedCountrytyId = countryList?.data?.country?.filter(
    (p) =>
      p?.unique_country_id?.toString() === formik.values.country?.toString()
  )?.[0]?._id;

  const handleFormSubmit = async (values, resetForm, setFieldError) => {
    try {
      if (statesListingPage?.editState === false) {
        try {
          let obj = {
            name: values.stateName,
            country_id: selectedCountrytyId,
          };

          dispatch(saveStatesDetails(obj))
            .unwrap()
            .then((data) => {
              setShowform(false);
              refetch?.();
              toast.success("Successfully submitted");
            })
            .catch((err) => {
              toast.error(err?.message);
            });
        } catch (error) {}
      } else if (statesListingPage?.editState === true) {
        try {
          let obj = {
            state_id: statesListingPage?.selectedState._id,
            name: values.stateName,
            country_id: selectedCountrytyId,
          };

          dispatch(updateStatesDetails(obj))
            .unwrap()
            .then((data) => {
              setShowform(false);
              dispatch(
                updateConfig((state) => {
                  state.clearSelection = true;
                })
              );
              refetch?.();
              toast.success("Details updated Successfully.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            })
            .catch((err) => {
              toast.error(err?.message);
            });
        } catch (error) {}
      }
    } catch (error) {
      setErrors("Something went wrong!");
    }
  };

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
  };

  return {
    errors,
    message,
    setMessage,
    formik,
    loading,
    countryList,
    setLoading,
    handleCloseModal,
    imageFileInputRef,
    selectedOption,
    setSelectedOption,
    options,
  };
};

export default useAddForm;
