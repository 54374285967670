import { useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetAdonsListDataQuery,
  useGetAdonsUpdateFieldsDataMutation,
} from "../../../store/queries/settings";
import { updateConfig } from "../../../store/slices/Settings/adonsSlice";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { updateConfig as addonUpdateConfig } from "../../../store/slices/Settings/adonsSlice";
import { editItemFromTableByID } from "../../../store/slices/Settings/adonsSlice";
import StatusButton from "./StatusButton";
const useAdons = () => {
  const dispatch = useDispatch();
  const adonsState = useSelector((state) => state.adons);

  const {
    showCreateModal,
    selectedItemsDetails,
    currentPageSize,
    currentPage,
  } = useSelector((state) => state.adons);
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const {
    data: adons = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetAdonsListDataQuery({
    sort_by: adonsState.sortBy,
    sort_order: adonsState.sortOrder,
    search: adonsState.search,
    page: adonsState.currentPage,
    page_size: adonsState.currentPageSize,
  });

  const [updateAdonsFields] = useGetAdonsUpdateFieldsDataMutation();

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = adons?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [adons]);

  useEffect(() => {
    if (showCreateModal === false && selectedItemsDetails != null) {
      dispatch(
        updateConfig((state) => {
          state.selectedItemsDetails = null;
        })
      );
    }
    // eslint-disable-next-line
  }, [showCreateModal]);

  useEffect(() => {
    if (adonsState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [adonsState.clearSelection]);

  const hasCreatePermission = useMemo(() => {
    let permission = adons?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_create")
    );
    return permission?.[0]?.can_create ?? 0;
    // eslint-disable-next-line
  }, [adons]);
  const hasEditPermission = useMemo(() => {
    let permission = adons?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_edit")
    );
    return permission?.[0]?.can_edit ?? 0;
    // eslint-disable-next-line
  }, [adons]);
  const hasUpdatePermission = useMemo(() => {
    let permission = adons?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_update")
    );
    return permission?.[0]?.can_update ?? 0;
    // eslint-disable-next-line
  }, [adons]);

  const hasExportPermission = useMemo(() => {
    let permission = adons?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_export")
    );

    return permission?.[0]?.can_export ?? 0;
  }, [adons]);


  const hasStatusUpdatePermission = useMemo(() => {
    let permission = adons?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_change_status")
    );

    return permission?.[0]?.can_change_status ?? 0;
  }, [adons]);

  useEffect(() => {
    dispatch(
      addonUpdateConfig((state) => {
        state.formType = "addon";
      })
    );
    return () => {
      dispatch(
        addonUpdateConfig((state) => {
          state.formType = null;
        })
      );
    };
    // eslint-disable-next-line
  }, []);

  const handleSort = (label) => {
    if (adonsState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.sortOrder = adonsState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
      })
    );
  };

  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
      })
    );
  };
  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
  };
  const handleEditClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };

  const handleEditAddonField = (data) => {
    let ID = Array.isArray(data) ? data[0] : data;
    dispatch(editItemFromTableByID({ add_on_id: ID }))
      .unwrap()
      .then((result) => {
        dispatch(
          updateConfig((state) => {
            state.showCreateModal = true;
          })
        );
      })
      .catch((err) => {});
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      status: (feild, data) =>
        hasStatusUpdatePermission === 1 ? (
          <StatusButton data={data} refetch={refetch} />
        ) : (
          <p className="pro-mb-0">{data[feild] ?? ""}</p>
        ),
    };
    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  let actionOptions = [
    { label: "Edit Columns", value: 0 },
    { label: "Export view", value: 1 },
  ];

  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    } else {
    }
  };

  const handleClearClick = () => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentPageSize = 10;
        state.sortBy = "";
        state.sortOrder = "";
        state.search = "";
        state.showCreateModal = false;
        state.selectedRowField = null;
        state.selectedItemsDetails = null;
        state.rules = null;
        state.formType = null;
      })
    );
  };

  return {
    handleClearClick,
    refetch,
    adons,
    showCreateModal,
    hasCreatePermission,
    hasExportPermission,
    hasEditPermission,
    hasUpdatePermission,
    isFetching,
    isLoading,
    actionOptions,
    handleActionChange,
    handleSort,
    handleSearch,
    handleCreateClick,
    closeModal,
    getRow,
    handleEditClick,
    closeEditModal,
    showEditModal,
    tableFields,
    updateAdonsFields,
    handleEditAddonField,
    adonsState,
    currentPageSize,
    currentPage,
    handlePagination,
    paginationOptions,
    handlePageSize,
  };
};

export default useAdons;
