import { getAxiosInstance } from "../../../../api";

export const changeStatus = async (status) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("admin/packages/change-status", status);
      return response;
    } catch (error) {
      return error.response.data;
    }
  };
  