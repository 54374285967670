import React, { useEffect } from "react";
import Select from "react-select";
import Style from "./packageRule.module.scss";
import usePackageRule from "./usePackageRule";
import { Input } from "@wac-ui-dashboard/wac_component_library";

const PackageRule = ({
  formik,
  ruleOptions,
  fieldsSetCount,
  setFieldsSetCount,
}) => {
  const { options2, errors, touched } = usePackageRule(formik);

  const isOptionSelected = (pointer) => {
    return fieldsSetCount.some((obj) => {
      return Object.values(obj).some(
        (value) => JSON.stringify(value) === JSON.stringify(pointer)
      );
    });
  };

  useEffect(() => {
    var isFull = [];

    fieldsSetCount.map((ele) => {
      if (
        ele?.OptionOne?.id !== undefined &&
        (ele?.OptionTwo?.value !== undefined || ele?.OptionTwo !== "")
      ) {
        isFull.push({
          id: ele?.OptionOne?.id,
          value:
            typeof ele?.OptionTwo === "object"
              ? ele?.OptionTwo.value
              : ele?.OptionTwo,
        });
      }
      return ele;
    });

    if (isFull.length >= 1) {
      formik.setFieldValue(`rules`, isFull);
    } else {
      formik.setFieldValue(`rules`, null);
    }
    // eslint-disable-next-line
  }, [fieldsSetCount]);

  return (
    <div className={Style.root}>
      <div className="col-12">
        <h5 className="pro-ttl h5 pro-mb-3">Rules</h5>
        {fieldsSetCount?.map((obj, index) => {
          return (
            <div
              className={`${Style.root_rule} pro-p-2 pro-mb-3 ${
                formik?.errors?.rules !== undefined && index === 0
                  ? Style.error_rule
                  : ""
              }`}
              key={index}
            >
              <h6 className="pro-mb-1 pro-fw-500">Rule {index + 1} </h6>
              <div className="row">
                <div className="col">
                  <div className="pro-w-100">
                    <Select
                      name={`rules[${index}].rule1`}
                      value={fieldsSetCount[index]?.OptionOne}
                      options={ruleOptions?.filter((s, i) => {
                        if (fieldsSetCount.length === 1) {
                          return s;
                        }
                        //&& index !== i
                        return isOptionSelected(s) ? false : true;
                      })}
                      className={`pro-input lg multi-select ${
                        errors.rules &&
                        errors.rules[index] &&
                        errors.rules[index].rule1 &&
                        touched.rules &&
                        touched.rules[index] &&
                        touched.rules[index].rule1 &&
                        " error"
                      }`}
                      placeholder="Select a rule"
                      menuPlacement="top"
                      menuPosition="auto"
                      isClearable
                      classNamePrefix="pro-input"
                      onChange={(option) => {
                        setFieldsSetCount((prevState) =>
                          prevState.map((field, pos) => {
                            if (pos === index) {
                              return { ...field, ...{ OptionOne: option } };
                            }
                            return field;
                          })
                        );
                        // formik.setFieldValue(`rules[${index}].rule1`, option);
                      }}
                    />
                    {errors.rules &&
                      errors.rules[index] &&
                      errors.rules[index].rule1 &&
                      touched.rules &&
                      touched.rules[index] &&
                      touched.rules[index].rule1 && (
                        <span className={Style.error_text}>
                          {errors.rules[index].rule1}
                        </span>
                      )}
                  </div>
                </div>
                <div className="col">
                  <div className="pro-w-100">
                    {fieldsSetCount[index]?.OptionOne?.type === `boolean` ? (
                      <Select
                        name={`rules[${index}].rule2`}
                        menuPlacement="auto"
                        value={fieldsSetCount[index]?.OptionTwo}
                        options={options2}
                        className={`pro-input multi-select lg ${
                          errors.rules &&
                          errors.rules[index] &&
                          errors.rules[index].rule2 &&
                          touched.rules &&
                          touched.rules[index] &&
                          touched.rules[index].rule2 &&
                          "error"
                        }`}
                        placeholder="Select a rule"
                        isClearable
                        classNamePrefix="pro-input"
                        onChange={(option) => {
                          setFieldsSetCount((prevState) =>
                            prevState.map((field, pos) => {
                              if (pos === index) {
                                return { ...field, ...{ OptionTwo: option } };
                              }
                              return field;
                            })
                          );
                        }}
                      />
                    ) : fieldsSetCount[index]?.OptionOne?.type === `text` ? (
                      <Input
                      type="number"
                        value={
                          typeof fieldsSetCount[index]?.OptionTwo == "object"
                            ? ""
                            : fieldsSetCount[index]?.OptionTwo
                        }
                        id={`rules[${index}].ruleText`}
                        name={`rules[${index}].ruleText`}
                        className={`pro-input lg ${
                          errors.rules &&
                          errors.rules[index] &&
                          errors.rules[index].ruleText &&
                          touched.rules &&
                          touched.rules[index] &&
                          touched.rules[index].ruleText &&
                          " error"
                        }`}
                        onChange={(e) => {
                          setFieldsSetCount((prevState) =>
                            prevState.map((field, pos) => {
                              if (pos === index) {
                                return {
                                  ...field,
                                  ...{ OptionTwo: e.target.value },
                                };
                              }
                              return field;
                            })
                          );
                        }}
                        error={
                          touched.rules &&
                          touched.rules[index] &&
                          touched.rules[index].ruleText &&
                          errors.rules &&
                          errors.rules[index] &&
                          errors.rules[index].ruleText
                        }
                        //errorMessage={errors.rules[index].ruleText}
                      />
                    ) : (
                      <></>
                    )}

                    {errors.rules &&
                      errors.rules[index] &&
                      errors.rules[index].rule2 &&
                      touched.rules &&
                      touched.rules[index] &&
                      touched.rules[index].rule2 && (
                        <span className={Style.error_text}>
                          {errors.rules[index].rule2}
                        </span>
                      )}
                  </div>
                </div>

                <div
                  className={`${Style.deleteAction} pro-d-flex pro-align-self-center`}
                >
                  {index !== 0 && (
                    <button
                      className="pro-btn-link "
                      disabled={index === 0 ? true : false}
                      onClick={() => {
                        setFieldsSetCount((previousValue) => {
                          return previousValue.filter((obj, position) => {
                            return position !== index;
                          });
                        });
                      }}
                    >
                      <span className="material-symbols-outlined">delete</span>
                    </button>
                  )}
                </div>
              </div>

              {formik?.errors?.rules !== undefined && index === 0 ? (
                <span className={Style.error_text}>*Required</span>
              ) : (
                ""
              )}
            </div>
          );
        })}

        <div className="col-12">
          <button
            disabled={fieldsSetCount?.length >= 5 ? true : false}
            className="pro-btn pro-btn-outline"
            onClick={(e) =>
              setFieldsSetCount((previousValue) => {
                return [
                  ...previousValue,
                  ...[
                    {
                      OptionOne: "",
                      OptionTwo: "",
                    },
                  ],
                ];
              })
            }
          >
            <span className="material-symbols-outlined">add_circle</span>
            <span>Add Rule</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default PackageRule;
