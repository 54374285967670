import { useDispatch, useSelector } from "react-redux";
import {
  useGetDioceseViewDataQuery,
  useGetStaffListQuery,
  useUpdateDioceseFieldsDataMutation,
} from "../../../store/queries/settings";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import {
  updateConfig,
  selectDiocese,
  editDiocese,
  deleteStaffFromBranches,
  getDioceseDetailsByID,
} from "../../../store/slices/Settings/dioceseSlice";
import { getFormatedDate } from "../../../utils/functions/table";
import { useEffect, useMemo, useState } from "react";
import { DioceseName } from "./Rows";
import moment from "moment";
import { getExportedData } from "./api";

const useDioceses = () => {
  const dispatch = useDispatch();

  const dioceseListingPage = useSelector((state) => state.dioceseListingPage);

  const { showEditModal } = useSelector((state) => state.global);

  const [showTableDeleteModal, setShowTableDeleteModal] = useState(false);
  const [selectedIDs, setSelectedIDs] = useState("");
  const [showform, setShowform] = useState(false);

  const [updateDiocesesFields] = useUpdateDioceseFieldsDataMutation();

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  useEffect(() => {
    if (showform === false) {
      dispatch(selectDiocese(""));
      dispatch(editDiocese(false));
    }
    // eslint-disable-next-line
  }, [showform]);

  useEffect(() => {
    if (dioceseListingPage.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [dioceseListingPage.clearSelection]);

  const {
    data: dioceseListingPageList = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetDioceseViewDataQuery({
    page_size: dioceseListingPage.currentPageSize,
    page: dioceseListingPage.currentPage,
    sort_by: dioceseListingPage.sortBy,
    sort_order: dioceseListingPage.sortOrder,
    start: dioceseListingPage.startDate,
    end: dioceseListingPage.endDate,
    search: dioceseListingPage.search,
  });

  const hasExportPermission = useMemo(() => {
    let permission = dioceseListingPageList?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_export")
    );

    return permission?.[0]?.can_export ?? 0;
  }, [dioceseListingPageList]);
  const hasCreatePermission = useMemo(() => {
    let permission = dioceseListingPageList?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_create")
    );

    return permission?.[0]?.can_create ?? 0;
  }, [dioceseListingPageList]);

  const hasEditPermission = useMemo(() => {
    let permission = dioceseListingPageList?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_edit")
    );

    return permission?.[0]?.can_edit ?? 0;
  }, [dioceseListingPageList]);

  const hasUpdatePermission = useMemo(() => {
    let permission = dioceseListingPageList?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_update")
    );

    return permission?.[0]?.can_update ?? 0;
  }, [dioceseListingPageList]);

  const hasViewPermission = useMemo(() => {
    let permission = dioceseListingPageList?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_view")
    );

    return permission?.[0]?.can_view ?? 0;
  }, [dioceseListingPageList]);


  

  const { refetch: refetchStaffList } = useGetStaffListQuery({ type: "1,2" });

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleDateChange = (range) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = getFormatedDate(range.startDate);
        state.endDate = getFormatedDate(range.endDate);
      })
    );
  };

  const handleDelete = async (ids) => {
    setShowTableDeleteModal(true);
    setSelectedIDs(ids[0]);
  };

  const ActionDelete = async (ids) => {
    dispatch(deleteStaffFromBranches({ user_id: [selectedIDs] }))
      .unwrap()
      .then((data) => {
        if (data.data.success === true) {
          refetch();
          refetchStaffList();
        }
      });
    setShowTableDeleteModal(false);
    setSelectedIDs("");
  };

  const handleSort = (label) => {
    if (dioceseListingPage.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder =
            dioceseListingPage.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleEditClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };

  const handleEdit = (dioceseID) => {
    dispatch(getDioceseDetailsByID({ diocese_id: dioceseID }))
      .unwrap()
      .then((data) => {
        dispatch(editDiocese(true));
        setShowform(true);
      });
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      name: (feild, data) => <DioceseName data={data} onClick={() => {}} hasViewPermission={hasViewPermission} />,
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const closeTableEditModal = () => {
    setSelectedIDs("");
    setShowTableDeleteModal(false);
  };
  let actionOptions = [
    { label: "Edit Columns", value: 0 },
    { label: "Export view", value: 1 },
  ];

  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    } else {
      getExportedData({
        fields: Object.keys(dioceseListingPageList?.data?.fields),
        filter: dioceseListingPage.currentFilter,
        sort_by: dioceseListingPage.sortBy,
        sort_order: dioceseListingPage.sortOrder,
        start: dioceseListingPage.startDate,
        end: dioceseListingPage.endDate,
        search: dioceseListingPage.search,
      }).then((response) => {
        let dataType = response?.data?.type;
        let binaryData = [];
        binaryData.push(response?.data);
        let downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(
          new Blob(binaryData, { type: dataType })
        );
        downloadLink.setAttribute(
          "download",
          `${moment(Date.now()).format("MM_DD_YYYY_HH_mm_ss")}.xlsx`
        );
        document.body.appendChild(downloadLink);
        downloadLink.click();
      });
    }
  };

  const handleClearClick = () => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentPageSize = 10;
        state.sortBy = "";
        state.sortOrder = "";
        state.data = [];
        state.showCreateModal = false;
        state.selectedDiocese = "";
        state.editDiocese = false;
        state.preSelectedCommunity = null;
        state.search = "";
      })
    );
  };

  return {
    handleClearClick,
    dioceseListingPageList,
    dioceseListingPage,
    currentPage: dioceseListingPage.currentPage,
    paginationOptions,
    isFetching,
    isLoading,
    hasExportPermission,
    hasCreatePermission,
    hasEditPermission,
    hasUpdatePermission,
    handlePagination,
    handleDateChange,
    handlePageSize,
    actionOptions,
    handleActionChange,
    handleSort,
    handleSearch,
    getRow,
    handleEditClick,
    closeEditModal,
    showEditModal,
    refetch,
    handleDelete,
    closeTableEditModal,
    showTableDeleteModal,
    ActionDelete,
    showform,
    setShowform,
    handleEdit,
    currentPageSize: dioceseListingPage.currentPageSize,
    updateDiocesesFields,
  };
};

export default useDioceses;
