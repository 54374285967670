import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/Users/membersSlice";
import { useSelector } from "react-redux";
import { useUpdateMemberTransferDataMutation } from "../../../../store/queries/members";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { notificationRtk } from "../../../../store/queries/notifications";

const useTranfserForm = ({ refetch }) => {
  const dispatch = useDispatch();

  const members = useSelector((state) => state.members);
  const [transferMember] = useUpdateMemberTransferDataMutation();

  const handleClose = () => {
    dispatch(
      updateConfig((state) => {
        state.showTransferModal = false;
        state.transferNote = "";
      })
    );
  };

  const validation = Yup.object({
    transferNote:
      Yup.string().max(255, "max 255 characters allowed")
      .required("Note is required"),
  });

  const formik = useFormik({
    initialValues: {
      transferNote: "",
    },
    validationSchema: validation,
    onSubmit: (values) => {
      let data = {
        user_id: members?.IDs.memberID,
        note: values?.transferNote,
        staff_id: members?.IDs?.staffID,
      };
      transferMember(data).then((response) => {
        if (response?.data?.status_code === 200) {
          toast.success("Transfer success ");
          dispatch(notificationRtk.util.invalidateTags(["Transfer"]));
          dispatch(
            updateConfig((state) => {
              state.showTransferModal = false;
            })
          );
          refetch();
        } else {
          toast.error("Transfer Failed");
        }
      });
    },
  });

  return { handleClose, formik };
};

export default useTranfserForm;
