import React from "react";
import Style from "./dataUpdationModal.module.scss";
import { Button, HeadingGroup } from "@wac-ui-dashboard/wac_component_library";
import useDataUpdationModal from "./useDataUpdationModal";

const DataUpdationModal = ({ selectedId, handleClose, refetch }) => {
  const { formik } = useDataUpdationModal({
    selectedId,
    handleClose,
    refetch,
  });
  return (
    <div className="row">
      <HeadingGroup
        title={"Data Updation"}
        extraClassName={`pro-mb-4 ${Style.heading}`}
      />

      <div className="col-12 pro-mb-4">
        <div className="input-wrap">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Reason
          </label>
          <textarea
            id="reason"
            rows={6}
            name="reason"
            className={`pro-input lg ${
              formik.errors.reason && formik.touched.reason && " error"
            }`}
            onChange={(e) => formik.setFieldValue("reason", e.target.value)}
          ></textarea>
          {formik.touched.reason && formik.errors.reason && (
            <span className="error-text">{formik.errors.reason}</span>
          )}
        </div>
      </div>

      <div className={`pro-d-flex  col-6`}>
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => handleClose()}
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-mx-3"}
          onClick={formik.handleSubmit}
          type="submit"
        >
          Confirm
        </Button>
      </div>
    </div>
  );
};

export default DataUpdationModal;
