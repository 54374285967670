import {
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../Global/OrderColumn";
import Style from "./selectStatusButton.module.scss";
import useMessages from "./useMessages";
import EmptyData from "../../Global/EmptyData";
import ComplaintsModal from "./ComplaintsModal";
import DatePickerRsuite from "../../Global/CustomDateRangePicker";

const Messages = ({ dashboard = false }) => {
  const {
    messagesList,
    messages,
    currentPage,
    paginationOptions,
    isFetching,
    isLoading,
    actionOptions,
    activeFilter,
    filters,
    selectedId,
    date,
    currentStatus,
    showStatusUpdateModal,
    hasExportPermission,
    handleDateRangeChange,
    closeActionModal,
    handleFilter,
    handleDashboardRedirect,
    handleActionChange,
    refetch,
    handlePagination,
    handlePageSize,
    handleDateChange,
    handleSort,
    handleSearch,
    getRow,
    handleEditClick,
    closeEditModal,
    showEditModal,
    updateMessagesListFields,
    handleClearClick,
  } = useMessages(dashboard);

  return (
    <>
      {!dashboard ? (
        <HeadingGroup title={"Complaints"} className={`pro-mb-4`} />
      ) : (
        <></>
      )}

      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        {!dashboard ? (
          <SearchFilters
            data={filters}
            //showDateRange
            dropDownFilter={
              <div className="col-auto">
                <DatePickerRsuite
                  defaultDateRange={date}
                  handleDateRangeChange={handleDateRangeChange}
                />
              </div>
            }
            activeFilter={activeFilter}
            handleButtonGroupChange={handleFilter}
            onDateChange={handleDateChange}
            onSearchInput={handleSearch}
            showActions={false}
            handleActionClick={handleEditClick}
            loading={isLoading}
            initialDateRange={date}
            SearchIcon={
              <span className="material-symbols-outlined"> search </span>
            }
            //showClearFilters
            handleClear={handleClearClick}
            searchInputProps={{ value: messages?.search }}
            actionOptions={actionOptions?.filter((item) =>
              hasExportPermission !== 1 ? item.value === 0 : true
            )}
            onActionOptionChange={handleActionChange}
          />
        ) : (
          <></>
        )}
        {messagesList?.data?.data?.length === 0 ? (
          <EmptyData />
        ) : (
          <div
            onClick={dashboard ? () => handleDashboardRedirect() : () => {}}
            className={!dashboard ? "pro-pt-3" : Style.dashboardTable}
          >
            <Table
              multiSelect={false}
              isSelectBox={dashboard ? false : true}
              data={messagesList?.data?.data || []}
              uniqueID={"_id"}
              fields={messagesList?.data?.fields}
              SortIcon={<FaSort />}
              editIcon={<span className="material-symbols-outlined">edit</span>}
              deleteIcon={
                <span className="material-symbols-outlined">delete</span>
              }
              handleSort={handleSort}
              getRow={getRow}
              loading={isFetching}
              perpage={messages?.currentPageSize}
              assignable={false}
              showCheckBox={false}
              extraClasssName={"dashboard_no_padding"}
            />
          </div>
        )}
        {messagesList?.data?.data?.length > 0 && !dashboard && (
          <Pagination
            currentPage={currentPage}
            totalPageCount={Math.ceil(
              messagesList?.data?.total / messages.currentPageSize
            )}
            defaultValue={paginationOptions?.filter(
              (item) => item.value === messages?.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}

        <ModalLayout show={showEditModal} handleClose={closeEditModal}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={messagesList?.data?.fields}
              moduleId={messagesList?.data?.module_id}
              updateData={updateMessagesListFields}
            />
          </div>
        </ModalLayout>

        <ModalLayout
          show={showStatusUpdateModal}
          handleClose={closeActionModal}
        >
          <div className="pro-m-5">
            <ComplaintsModal
              selectedId={selectedId}
              handleClose={closeActionModal}
              refetch={refetch}
              currentStatus={currentStatus}
            />
          </div>
        </ModalLayout>
      </div>
    </>
  );
};

export default Messages;
