import { useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    useGetAllStaffListQuery,
    useGetIncompleteRegistersDataQuery,
    useGetMemberCreationFormDataQuery,
    useUpdateIncompleteMemberStaffAssignDataMutation,
    useUpdateMembersListDataMutation,
} from "../../../store/queries/members";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { updateConfig } from "../../../store/slices/Users/incompleteReg.Slice";
import { getFormatedDate } from "../../../utils/functions/table";
import { useNavigate, useSearchParams } from "react-router-dom";
import { updateFormData } from "../../../store/slices/Users/createMemberSlice";
import { useEffect } from "react";
import { useGetOverviewDataQuery } from "../../../store/queries/Profile";
import { ProfileNavigation } from "../../Global/ProfileNavigation";
import { subDays } from "date-fns";
import { toast } from "react-toastify";
import Style from "./incompletereg.module.scss";
import { SelectWithIcons } from "../../Global/SelectWithIcons";
import Select from "react-select";
import { updateStatus } from "./api";
import { IconText } from "@wac-ui-dashboard/wac_component_library";

const useIncompletereg = ({ dashboard }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [viewStaffModal, setViewStaffModal] = useState(false);
    const [staffData, setStaffData] = useState({});
    const currentLoginId = localStorage.getItem("USER_DETAILS");
    const membersState = useSelector((state) => state.incompletereg);
    const [showAllDesc, setShowAllDesc] = useState({});
    const [showTooltip, setShowTooltip] = useState(false);
    const [tooltipPosition, setTooltipPosition] = useState({});
    const [date, setDate] = useState({
        startDate: membersState.startDate,
        endDate: membersState.endDate,
        key: "selection",
    });
    const { showCreateModal } = useSelector((state) => state.incompletereg);
    const { showEditModal } = useSelector((state) => state.global);
    const { isUpdateProfile, isEditProfile, selectedUser, activeTab } = useSelector(
        (state) => state.createMembers
    );
    const [params] = useSearchParams();

    const { data: optionsData = {} } =
        useGetMemberCreationFormDataQuery({});

    const offCanvasRef = useRef();
    useEffect(() => {
        if (offCanvasRef?.current) {
            offCanvasRef?.current.scrollTo(0, 0);
        }
    }, [activeTab]);
    const activeProfile =
        params.get("active") ?? sessionStorage.getItem("active");

    let paginationOptions = [
        { label: "10 Per page", value: 10 },
        { label: "20 Per page", value: 20 },
        { label: "30 Per page", value: 30 },
    ];

    const [updateMemberFields] = useUpdateMembersListDataMutation();
    const [updateStaffAssign] = useUpdateIncompleteMemberStaffAssignDataMutation();

    const {
        data: membersList = {},
        isFetching,
        isLoading,
        refetch,
    } = useGetIncompleteRegistersDataQuery({
        page_size: membersState.currentPageSize,
        page: membersState.currentPage,
        filter: membersState.currentFilter,
        sort_by: membersState.sortBy,
        sort_order: membersState.sortOrder,
        start: getFormatedDate(membersState.startDate),
        end: getFormatedDate(membersState.endDate),
        search: membersState.search,
        branch_id: membersState?.branchId,
    }
    );

    const { data: profileData, isSuccess: isUpdateDataSuccess } =
        useGetOverviewDataQuery(
            {
                user_id: selectedUser,
            },
            { skip: isEditProfile === false }
        );

    const [formattedStaffs, setFormattedStaffs] = useState([]);

    const { data: staffList } = useGetAllStaffListQuery({ type: "1,2" });

    useEffect(() => {
        var temp = [];
        staffList?.data?.map((element) => {
            temp.push({
                value: element._id,
                label: element.value.name,
                icon: (
                    <img
                        src={element.value.image_url}
                        alt={""}
                        width={"500"}
                        height={"600"}
                    ></img>
                ),
            });
            return element;
        });
        setFormattedStaffs(temp);
    }, [staffList]);

    useEffect(() => {
        if (!dashboard) {
            setDate({
                startDate: subDays(new Date(), 30),
                endDate: new Date(),
                key: "selection",
            });
            dispatch(
                updateConfig((state) => {
                    state.currentPage = 1;
                    state.currentFilter = "pending";
                    state.currentPageSize = 10;
                    state.startDate = subDays(new Date(), 30);
                    state.endDate = new Date();
                    state.sortBy = "";
                    state.sortOrder = "";
                    state.search = "";
                    state.showCreateModal = false;
                })
            );
        }
        // eslint-disable-next-line
    }, [dashboard]);

    useEffect(() => {
        if (membersState.clearSelection) {
            dispatch(
                updateConfig((state) => {
                    state.clearSelection = false;
                })
            );
        }
        // eslint-disable-next-line
    }, [membersState.clearSelection]);

    useEffect(() => {
        if (isUpdateProfile && isUpdateDataSuccess) {
            dispatch(
                updateFormData((state) => {
                    state.currentFormData = {
                        ...profileData?.data?.data,
                        user_id: selectedUser,
                    };
                })
            );
        }
        // eslint-disable-next-line
    }, [isUpdateProfile, isUpdateDataSuccess, profileData]);

    const hasCreatePermission = useMemo(() => {
        let permission = membersList?.data?.permissions?.filter((p) =>
            Object.keys(p).includes("can_create")
        );

        return permission?.[0]?.can_create ?? 0;
    }, [membersList]);

    const hasAssignPermission = useMemo(() => {
        let permission = membersList?.data?.permission?.filter((p) =>
            Object.keys(p).includes("can_assign")
        );

        return permission?.[0]?.can_assign ?? 0;
    }, [membersList]);

    const hasStatusPermission = useMemo(() => {
        let permission = membersList?.data?.permission?.filter((p) =>
            Object.keys(p).includes("can_status")
        );

        return permission?.[0]?.can_status ?? 0;
    }, [membersList]);

    const hasViewPermission = useMemo(() => {
        let permission = membersList?.data?.permissions?.filter((p) =>
            Object.keys(p).includes("can_view")
        );

        return permission?.[0]?.can_view ?? 0;
    }, [membersList]);

    const filters = useMemo(() => {
        if (membersList?.data?.filters) {
            const buttonGroups = membersList?.data?.filters?.filter((d) => !d?.type);
            return [{ type: "buttonGroup", buttons: buttonGroups }];
        } else {
            return [];
        }
        // eslint-disable-next-line
    }, [isLoading, membersList]);

    const handlePagination = (page) => {
        dispatch(
            updateConfig((state) => {
                state.currentPage = page;
            })
        );
    };

    const handlePageSize = ({ value: page_size }) => {
        dispatch(
            updateConfig((state) => {
                state.currentPageSize = page_size;
                state.currentPage = 1;
            })
        );
    };
    const handleFilter = (filter) => {
        if (filter.value === "pending") {
            dispatch(
                updateConfig((state) => {
                    state.currentFilter = filter.value;
                    state.sortBy = "";
                    state.sortOrder = "";
                    state.currentPage = 1;
                })
            );
        } else if (filter.value === "rejected") {
            dispatch(
                updateConfig((state) => {
                    state.currentFilter = filter.value;
                    state.currentPage = 1;
                })
            );
        } else if (filter.value === "not_interested") {
            dispatch(
                updateConfig((state) => {
                    state.currentFilter = filter.value;
                    state.currentPage = 1;
                })
            );
        }
    };
    const handleDateChange = (range) => {
        setDate(range);
        dispatch(
            updateConfig((state) => {
                state.currentPage = 1;
                state.startDate = range.startDate;
                state.endDate = range.endDate;
            })
        );
    };

    const handleSort = (label) => {
        if (membersState.sortBy === label) {
            dispatch(
                updateConfig((state) => {
                    state.currentPage = 1;
                    state.sortOrder = membersState.sortOrder === "asc" ? "desc" : "asc";
                })
            );
        } else {
            dispatch(
                updateConfig((state) => {
                    state.currentPage = 1;
                    state.sortBy = label;
                    state.sortOrder = "asc";
                })
            );
        }
    };

    const handleDateRangeChange = (range) => {
        const startDate = range[0];
        const endDate = range[1];

        setDate({
            startDate,
            endDate,
        });

        dispatch(
            updateConfig((state) => {
                state.currentPage = 1;
                state.startDate = startDate;
                state.endDate = endDate;
            })
        );
    };

    const handleSearch = (e) => {
        dispatch(
            updateConfig((state) => {
                state.search = e.target.value;
                state.currentPage = 1;
            })
        );
    };

    const closeModal = () => {
        dispatch(
            updateConfig((state) => {
                state.showCreateModal = false;
            })
        );
        if (isUpdateProfile) {
            dispatch(
                updateFormData((state) => {
                    state.currentFormData = {}
                    state.updatedata = {};
                    state.selectedUser = "";
                    state.activeTab = "Basic";
                    state.isEditProfile = false;
                    state.isUpdateProfile = false;
                    state.completedTabs = {
                        ...state.completedTabs,
                        Basic: false,
                        Family: false,
                        Job: false,
                        Edu: false,
                        Contact: false,
                    };
                    state.CompletedPercentage = {
                        ...state.CompletedPercentage,
                        Basic: 0,
                        Family: 0,
                        Job: 0,
                        Edu: 0,
                        Contact: 0,
                    };
                })
            );
        }
    };

    const handleEditModal = () => {
        dispatch(
            globalUpdateConfig((state) => {
                state.showEditModal = true;
            })
        );
    };
    const handleEditClick = (id) => {
        const newvalue = membersList?.data?.data?.data?.find(item => item?.temporary_user_id === id?.[0])
        dispatch(
            updateConfig((state) => {
                state.showCreateModal = true;
            })
        );
        dispatch(
            updateFormData((state) => {
                state.activeTab = "Basic";
                state.isUpdateProfile = true;
                state.selectedUser = id?.[0];
                state.Updatedata = newvalue
                state.phoneVerified = {
                    verified: false,
                    status: false,
                };
            })
        );
    };

    const closeEditModal = () => {
        dispatch(
            globalUpdateConfig((state) => {
                state.showEditModal = false;
            })
        );
    };

    const handleStaffChange = (staff, data) => {
        dispatch(
            updateConfig((state) => {
                state.IDs = { memberID: data?.temporary_user_id, staffID: staff?.value };
            })
        );
        let obj = {
            temporary_user_id: data?.temporary_user_id,
            staff_id: staff?.value,
        };

        updateStaffAssign(obj).then((response) => {
            if (response?.data?.status_code === 200) {
                toast.success("Successfully Assigned");
                refetch()
            } else {
                toast.error("Failed to Assign");
            }
        });
    };

    const handleHideViewStaff = (data) => {
        setTimeout(() => {
            setViewStaffModal(false);
            setStaffData({});
        }, 2000);
    };
    const closeViewStaffModal = (data) => {
        setViewStaffModal(false);
        setStaffData({});
    };

    const handleViewTooltip = (e, data) => {
        setStaffData(data?.assigned_staff);
        setShowTooltip(true);
        const pageWidth = window.innerWidth / 1.5;
        const pageHeight = window.innerHeight / 1.5;
        const currectElement = e?.currentTarget?.getBoundingClientRect();
        const mousePostionX = currectElement?.left;
        const mousePostionY = currectElement?.top;
        setTooltipPosition({
            x: mousePostionX,
            y: mousePostionY,
            placementY: mousePostionY <= pageHeight ? "bottom" : "top",
            placementX: mousePostionX <= pageWidth ? "left" : "right",
        });
    };
    const handleReadMoreDesc = (index) => {
        setShowAllDesc((prev) => ({
            ...prev,
            [index]: !prev[index],
        }));
    };
    const handleHideTooltip = () => {
        setTooltipPosition({});
        setShowTooltip(false);
        setStaffData({});
    };
    const handleChange = (e, data) => {
        let params = {
            temporary_user_id: data?.temporary_user_id,
            status: e.id,
        };
        updateStatus(params).then(res => {
            if (res?.data?.status_code === 200) {
                refetch();
                //  dispatch(members.util.resetApiState());
                toast.success("Status changed succesfully")
            } else {
                toast.error("Failed to change status")
            }
        }
        )
    };
    const topDropdown = (id) => {
        if (membersList?.data?.data?.data?.length < membersState.currentPageSize / 2) {
            return true; 
        } else {
            const index=membersList?.data?.data?.data.findIndex(item => item.temporary_user_id === id);
            return (index > membersList?.data?.data?.data?.length - 5) ? false : true
        }
    }
    const getRow = (feild, data) => {

        const isExpanded = showAllDesc[data?.temporary_user_id];
        const staffassigned = formattedStaffs?.filter((item) => item?.value === data?.assigned_staff?._id
        )

        const rows = {
            common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
            status: (feild, data) =>
                <div className="col-12 no-border" style={{ minWidth: "222px" }} >
                    {hasStatusPermission === 1 && !dashboard ? (
                            <Select
                                menuPlacement={topDropdown(data?.temporary_user_id) ? "bottom" : "top"}
                                options={optionsData?.data?.temporary_user_status || []}
                                value={optionsData?.data?.temporary_user_status.find(option => option.id === data[feild])}
                                onChange={(e) => handleChange(e, data)}
                                getOptionValue={(option) => option?.id}
                                getOptionLabel={(option) => option?.name}
                                className={Style.reactSelectContainer} // Uncomment this if you have styles defined
                                classNamePrefix="pro-input" // Uncomment this if you have styles defined
                            />
                    ) : (
                        <div
                            className={`pro-badge  ${data[feild] !== 3
                                ? "badge-grey-outline"
                                : "badge-danger-outline"
                                }`}
                        >{optionsData?.data?.temporary_user_status.find(option => option.id === data[feild]).name ?? ""}</div>
                    )}
                </div>,
            assigned_staff: (feild, data) => (
                <p
                    className={`pro-mb-0 ${Style.user_select}`}
                >
                    {
                        hasAssignPermission === 1 && !dashboard ? (
                            <SelectWithIcons
                                menuPlacement={topDropdown(data?.temporary_user_id) ? "bottom" : "top"}
                                menuPosition="auto"
                                options={formattedStaffs?.filter(
                                    (item) =>
                                        item?.value !== data?.assigned_staff?._id &&
                                        item?.value !== currentLoginId
                                )}
                                multiSelect={false}
                                onChange={(staff) => handleStaffChange(staff, data)}
                                name="select_staff"
                                placeholder="Unassigned"
                                // formattedStaffs.find(item => item.value === data?.assigned_staff)
                                value={data?.assigned_staff}
                                handleViewTooltip={handleViewTooltip}
                                handleHideTooltip={handleHideTooltip}
                                data={data}
                            />
                        ) : staffassigned?.length > 0 ?
                            <div className={Style.icon_text_wrap}>
                                <IconText
                                    icon={
                                        <img
                                            src={staffassigned[0]?.icon?.props?.src}
                                            alt={"profile-pic"}
                                            width={20}
                                            height={20}
                                            className="object-fit-cover"
                                        />
                                    }
                                    title={staffassigned[0]?.label}
                                    propStyle={{
                                        rootIcon:
                                            "pro-d-flex pro-items-center pro-rounded-circle pro-overflow-hidden",
                                    }}
                                />
                            </div>
                            : <>{data?.staff?.name ?? "Unassigned"}</>
                    }
                </p>
            ),
            landing_page_url: (feild, data) =>
                data[feild]?.length > 50 && !isExpanded ? (
                    <p>
                        {data[feild]?.slice(0, 50) +
                            "..."}
                        <span
                            className="pro-pnt"
                            onClick={() => handleReadMoreDesc(data?.temporary_user_id)}
                        >
                            Read More
                        </span>
                    </p>
                ) : (
                    <p>{data[feild]}</p>
                ),
            name: (_, data) => (
                <ProfileNavigation
                    userId={data?._id}
                    fieldValue={data?.name}
                    activeProfile={activeProfile}
                    params={params}
                    hasViewPermission={
                        hasViewPermission === 1 && !dashboard ? true : false
                    }
                />
            ),
        };

        return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
    };

    const handleClearClick = () => {
        setDate({
            startDate: subDays(new Date(), 30),
            endDate: new Date(),
            key: "selection",
        });
        dispatch(
            updateConfig((state) => {
                state.currentPage = 1;
                state.currentFilter = "pending";
                state.currentPageSize = 10;
                state.startDate = subDays(new Date(), 30);
                state.endDate = new Date();
                state.sortBy = "";
                state.sortOrder = "";
                state.search = "";
                state.showCreateModal = false;
            })
        );
    };
    let actionOptions = [
        { label: "Edit Columns", value: 0 }
    ];

    const handleActionChange = (item) => {
        if (item?.value === 0) {
            dispatch(
                globalUpdateConfig((state) => {
                    state.showEditModal = true;
                })
            );
        }
    };

    const handleDashboardRedirect = (filter) => {
        navigate("/users/in-complete-user");
        setTimeout(() => {
            dispatch(
                updateConfig((state) => {
                    state.currentFilter = filter;
                    state.currentPage = 1;
                })
            );
        }, 500);
    };
    return {
        membersList,
        membersState,
        filters,
        navigate,
        currentPage: membersState.currentPage,
        activeFilter: membersState.currentFilter,
        showCreateModal,
        showEditModal,
        hasCreatePermission,
        paginationOptions,
        isFetching,
        isLoading,
        isUpdateDataSuccess,
        selectedUser,
        viewStaffModal,
        staffData,
        offCanvasRef,
        isUpdateProfile,
        actionOptions,
        handleDateRangeChange,
        handleActionChange,
        handleDashboardRedirect,
        handleEditClick,
        handlePagination,
        handlePageSize,
        handleFilter,
        handleDateChange,
        handleSort,
        handleSearch,
        handleHideViewStaff,
        handleEditModal,
        closeEditModal,
        closeModal,
        getRow,
        updateMemberFields,
        closeViewStaffModal,
        refetch,
        handleClearClick,
        date,
        showTooltip,
        tooltipPosition,
    };
};

export default useIncompletereg;
