import { getAxiosInstance } from "../../../api";

export const getExportedData = async (params) => {
  const getParams = (key) => {
    if (params?.[key]) {
      return `${key}=${params[key]}&`;
    } else {
      return "";
    }
  };

  const api = await getAxiosInstance();
  try {
    const response = await api.get(
      `admin/staffs/export?${params?.fields
        ?.map((field, index) => `fields[${index}]=${field}`)
        .join("&")}&
        ${getParams("sort_order")}
        ${getParams("sort_by")}
        ${getParams("search")}`,
      {
        responseType: "blob",
      }
    );

    return response;
  } catch (error) {
    return error.response.data;
  }
};
