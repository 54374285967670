import React from "react";
import useChartData from "./useChartData";
import PartnerDashboardChart from "./PartnerDashboardChart";
import DashboardGraphShimmer from "../../Global/Shimmers/DashboardGraphShimmer";

const ChartData = ({ chartType, type }) => {
  const { chartData, state } = useChartData({ chartType,type });

  return (
    <div className="pro-m-4 pro-mx-0">
      {state?.dashboardChartStatus === "pending" ? (
        <DashboardGraphShimmer count={10} />
      ) : (
        <>
          {/* // <div className="row row-cols-3"> */}
          {chartData?.map((data, i) => {
            return (
              // <div>
              <PartnerDashboardChart
                // title={data?.title}
                datasets={data?.datasets}
                labels={data?.labels}
                chartType={chartType}
                type={type}
                state={state}
              />
              // </div>
            );
          })}
          {/* // </div> */}
        </>
      )}
    </div>
  );
};

export default ChartData;
