import { IconText } from "@wac-ui-dashboard/wac_component_library";
import React from "react";

export const PhotoRow = ({ data, onClick }) => {
  if (!data?.image_details_url) {
    return "";
  }
  return (
    <IconText
      icon={
        <img
          src={data?.image_details_url ?? ""}
          alt={"img"}
          onClick={() => onClick(data?.id)}
        />
      }
    />
  );
};
export const BasicRow = ({ data, onClick }) => {
  if (!data?.basic_details_url) {
    return "";
  }
  return (
    <IconText
      icon={
        <img
          src={data?.basic_details_url ?? ""}
          alt={"img"}
          onClick={() => onClick("Basic", data?.id)}
        />
      }
    />
  );
};
export const FamilyDetailsRow = ({ data, onClick }) => {
  if (!data?.family_details_url) {
    return "";
  }
  return (
    <IconText
      icon={
        <img
          src={data?.family_details_url ?? ""}
          alt={"img"}
          onClick={() => onClick("Family", data?.id)}
        />
      }
    />
  );
};
export const CommunicationRow = ({ data, onClick }) => {
  if (!data?.communication_url) {
    return "";
  }
  return (
    <IconText
      icon={
        <img
          src={data?.communication_url ?? ""}
          alt={"img"}
          onClick={() => onClick("Contact", data?.id)}
        />
      }
    />
  );
};
export const EmploymentRow = ({ data, onClick }) => {
  if (!data?.employement_url) {
    return "";
  }
  return (
    <IconText
      icon={
        <img
          src={data?.employement_url ?? ""}
          alt={"img"}
          onClick={() => onClick("Job", data?.id)}
        />
      }
    />
  );
};

export const EducationRow = ({ data, onClick }) => {
  if (!data?.education_url) {
    return "";
  }
  return (
    <IconText
      icon={
        <img
          src={data?.education_url ?? ""}
          alt={"img"}
          onClick={() => onClick("Edu", data?.id)}
        />
      }
    />
  );
};
