import { useDispatch, useSelector } from "react-redux";

import { updateConfig } from "../../../store/slices/CMS/useHighlightedProfilesSlice";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { getFormatedDate } from "../../../utils/functions/table";
import { useMemo, useState } from "react";
import {
  useGetHighlightedProfilesListDataQuery,
  useUpdateHighlightedProfilesListFieldsDataMutation,
} from "../../../store/queries/cms";
import { IconText } from "@wac-ui-dashboard/wac_component_library";
import { toast } from "react-toastify";
import { useUnHighlightProfileMutation } from "../../../store/queries/Profile";
import { useSearchParams } from "react-router-dom";
import { ProfileNavigation } from "../../Global/ProfileNavigation";
import { getExportedData } from "./api";
import { subDays } from "date-fns";
import moment from "moment";

const useHighlightedProfiles = () => {
  const dispatch = useDispatch();
  const [unHighlightRequest] = useUnHighlightProfileMutation();
  const highlightedProfiles = useSelector((state) => state.highlightedProfiles);
  const [params] = useSearchParams();
  const activeProfile =
    params.get("active") ?? sessionStorage.getItem("active");
  const { showEditModal } = useSelector((state) => state.global);

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [userID, SetUserId] = useState("");

  const [date, setDate] = useState({
    startDate: highlightedProfiles.startDate,
    endDate: highlightedProfiles.endDate,
    key: "selection",
  });

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const [updateHighlightedProfilesFields] =
    useUpdateHighlightedProfilesListFieldsDataMutation();

  const {
    data: highlightedProfilesList = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetHighlightedProfilesListDataQuery({
    page_size: highlightedProfiles.currentPageSize,
    page: highlightedProfiles.currentPage,
    sort_by: highlightedProfiles.sortBy,
    sort_order: highlightedProfiles.sortOrder,
    start: getFormatedDate(highlightedProfiles.startDate),
    end: getFormatedDate(highlightedProfiles.endDate),
    search: highlightedProfiles.search,
  });

  const hasExportPermission = useMemo(() => {
    let permission = highlightedProfilesList?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_export")
    );

    return permission?.[0]?.can_export ?? 0;
  }, [highlightedProfilesList]);

  const hasRemovePermission = useMemo(() => {
    let permission = highlightedProfilesList?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_delete")
    );

    return permission?.[0]?.can_delete ?? 0;
  }, [highlightedProfilesList]);

  const hasViewPermission = useMemo(() => {
    let permission = highlightedProfilesList?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_view")
    );

    return permission?.[0]?.can_view ?? 0;
  }, [highlightedProfilesList]);

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleEditClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
  };

  const handleDateChange = (range) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = range.startDate;
        state.endDate = range.endDate;
      })
    );
  };

  const handleSort = (label) => {
    if (highlightedProfiles.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder =
            highlightedProfiles.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      action: (feild, data) =>
        hasRemovePermission === 1 ? (
          <IconText
            icon={<span className="material-symbols-outlined x4">delete</span>}
            title={`${"Remove"}`}
            onClick={() => handleShowDeleteConfirm(data?.member_id)}
          />
        ) : (
          <></>
        ),
      name: (_, data) => (
        <ProfileNavigation
          userId={data?.member_id}
          fieldValue={data?.name}
          activeProfile={activeProfile}
          params={params}
          hasViewPermission={hasViewPermission === 1 ? true : false}
        />
      ),
      status: (feild, data) => (
        <div
          className={`pro-badge  ${
            data[feild] === "Active"
              ? "badge-grey-outline"
              : "badge-danger-outline"
          }`}
        >
          {data[feild]}
        </div>
      ),
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleShowDeleteConfirm = (id) => {
    SetUserId(() => id);
    setShowDeleteConfirm(() => true);
  };

  const handleRemoveHighlighted = (id) => {
    let formData = new FormData();
    formData.append("_method", "PUT");
    formData.append("user_id", userID);

    unHighlightRequest(formData)
      .then((response) => {
        if (response?.data?.status_code === 200) {
          toast.success("Removed Successfully!");
          SetUserId(() => "");
          setShowDeleteConfirm(() => false);
          refetch?.();
        } else if (response?.error?.data?.status_code === 422) {
          toast.error(response?.error?.data?.errors);
        } else {
          toast.error("Failed to Remove!");
        }
      })
      .catch(() => {
        toast.error("Failed to Remove!");
      });
  };
  let actionOptions = [
    { label: "Edit Columns", value: 0 },
    { label: "Export view", value: 1 },
  ];

  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    } else {
      getExportedData({
        fields: Object.keys(highlightedProfilesList?.data?.fields),
        filter: highlightedProfiles.currentFilter,
        sort_by: highlightedProfiles.sortBy,
        sort_order: highlightedProfiles.sortOrder,
        start: getFormatedDate(highlightedProfiles.startDate),
        end: getFormatedDate(highlightedProfiles.endDate),
        search: highlightedProfiles.search,
      }).then((response) => {
        let dataType = response?.data?.type;
        let binaryData = [];
        binaryData.push(response?.data);
        let downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(
          new Blob(binaryData, { type: dataType })
        );
        downloadLink.setAttribute(
          "download",
          `${moment(Date.now()).format("MM_DD_YYYY_HH_mm_ss")}.xlsx`
        );
        document.body.appendChild(downloadLink);
        downloadLink.click();
      });
    }
  };

  const handleClearClick = () => {
    setDate({
      startDate: subDays(new Date(), 30),
      endDate: new Date(),
      key: "selection",
    });
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentPageSize = 10;
        state.startDate = subDays(new Date(), 30)
        state.endDate = new Date()
        state.sortBy = "";
        state.sortOrder = "";
        state.data = [];
        state.showCreateModal = false;
        state.showEditModal = false;
        state.selectedItemsDetails = "";
        state.search = "";
      })
    );
  };
  const closeRemoveHightlightedModal = () => {
    setShowDeleteConfirm(() => false);
  };

  const handleDateRangeChange = (range) => {
    const startDate = range[0];
    const endDate = range[1];

    setDate({
      startDate,
      endDate,
    });

    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = startDate;
        state.endDate = endDate;
      })
    );
  };

  return {
    highlightedProfilesList,
    handleClearClick,
    highlightedProfiles,
    currentPage: highlightedProfiles.currentPage,
    paginationOptions,
    isFetching,
    isLoading,
    actionOptions,
    hasExportPermission,
    date,
    closeRemoveHightlightedModal,
    handleRemoveHighlighted,
    handleActionChange,
    handlePagination,
    handleDateChange,
    handlePageSize,
    handleSort,
    handleSearch,
    getRow,
    closeEditModal,
    showEditModal,
    refetch,
    handleEditClick,
    showDeleteConfirm,
    setShowDeleteConfirm,
    handleDateRangeChange,
    updateHighlightedProfilesFields,
  };
};

export default useHighlightedProfiles;
