import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  menuDrawer: {},
  showEditModal: false,
  isLogged: false,
  currentPage: 1,
  otpGenerated: "",
  tableFields: {},
  searchData: {},
  searchStatus: "idle",
  searchKey: "",
  drawerTheme: "expanded",
  currentTheme: "light",
  dashboard_permissions: {},
  dashboard_permissions_status: "idle",
  dashboardChartData: {},
  dashboardChartStatus: "idle",
};

export const getDashboardChartData = createAsyncThunk(
  "global/getDashboardChartData",
  async (params, { rejectWithValue }) => {
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };

    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        params?.typeApi === 1
          ? `/admin/director/user-count-details?${getParams("type")}`
          : `/admin/director/payment-history-details?${getParams("type")}`
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getMenuPermissions = createAsyncThunk(
  "global/getMenuPermissions",
  async (body, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`user/token/validate`, body);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getProfilePermissions = createAsyncThunk(
  "global/getProfilePermissions",
  async (_, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`admin/members/profile/menu`);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getGlobalSearchData = createAsyncThunk(
  "global/getGlobalSearchData",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `admin/global-search?search=${params?.searchKey}&page_size=5&page=${params?.page}`
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const globalSlice = createSlice({
  name: "role",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMenuPermissions.pending, (state) => {
      state.dashboard_permissions_status = "pending";
    });
    builder.addCase(getMenuPermissions.fulfilled, (state, action) => {
      state.dashboard_permissions = action.payload.data.data;
      state.dashboard_permissions_status = "fulfilled";
      localStorage.setItem(
        "dashboard_permissions",
        JSON.stringify(action.payload.data.data)
      );
    });
    builder.addCase(getMenuPermissions.rejected, (state) => {
      state.dashboard_permissions_status = "rejected";
    });
    builder.addCase(getProfilePermissions.pending, (state) => {
      state.profile_permissions_status = "pending";
    });
    builder.addCase(getProfilePermissions.fulfilled, (state, action) => {
      state.profile_permissions = action.payload.data.data;
      state.profile_permissions_status = "fulfilled";
      localStorage.setItem(
        "profile_permissions",
        JSON.stringify(action.payload.data.data)
      );
    });
    builder.addCase(getProfilePermissions.rejected, (state) => {
      state.profile_permissions_status = "rejected";
    });
    builder.addCase(getGlobalSearchData.pending, (state, action) => {
      state.searchStatus = "pending";
    });
    builder.addCase(getGlobalSearchData.fulfilled, (state, action) => {
      if (
        state.searchData?.[action.meta?.arg?.user_id] &&
        !action.meta?.arg?.clearState
      ) {
        if (action.payload?.data?.data?.data) {
          state.searchData[action.meta?.arg?.user_id] = {
            ...action.payload?.data?.data,
            searchStatus: "fulfilled",
            data: [
              ...state.searchData[action.meta?.arg?.user_id].data,
              ...action.payload?.data?.data?.data,
            ],
          };
        } else {
          state.searchData[action.meta?.arg?.user_id] = {
            ...action.payload?.data?.data,
            searchStatus: "fulfilled",
            data: [...state.searchData[action.meta?.arg?.user_id].data],
          };
        }
      } else {
        state.searchData[action.meta?.arg?.user_id] = {
          ...action.payload?.data?.data,
          searchStatus: "fulfilled",
          page: 1,
        };
      }
      state.searchStatus = "fulfilled";
    });
    builder.addCase(getGlobalSearchData.rejected, (state, action) => {
      state.searchStatus = "failed";
    });

    builder.addCase(getDashboardChartData.pending, (state, action) => {
      state.dashboardChartStatus = "pending";
    });

    builder.addCase(getDashboardChartData.fulfilled, (state, action) => {
  
      state.dashboardChartData[action.meta?.arg?.type] = {
        ...action.payload?.data,
        dashboardChartStatus: "fulfilled",
      };

      state.dashboardChartStatus = "fulfilled";
    });

    builder.addCase(getDashboardChartData.rejected, (state, action) => {
      state.dashboardChartStatus = "failed";
    });
  },
});

export const { updateConfig } = globalSlice.actions;

export default globalSlice.reducer;
