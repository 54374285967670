import React from "react";
import ButtonGroupShimmer from "../../Global/Shimmers/ButtonGroupShimmer";
import useProfileFollowUp from "./useProfileFollowUp";
import { Image, SearchFilters } from "@wac-ui-dashboard/wac_component_library";
import Style from "./profileFollowUp.module.scss";
import EmptyData from "../../Global/EmptyData";
import FollowUpOverviewShimmer from "../../Global/Shimmers/FollowUpOverviewShimmer";
import FollowUpCommentsShimmer from "../../Global/Shimmers/FollowUpCommentsShimmer";

const ProfileFollowUp = () => {
  const {
    activeFilter,
    filters,
    state,
    showScroll,
    profileFollowupCommentState,
    profileFollowupOverviewState,
    lastElement,
    handleScrollToTop,
    handleTabChange,
  } = useProfileFollowUp();

  return (
    <>
      {activeFilter === 1 &&
      profileFollowupCommentState?.commentStatus !== "fulfilled" &&
      !profileFollowupCommentState?.data ? (
        <div className="pro-p-5">
          <ButtonGroupShimmer />
          <FollowUpCommentsShimmer />
        </div>
      ) : activeFilter === 2 &&
        profileFollowupOverviewState?.overviewStatus !== "fulfilled" &&
        !profileFollowupOverviewState?.data ? (
        <div className="pro-p-5">
          <ButtonGroupShimmer />
          <FollowUpOverviewShimmer />
        </div>
      ) : (
        <>
          <div className="pro-m-4 pro-mx-5">
            <SearchFilters
              searchable={false}
              showActions={false}
              data={filters}
              activeFilter={activeFilter}
              handleButtonGroupChange={handleTabChange}
            />
          </div>
          {showScroll && (
            <button className={`scoll-top`} onClick={() => handleScrollToTop()}>
              <span className="material-symbols-outlined">expand_less</span>
            </button>
          )}
          {activeFilter === 2 ? (
            <>
              {profileFollowupOverviewState?.data?.length > 0 ? (
                <>
                  {profileFollowupOverviewState?.data?.map((data, i) => {
                    return (
                      <div
                        className={`pro-m-2 pro-mx-5`}
                        key={i}
                        ref={
                          i === profileFollowupOverviewState?.data?.length - 5
                            ? lastElement
                            : false || null
                        }
                      >
                        <div
                          className={`${Style.root_overview_card} pro-d-flex pro-justify-between pro-py-5`}
                        >
                          <p className={`pro-fw-medium pro-mb-0`}>
                            {data?.activity}
                          </p>
                          <p className={`pro-font-sm pro-mb-0 ${Style.date}`}>
                            {data?.date}
                          </p>
                        </div>
                      </div>
                    );
                  })}

                  {state?.overviewStatus === "pending" &&
                    profileFollowupOverviewState?.data && (
                      <div className="pro-w-100 pro-mb-4">
                        <FollowUpOverviewShimmer />
                      </div>
                    )}
                </>
              ) : (
                <EmptyData />
              )}
            </>
          ) : (
            <>
              <>
                {profileFollowupCommentState?.data?.length > 0 ? (
                  <>
                    {profileFollowupCommentState?.data?.map((data, i) => {
                    
                      return (
                        <div
                          className={`pro-m-2 pro-mx-5`}
                          key={i}
                          ref={
                            i === profileFollowupCommentState?.data?.length - 5
                              ? lastElement
                              : false || null
                          }
                        >
                          <div
                            className={`${Style.root_comments_card} pro-d-flex pro-justify-between pro-py-5`}
                          >
                            <div className={`${Style.left_wrap}`}>
                              <div className="pro-badge badge-danger-outline">
                                {data?.status}
                              </div>
                              <div
                                className={`pro-d-flex pro-align-self-center ${Style.profile_wrap}`}
                              >
                                {data?.profile_image ? (
                                  <Image
                                    src={`${data?.profile_image}`}
                                    alt={"name"}
                                    width={24}
                                    height={24}
                                    propStyle={{ root: Style.img_wrap }}
                                  />
                                ) : (
                                  <span
                                    className={`material-symbols-outlined x4 ${Style.profile_icon}`}
                                  >
                                    {" "}
                                    account_circle{" "}
                                  </span>
                                )}

                               
                                <p className="pro-mb-0 pro-fw-medium pro-align-self-center">
                                  {data?.staff_name}
                                </p>
                              </div>
                              <p className={`pro-fw-medium ${Style.content}`}>
                                {data?.note}
                              </p>
                            </div>
                            <p
                              className={`pro-align-self-center pro-mb-0 pro-font-sm ${Style.date}`}
                            >
                              {data?.date}
                            </p>
                          </div>
                        </div>
                      );
                    })}

                    {state?.commentStatus === "pending" &&
                      profileFollowupCommentState?.data && (
                        <div className="pro-w-100 pro-mb-4">
                          <FollowUpCommentsShimmer />
                        </div>
                      )}
                  </>
                ) : (
                  <EmptyData />
                )}
              </>
            </>
          )}
        </>
      )}
    </>
  );
};

export default ProfileFollowUp;
