import React from "react";
import useRouteUtils from "../../utils/hooks/useRouteUtils";
import { useMemo } from "react";
import { useSelector } from "react-redux";

const useUsers = () => {
  const { checkIfActiveRoute } = useRouteUtils();
  const globalState = useSelector((state) => state.global);
  const drawerMenu = [
    {
      title: "Members",
      key: "Member",
      link: "/users/",
      icon: <span className="material-symbols-outlined x4"> group </span>,
      active: checkIfActiveRoute("/users/", true),
    },
    {
      title: "Incomplete Registrations",
       key: "Incomplete Registration",
      label: "Incomplete Registrations",
      link: "/users/in-complete-user",
      icon: <span className="material-symbols-outlined x4"> group </span>,
      active: checkIfActiveRoute("/users/in-complete-user"),
    },
    {
      title: "Verification",
      key: "Verification",
      link: "/users/verification",
      icon: (
        <span className="material-symbols-outlined x4">workspace_premium</span>
      ),
      active: checkIfActiveRoute("/users/verification"),
    },
    {
      title: "Incomplete",
      key: "Incomplete",
      link: "/users/incomplete",
      icon: <span className="material-symbols-outlined x4">report</span>,
      active: checkIfActiveRoute("/users/incomplete"),
    },
    {
      title: "Reported",
      key: "Reported",
      link: "/users/reported",
      icon: <span className="material-symbols-outlined x4">flag</span>,
      active: checkIfActiveRoute("/users/reported"),
    },
    {
      title: "Blocked",
      key: "Blocked",
      link: "/users/blocked",
      icon: <span className="material-symbols-outlined x4">gpp_bad</span>,
      active: checkIfActiveRoute("/users/blocked"),
    },
    {
      title: "Deleted Accounts",
      key: "Deleted Accounts",
      link: "/users/deleted-accounts",
      icon: <span className="material-symbols-outlined x4">delete</span>,
      active: checkIfActiveRoute("/users/deleted-accounts"),
    },
    {
      title: "Follow-ups",
       key: "Follow-ups",
      label: "Follow-up List",
      link: "/users/followup",
      iconAsset: "DrawerIcon3",
      icon: <span className="material-symbols-outlined x4">forum</span>,
      active: checkIfActiveRoute("/users/followup"),
    },  
  ];
  const drawerMenuPermission = useMemo(() => {
    let menus = globalState.dashboard_permissions?.flatMap?.((menu) => [
      // menu.name,
      ...menu.permissions.map((sub_menu) => sub_menu.menu_name),
    ]);
    return drawerMenu
      .filter((menu) => menus?.includes?.(menu.key))
      .map((menu) => {
        return {
          ...menu,
        };
      });
    //eslint-disable-next-line
  }, [globalState.dashboard_permissions, location.pathname]);

  return {
    drawerMenu: drawerMenuPermission,
  };
};

export default useUsers;
