import { Image } from "@wac-ui-dashboard/wac_component_library";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import Style from "./ProfileCard.module.scss";
import useProfileCard from "./useProfileCard";
import ImageCrop from "./ImageCrop";
import { useState } from "react";

export const ProfileCard = ({
  data,
  userId,
  setShow,
  handleDeleteImage,
  activeImageIndex,
  setActiveImageIndex,
  setShowDeleteImageModal,
  hasUpdatePermission,
  profileData,
  deletable = true,
  handleClose,
  refetch,
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const {
    showCrop,
    crop,
    image,
    imgRef,
    isLoading,
    aspectRatio,
    handleImageLimit,
    setCrop,
    handleThumbClick,
    handleCropClick,
    handleFreeCropClick,
    handleCropCancel,
    handlePrimary,
    handleUpload,
    handleNewImage,
  } = useProfileCard({
    data,
    userId,
    profileData,
    setShowDeleteImageModal,
    activeImageIndex,
    setActiveImageIndex,
    setShow,
    refetch,
    handleClose,
  });

  return (
    <div className={`${Style.root} pro-p-2`}>
      {!showCrop && !data?.[0]?.isDefault ? (
        <TransformWrapper>
          {({ zoomIn, zoomOut }) => (
            <>
              <div className={Style.action_icon}>
                {hasUpdatePermission === 1 &&
                profileData?.data?.assigned_profile === 1 &&
                !data?.[activeImageIndex]?.isRejected ? (
                  <button
                    className={`${Style.icon} pro-btn pro-p-0 pro-mb-1`}
                    onClick={() => handleCropClick()}
                  >
                    <span className="material-symbols-outlined">crop</span>
                  </button>
                ) : (
                  <></>
                )}

                <button
                  className={`${Style.icon} pro-btn pro-p-0 pro-mb-1`}
                  onClick={() => zoomIn()}
                >
                  <span className="material-symbols-outlined">zoom_in</span>
                </button>
                <button
                  className={`${Style.icon} pro-btn pro-p-0`}
                  onClick={() => zoomOut()}
                >
                  <span className="material-symbols-outlined">zoom_out</span>
                </button>
                {hasUpdatePermission === 1 &&
                deletable &&
                profileData?.data?.assigned_profile === 1 ? (
                  <button
                    className={`${Style.icon} pro-btn pro-p-0 pro-mt-1`}
                    onClick={() => handleDeleteImage()}
                  >
                    <span className="material-symbols-outlined">delete</span>
                  </button>
                ) : (
                  <></>
                )}
              </div>
              <TransformComponent>
                <div className={`${Style.root_image} pro-mb-1`}>
                  <div className={Style.root_figure}>
                    {data?.[activeImageIndex]?.isRejected ? (
                      <span
                        className={`${Style.isRejected} pro-py-1 pro-px-3 pro-font-sm pro-fw-bold`}
                      >
                        Rejected Image
                      </span>
                    ) : (
                      <></>
                    )}
                    <figure className={`${Style.image}`}>
                      <img
                        src={data?.[activeImageIndex]?.image ?? image}
                        alt={"profile"}
                        heigh={`100%`}
                        width={`100%`}
                        onLoad={() => setImageLoaded(true)}
                      />
                    </figure>
                  </div>
                  {!imageLoaded && (
                    <div className={Style.root_figure}>
                      <div className={`shine ${Style.thumb}`} />
                    </div>
                  )}
                  {data?.[activeImageIndex]?.reject_reason ? (
                    <span
                      className={`${Style.rejectedReason} pro-py-1 pro-px-3 pro-font-sm pro-fw-bold`}
                    >
                      {`Rejected Reason : ${data?.[activeImageIndex]?.reject_reason}`}
                    </span>
                  ) : (
                    <></>
                  )}
                </div>
              </TransformComponent>
            </>
          )}
        </TransformWrapper>
      ) : (
        <div className={`${Style.root_image} pro-mb-1`}>
          <div className={Style.root_figure}>
            <figure className={`${Style.image}`}>
              <ImageCrop
                ref={imgRef}
                aspectRatio={aspectRatio}
                image={data?.[activeImageIndex]?.image ?? image}
                crop={crop}
                setCrop={setCrop}
              />
            </figure>
          </div>
        </div>
      )}
      <div className={`${Style.root_image_lists} col-12 pro-mb-3 pro-pb-3`}>
        <div className={`row`}>
          {!data?.[0]?.isDefault &&
            data?.map?.((profile, index) => (
              <div
                className={`col-2`}
                key={index}
                onClick={() => !showCrop && handleThumbClick(index)}
              >
                <div
                  className={`${Style.root_image_inner} ${
                    activeImageIndex === index ? Style.active : ""
                  } ${profile.isPrimary === 1 ? Style.checked : ""}`}
                >
                  <Image
                    width={100}
                    height={100}
                    src={profile?.image}
                    alt={`image - 01`}
                  />
                </div>
              </div>
            ))}
          {image && (
            <div
              className={`col-2`}
              onClick={() => !showCrop && handleThumbClick(data.length)}
            >
              <div className={`${Style.root_image_inner} ${Style.active}`}>
                <Image
                  width={100}
                  height={100}
                  src={image}
                  alt={`image - 01`}
                />
              </div>
            </div>
          )}
          {hasUpdatePermission === 1 &&
          profileData?.data?.assigned_profile === 1 ? (
            <>
              {" "}
              {data?.length < 7 ? (
                <div className={`col-2`}>
                  <label
                    htmlFor="add_image"
                    className={`${Style.root_image_inner} ${Style.add_image} `}
                  >
                    <span className={Style.add_image_box}>
                      <p>+</p>
                      <input
                        type="file"
                        id="add_image"
                        accept=".jpeg, .jpg"
                        onChange={handleNewImage}
                      />
                    </span>
                  </label>
                </div>
              ) : (
                <div className={`col-2`} onClick={() => handleImageLimit()}>
                  <label
                    htmlFor="add_image"
                    className={`${Style.root_image_inner} ${Style.add_image} `}
                  >
                    <span className={Style.add_image_box}>
                      <p>+</p>
                    </span>
                  </label>
                </div>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      </div>

      <div
        className={`${Style.buttons} pro-d-flex pro-mt-2 pro-m-1 pro-justify-between  `}
      >
        <div className="pro-d-flex pro-gap-2">
          {hasUpdatePermission === 1 &&
          profileData?.data?.assigned_profile === 1 &&
          !data?.[activeImageIndex]?.isRejected &&
          showCrop ? (
            <button
              className={`${Style.icon} pro-btn pro-btn-outline ${
                aspectRatio ? Style.crop_btn_active : ""
              }`}
              onClick={() => handleCropClick()}
            >
              <span className="material-symbols-outlined">crop</span>
              <span>Fixed Crop</span>
            </button>
          ) : (
            <></>
          )}

          {hasUpdatePermission === 1 &&
          profileData?.data?.assigned_profile === 1 &&
          !data?.[activeImageIndex]?.isRejected &&
          showCrop ? (
            <button
              className={`${Style.icon} pro-btn pro-btn-outline ${
                !aspectRatio ? Style.crop_btn_active : ""
              }`}
              onClick={() => handleFreeCropClick()}
            >
              <span className="material-symbols-outlined">crop_free</span>
              <span>Free Crop</span>
            </button>
          ) : (
            <></>
          )}
        </div>
        <div
          className={`pro-d-flex pro-gap-2 ${
            !showCrop ? "pro-justify-between pro-w-100" : ""
          }`}
        >
          {hasUpdatePermission === 1 &&
          profileData?.data?.assigned_profile === 1 &&
          !data?.[activeImageIndex]?.isRejected ? (
            <button
              className={`pro-btn pro-btn-primary ${
                isLoading ? "loading" : ""
              } ${
                data?.[activeImageIndex]?.isPrimary === 1 && !showCrop
                  ? " pro-no-point"
                  : ""
              }`}
              disabled={isLoading}
              onClick={showCrop ? handleUpload : handlePrimary}
            >
              {showCrop
                ? "Upload"
                : data?.[activeImageIndex]?.isPrimary !== 1
                ? "Set as Primary"
                : "Primary Image"}
            </button>
          ) : (
            <></>
          )}

          <button
            className={`pro-btn pro-btn-secondary ${
              profileData?.data?.assigned_profile === 1 ? "" : "pro-ms-auto"
            }`}
            onClick={showCrop ? handleCropCancel : handleClose}
          >
            {showCrop ? "Cancel" : "Close"}
          </button>
        </div>
      </div>
    </div>
  );
};
