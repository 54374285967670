import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  sortBy: "",
  sortOrder: "desc",
  search: "",
  showCreateModal: false,
  selectedItemsDetails: null,
  rules: null,
  formType: null,
  clearSelection: false,
};

/**
 * @example {
 *  "sort_by":"name"
 *  "sort_order":"asc"

 *  }
 */

export const editItemFromTableByID = createAsyncThunk(
  "package/editItemFromTableByID",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `/admin/packages/edit?${new URLSearchParams(params).toString()}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getBasicData = createAsyncThunk(
  "package/getBasicData",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`admin/packages/data`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const packageSlice = createSlice({
  name: "package",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: {
    [editItemFromTableByID.pending]: (state, action) => {},
    [editItemFromTableByID.fulfilled]: (state, action) => {
      state.selectedItemsDetails = action.payload.data.data;
    },
    [editItemFromTableByID.rejected]: (state, action) => {},

    [getBasicData.pending]: (state, action) => {},
    [getBasicData.fulfilled]: (state, action) => {
      state.rules = action.payload.data.data;
    },
    [getBasicData.rejected]: (state, action) => {},
  },
});

export const { updateConfig } = packageSlice.actions;

export default packageSlice.reducer;
