import Select from "react-select";
import Style from "./selectStatusButton.module.scss";

export const SelectStatusButton = ({
  options,
  data,
  type,
  setShowBlockedModal,
  setShowDeleteModal,
  setSelectedUser,
  // label1,
  // label2,
}) => {
  // const [status, setStatus] = useState();
  // data?.status === label2
  //   ? { value: 1, label: label2 }
  //   : { value: 0, label: label1 }
  const handleChange = (selectedOption) => {
    if (selectedOption?.value === 1) {
      // setStatus(selectedOption);
      setShowBlockedModal(() => true);
      type === "blocked"
        ? setSelectedUser(data?.blocked_id)
        : setSelectedUser(data?.member_id);
    } else {
      // setStatus(selectedOption);
      setShowDeleteModal(() => true);
      setSelectedUser(data?.member_id);
    }
  };

  return (
    <div className="col-12 no-border" style={{ minWidth: "222px" }}>
      <Select
        options={options}
        value={""}
        menuPlacement={`auto`}
        placeholder={"Choose"}
        onChange={handleChange}
        className={Style.reactSelectContainer}
        classNamePrefix="pro-input"
      />
    </div>
  );
};
