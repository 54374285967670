import React from "react";
import useRouteUtils from "../../utils/hooks/useRouteUtils";
import { useMemo } from "react";
import { useSelector } from "react-redux";

const useSupport = () => {
  const { checkIfActiveRoute } = useRouteUtils();
  const globalState = useSelector((state) => state.global);
  const drawerMenu = [
    {
      title: "Data Updation",
      link: "/support/data-updation",
      iconAsset: "DrawerIcon3",
      icon: <span className="material-symbols-outlined x4">package</span>,
      active: checkIfActiveRoute("/support/data-updation", true),
    },
    {
      title: "Complaints",
      link: "/support/complaints",
      icon: <span className="material-symbols-outlined x4">chat</span>,
      active: checkIfActiveRoute("/support/complaints"),
    },
    {
      title: "Transfer",
      link: "/support/transfers",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined x4">group</span>,
      active: checkIfActiveRoute("/support/transfers"),
    },
  ];

  const drawerMenuPermission = useMemo(() => {
    let menus = globalState.dashboard_permissions?.flatMap?.((menu) => [
      ...menu.permissions.map((sub_menu) => sub_menu.menu_name),
    ]);
    return drawerMenu
      .filter((menu) => menus?.includes?.(menu.title))
      .map((menu) => {
        return {
          ...menu,
        };
      });
    //eslint-disable-next-line
  }, [globalState.dashboard_permissions, location.pathname]);

  const newDrawerMenuPermission = [];

  const combinedMenuPermissions = [
    ...drawerMenuPermission,
    ...newDrawerMenuPermission,
  ];

  return {
    drawerMenu: combinedMenuPermissions,
  };
};

export default useSupport;
