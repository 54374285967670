import { getAxiosInstance } from "../../../../api";

export const UpdateData = async (formData) => {
  const api = await getAxiosInstance();

  try {
    const response = await api.post("admin/payments/status/change", formData);
    return response;
  } catch (error) {
    return error.response.data;
  }
};
